import config from './putcut-react-config.js';  // TODO RAZLIKA OD SAJTA DO SAJTA
import { api_lib, url_lib } from './url-lib.js';
import { browser_utils } from './frfr-browser-utils.js';
import { frfr_responsive } from './frfr-responsive.js';
import { on_url_change_offline } from './putcut-routing.js';

import { ajax_utils } from './frfr-ajax-abstract.js';
import { auth_steps } from './frfr-ajax-auth-steps.js'; // token, cokies and localStorage

import { store } from '../index.js' // NOVO dispatch

import { test_a, resolve_after_ms, ajax_simulator, universal_fetch_get, universal_fetch_post } from '../utils/ajax.js';

import { putcut_cheats_init } from './putcut-cheats.js';


// AJAX FRAMEWORK VS APP abstract function names but site dependent function content...
// SEE EXPORT SECTION ON PAGE BOTTOM FOR FUNCTION NAMES

// THIS ONE IS MADE FOR putcut-react


let framework_vs_app = {};


// ----INIT
framework_vs_app.init = function () {

  putcut_cheats_init();

  frfr_responsive.init();

  // add event listeners...
  // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onpopstate
  // Calling history.pushState() or history.replaceState() won't trigger a popstate event. The popstate event is only triggered by performing a browser action, such as clicking on the back button (or calling history.back() in JavaScript), when navigating between two history entries for the same document.
  window.onpopstate = function (event) {
    console.log("-----ONPOPSTATE - location: " + document.location + ", state: " + JSON.stringify(event.state));
    console.log(event);
    if (event.state == null) {
      console.log("State is NULL. Skip parsing.");
      return;
    }
    console.log(event.state.url);
    var state_url = event.state.url;
    on_url_change_offline(state_url);
  };





  //

  // https://www.ibm.com/support/knowledgecenter/en/SS6PEW_9.4.0/com.ibm.help.custom.restapis.doc/c_SpecifyingHTTPHeaders.html

  let test_token = '123456789';
  test_token = config.initial_token_value;

  let content_type_json = 'application/json';
  let content_type_urlencoded = 'application/x-www-form-urlencoded;charset=UTF-8';
  // ajaxpost_headers_set('Content-Type', content_type_urlencoded);


  let putcut_header_token_key = config.header_token_key; // 'X-PutCut-Session' je naziv headera za putcut.com
  let header_token_key = putcut_header_token_key;
  let cookie_token_key = config.cookie_token_name;

  auth_steps.local_token_key_set(cookie_token_key); // name of the cookie
  auth_steps.header_token_key_set(header_token_key); // name of the header

  ajax_utils.init(); // INIT HEADERS FOR AJAX REQUESTS
  ajax_utils.log();
  let initial_getmethod_headers_arr = [
    [header_token_key, test_token]
  ];
  let initial_postmethod_headers_arr = [
    [header_token_key, test_token],
    ['Content-Type', content_type_urlencoded]
  ];
  ajax_utils.getmethod_headers_set_arr(initial_getmethod_headers_arr);
  ajax_utils.postmethod_headers_set_arr(initial_postmethod_headers_arr);
  ajax_utils.log();
};


framework_vs_app.resolve_after_ms = async function (x, ms) {
  if (typeof ms !== 'number') {
    ms = 7000;
  }
  return new Promise(resolve => {
    setTimeout(function () { resolve(x) }, ms);
  });
}


framework_vs_app.check_formlogin_data = function (data) {
  // step log login form data
  // TODO: OVDE JE RAZLIKA OD SAJTA DO SAJTA
  console.log('++ PRE FETCHOVANJA LOGIN FORM SUBMIT');
  if (typeof data.email !== 'undefined') {
    console.log(data.email);
  }
  if (typeof data.username !== 'undefined') {
    console.log(data.username);
  }
  let pw = data.password;
  if (typeof pw === 'undefined') {
    pw = '';
  }
  let masked_password = ('*'.repeat(pw.length));
  console.log('password: ' + masked_password);
}


framework_vs_app.formlogin_fetch = async (data) => {
  // framework_vs_app.formlogin_fetch_url_abstract = () => {
  // OVDE JE RAZLIKA OD SAJTA DO SAJTA
  let fetch_url = api_lib.auth_login(); // putcut form login api
  // ABSTRAKCIJA RAZLIKA OD SAJTA DO SAJTA
  let result = await universal_fetch_post(fetch_url, data, 'fething login form submit...');
  return result;
};


framework_vs_app.check_formlogin_response = async (response) => {
  // argument is autoogin api response in JSON format
  console.log('after_formlogin_response(response): ', response);
  let result;
  if (response.success) {
    // console.log('')
    // TODO: OVDE JE RAZLIKA OD SAJTA DO SAJTA
    let token = response.token; // OVDE JE RAZLIKA OD SAJTA DO SAJTA
    await auth_steps.local_token_set(token);
    result = {
      is_success: true,
      comment: 'form login step 2 - login status TRUE',
      ajax_response: response
    };
  } else {
    console.log('form login failed step 2 success not true');
    // return false;
    result = {
      is_success: false,
      comment: 'form login failed step 2 success not true',
      ajax_response: response
    };
  }
  // return true; // login status true
  return result;
}


framework_vs_app.autologin_fetch = async () => {
  // framework_vs_app.autologin_fetch_url_abstract = () => {
  // OVDE JE RAZLIKA OD SAJTA DO SAJTA
  let fetch_url = api_lib.user_my_profile_get(); //putcut autologin api
  // ABSTRAKCIJA RAZLIKA OD SAJTA DO SAJTA
  let result = await universal_fetch_get(fetch_url, 'fething autologin step2...');
  return result;
};


framework_vs_app.check_autologin_response = (response) => {
  // argument is autoogin api response in JSON format
  console.log('check_autologin_response(response): ', response);
  if (response.success === true) {
    let user_id = response.user_id;
    // return true; // login status true
    return {
      success: true,
      is_logged_in: true,
      comment: 'login step 2 - autologin success',
      ajax_response: response
    };
  } else {
    // response.succes.false
    console.log('login failed step 2 success not true');
    // return false;
    return {
      is_logged_in: false,
      comment: 'login failed step 2 success not true',
      ajax_response: response
    };
  }
};


framework_vs_app.after_logout_procedure = function () {
  // do something after frontend logout procedure completed
  let logout_url = url_lib.backend_logout(); // '/logout';
  browser_utils.browser_go_url_relative(logout_url);
}


// EXPORT
export { framework_vs_app };

