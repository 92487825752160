import React, { Component } from 'react';
import { actionNewRoute, actionSearch_proba } from '../actions/actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import PeopleItem from './PeopleItem';
import PostsItemLinkUniversal from './PostsItemLinkUniversal';

export default class RouteSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._search = this._search.bind(this);
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'REFRESH') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED REFRESH');
        // dispatch(actionFeedsItemsFetch());
      }
    }
    if (what === 'TAB') {
      return () => {
        console.log('CLICKED TAB ', second);
        // step 1
        /*
        dispatch({
          type: 'GUI_SEARCH_SECTION_SELECTED',
          payload: second
        });
        */
        // step 2
        this._search(second);
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name === 'filter') {
      // ukoliko je izmenjeno polje filter
      this.setState({
        filter_selected: value
      });

    }
  }

  _search(section) {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let search_query = route_params.search_query;
    let search_section = 1;
    if (typeof section !== 'undefined') {
      search_section = section;
      dispatch({
        type: 'GUI_SEARCH_SECTION_SELECTED',
        payload: section
      }); // from tab click
    } else {
      if (typeof route_params.search_section !== 'undefined') {
        search_section = route_params.search_section; // from route params
        dispatch({
          type: 'GUI_SEARCH_SECTION_SELECTED',
          payload: search_section
        }); //
      } else {
        search_section = this.props.appstate.selected_search_section; // from app state
      }
    }
    let move = this.props.appstate.route_freshness;
    dispatch(actionSearch_proba(move, search_section, search_query)); // actionSearch_proba = function (move, section, query)
  }

  componentDidMount() {
    this._search();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._search();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    /*
    	$sectionMeanings = [
	  1 => 'allposts',
	  4 => 'myposts',
	  2 => 'myfriendsposts',
	  3 => 'people'
	];
    */
    // let tab_selected = 3;
    let search_section = this.props.appstate.selected_search_section;

    let route_params = this.props.appstate.route_current.route_params;
    let search_query = route_params.search_query;

    let raw_data = this.props.appstate.data_search_proba;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.data.items;
    }

    console.log(table_arr);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let browser_url = url_lib.search(search_section, search_query);
    browser_utils.browser_url_set(browser_url);
    let page_title = "... PutCut - search";
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {


      let filtered_arr = table_arr; // NO FILTERING

      jsx_arr = filtered_arr.map((item, index) => {
        zero = false;
        // <div>{item.action}</div>

        let type = 'PEOPLE';
        if (item.display_name) {
          type = 'PEOPLE';
        } else if (item.post_type) {
          type = 'LINK';
        }

        if (type === 'PEOPLE') {
          return (
            <PeopleItem key={index} item={item} appstate={this.props.appstate} dispatch={this.props.dispatch} />
          );
        } else {
          // return <div><b>{index}</b></div>
          return (
            <PostsItemLinkUniversal key={item.post_id} type={'NESTED'} item={item} dispatch={this.props.dispatch} />
          );
        }

      });

      if (zero === true) {
        jsx_zero_items = (
          <div className="no-result-msg"><p>Your search did not match any content.</p></div>
        );
      }

    } // ens of pending === false


    let cl_tab_active = ' active-tab';
    let cl_tab_1 = 'tab tab-section-1';
    let cl_tab_2 = 'tab tab-section-2';
    let cl_tab_3 = 'tab tab-section-3';
    let cl_tab_4 = 'tab tab-section-4';
    if (search_section === 1) {
      cl_tab_1 = cl_tab_1 + cl_tab_active;
    } else if (search_section === 2) {
      cl_tab_2 = cl_tab_2 + cl_tab_active;
    } else if (search_section === 3) {
      cl_tab_3 = cl_tab_3 + cl_tab_active;
    } else if (search_section === 4) {
      cl_tab_4 = cl_tab_4 + cl_tab_active;
    }

    return (
      <>
        <div className="main" data-rendered-here="search">


          <div className="search-tabs clearfix">
            <div className={cl_tab_1} data-tab="putcut" title="Search all of PutCut" onClick={this._handleClick('TAB', 1)}>Putcut</div>
            <div className={cl_tab_4} data-tab="my-folders" title="Search in my folders" onClick={this._handleClick('TAB', 4)}>My links</div>
            <div className={cl_tab_2} data-tab="my-connections" title="Search links from people I followed" onClick={this._handleClick('TAB', 2)}>Friends links</div>
            <div className={cl_tab_3} data-tab="people" title="Search for people" onClick={this._handleClick('TAB', 3)}>Community</div>
          </div>


          <div className="ajax-container">

            {jsx_arr}
            {jsx_zero_items}
            {jsx_spinner}

          </div>

        </div>
      </>
    );
  }
}
