export let arr_utils = {};

arr_utils.pure_clone = function (arr) {
  // The slice() method returns a shallow copy of a portion of an array into a new array object selected from begin to end (end not included). The original array will not be modified.
  // If begin is undefined, slice begins from index 0.
  // If end is omitted, slice extracts through the end of the sequence.
  let pure_cloned_arr = arr.slice();
  return pure_cloned_arr;
};

arr_utils.pure_merge_with_duplicates = function (arr1, arr2) {
  // The concat() method is used to merge two or more arrays. This method does not change the existing arrays, but instead returns a new array.
  let new_merged_arr_with_duplicates = arr1.concat(arr2); // merged old activities with new, duplicates included.
  return new_merged_arr_with_duplicates;
};



/*
function isAlreadyRendered(testarray, testval) {
  return testarray.some(function (val) {
    console.log(testval === val);
    return testval === val; // return TRUE if equal
  });
}
*/

arr_utils.is_value_in_array = function (testarr, testval) {
  return testarr.some(function (val) {
    // console.log(testval === val);
    return testval === val; // return TRUE if equal
  });
};


arr_utils.pure_merge_without_duplicates = function (arr1, arr2) {
  let new_arr = [];
  new_arr = arr1.slice(); // pure array clone of arr 1
  // console.log('MERGE 1 2 and RESULT');
  // console.log(arr1);
  // console.log(arr2);
  arr2.forEach(function (current_val, i) { // foreach arr 2
    // current_val = singleact.id;
    if (arr_utils.is_value_in_array(new_arr, current_val)) {
      // true // already rendered
      // console.log("+++ already in array", current_val);
    } else {
      // value is fresh
      // console.log("---not in array", current_val);
      new_arr.push(current_val);
    }
  }); // end of forEach
  console.log(new_arr);
  return new_arr;
}
