import React, { Component } from 'react';
import { actionNewRoute, actionPostsSingleFetch } from '../actions/actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import PostsItemLinkUniversal from './PostsItemLinkUniversal';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';


export default class RoutePostsSingle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewstyle: 'DEFAULT'
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'VS_DEFAULT') {
      return () => {
        this.setState({ viewstyle: 'DEFAULT' });
      }
    }
    if (what === 'VS_LIST') {
      return () => {
        this.setState({ viewstyle: 'LIST' });
      }
    }
    if (what === 'VS_ICONS') {
      return () => {
        this.setState({ viewstyle: 'ICONS' });
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let post_id = route_params.post_id;
    let move = this.props.appstate.route_freshness;
    dispatch(actionPostsSingleFetch(move, post_id));
  }

  componentDidMount() {
    this._refresh();
  }


  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let single_item = [];
    let item;
    let fid;

    let user_id = this.props.appstate.route_current.route_params.user_id;
    let post_id = this.props.appstate.route_current.route_params.post_id;

    let draggable = false;

    let raw_data = this.props.appstate.data_posts_single;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      single_item = raw_data.data.item;
      item = single_item;

      fid = item.folder_id;
    }

    console.log('***************POST SINGLE ITEM');
    console.log(single_item);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let browser_url = url_lib.post_single(user_id, post_id);
    browser_utils.browser_url_set(browser_url);
    let page_title = 'shared post - PutCut';
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    // let jsx_arr = null;
    let jsx_item = null;
    let jsx = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {
      zero = false;

      jsx_item = (
        <PostsItemLinkUniversal key={item.post_id} item={item} draggable={draggable} dispatch={this.props.dispatch} />
      );

      let jsx_btn_more = (
        <a className="click-to-parent-folder-more-NEW posts-parent-folder-more button-style-3" href="' + props.temp_folder_url + '" onClick={this._handleClick('ROUTE', 'FOLDERS', { fid: fid, user_id: user_id })}>&gt;&gt; More from same folder</a>
      );

      jsx = (
        <>
          {jsx_item}
          {jsx_btn_more}
        </>
      );

      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }

    } // end of pending === false

    // toolbar for recycle bin <ToolbarBin />
    let jsx_toolbar = (
      <div className="main-toolbar clearfix">
        <div className="item layout-view" onClick={this._handleClick('VS_DEFAULT')}><img title="Details view" className="change-view-style click-to-style-normal" src={process.env.PUBLIC_URL + "/static/img/view-thumblist.png"} /></div>
        <div className="item layout-view" onClick={this._handleClick('VS_ICONS')}><img title="Icons view" className="change-view-style click-to-style-icons" src={process.env.PUBLIC_URL + "/static/img/view-icons-medium.png"} /></div>
        <div className="item layout-view" onClick={this._handleClick('VS_LIST')}><img title="List view" className="change-view-style click-to-style-list" src={process.env.PUBLIC_URL + "/static/img/view-list.png"} /></div>
      </div>
    );

    let cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-default';
    if (this.state.viewstyle === 'LIST') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-list';
    } else if (this.state.viewstyle === 'ICONS') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-icons';
    }

    return (
      <>
        <div className="main" data-rendered-here="">

          {jsx_toolbar}

          <div className={cl_viewstyle}>

            {jsx}
            {jsx_zero_items}
            {jsx_spinner}

          </div>
        </div >
      </>
    );
  }
}
