import UniversalFollowMultibutton from '../components/UniversalFollowMultibutton';
import { api_lib } from '../utils/url-lib.js';
import { mini_ajax_get, mini_ajax_post } from '../utils/ajax.js';
// import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import React, { Component } from 'react';

// CONTAINER COMPONENT

export default class UniversalFollowMultibuttonContainer extends React.Component {
  //
  constructor(props) {
    super(props);
    this.state = {
      pending: false,
      data: {}
    };
    this._cb_refresh = this._cb_refresh.bind(this);
    this._cb_follow = this._cb_follow.bind(this);
    this._cb_unfollow = this._cb_unfollow.bind(this); // bez bindovanja bude Cannot read property 'props' of undefined
  }

  _refresh() {
    // let fetch_url = this.props.fetch_url;
    let my_user_id = this.props.my_user_id;
    let user_id = this.props.user_id;
    let fetch_url = api_lib.social_is_following_get(my_user_id, user_id); // (userid1, userid2)
    this._fetch(fetch_url);
  }

  _cb_refresh() {
    console.log(' ***** CHILDREN CALLBACK REFRESH *****');
    this._refresh();
  }

  _cb_follow(data) {
    console.log(' ***** CHILDREN CALLBACK POST FOLLOW *****');
    let user_id = this.props.user_id;
    let fetch_url = api_lib.social_follow_upsert(user_id);
    // fetch_url, { userid: id }
    let outbox = { userid: user_id };
    let callback = () => {
      this._refresh();
    }
    // step 1
    this.setState({ ...this.state, pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  _cb_unfollow(data) {
    console.log(' ***** CHILDREN CALLBACK POST UNFOLLOW *****');
    let user_id = this.props.user_id;
    let fetch_url = api_lib.social_unfollow_upsert(user_id);
    // fetch_url, { userid: id }
    let outbox = { userid: user_id };
    let callback = () => {
      this._refresh();
    }
    // step 1
    this.setState({ ...this.state, pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  _fetch = (fetch_url) => {
    this.setState({ ...this.state, pending: true });
    let callback = (result) => {
      this.setState({ data: result, pending: false });
    }
    mini_ajax_get(fetch_url, callback, null, false, ''); // log disabled
  };

  /*
  _post = (fetch_url, data) => {
    this.setState({ ...this.state, pending: true });
    mini_fetch_post(fetch_url, data)
      .then(response => response.json())
      .then(result => {
        console.log('MINI POST SUCCESSFUL');
        console.log(result);
        this._refresh();
      })
      .catch(e => {
        console.log('mini fetch post CATCH ERROR - e: ');
        console.log(e);
      });
  };
  */

  componentDidMount() {
    this._refresh();
  };

  render() {
    return (
      <UniversalFollowMultibutton data={this.state} cb_refresh={this._cb_refresh} cb_follow={this._cb_follow} cb_unfollow={this._cb_unfollow} />
    );
  }
}
