// ---------FRFR HTML UTILS 2019-06-26
/* build2018-06-19*/
/* Please describe here if you customized this file
*/

export let frfr_html = {};

frfr_html.html_entities = function (text) {
  if (typeof text !== 'undefined' && typeof text === 'string') {
    return text.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
  }
  return text;
};


function htmlentities(text) {
  // console.log("htmlentities(text)");
  // console.log(text);
  // console.log(typeof text);
  if (typeof someUndeclaredVariable != "undefined") {
    return text.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
      return '&#' + i.charCodeAt(0) + ';';
    });
  } else {
    // if undefined
    return text;
  }
}

function OLD_htmlentities(text) {
  return text.replace(/[\u00A0-\u9999<>\&]/gim, function (i) {
    return '&#' + i.charCodeAt(0) + ';';
  });
}

// https://css-tricks.com/snippets/javascript/htmlentities-for-javascript/

function htmlEntities(str) {
  return String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;');
}


