import React, { Component } from 'react';

export default class ModalError extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'SCRAPE_AGAIN') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        this._scrape();
      }
    }
  }

  render() {
    /*
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let dispatch_close_modal = this.props.dispatch_close_modal;
    title = 'ERROR CATCH';
    let where = '';
    if (typeof modal_data.where === 'string') {
      where = modal_data.where
    }
    message = where + '  - Check log for details';

    let modal_args = {
      title,
      message
    };
    */
    // jsx_modal_content = <ModalError modal_args={modal_args} dispatch_close_modal={dispatch_close_modal}



    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;

    return (
      <>
        <div className="modal-item-content thisis-ModalError">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="flex-bar center gaps buttons-group-style-2">
            <div className="xi btn btn-size-1" onClick={dispatch_close_modal}>Ok</div>
          </div>
        </div>
      </>
    );
  }
}
