import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';



export default class ModalPrompt extends Component {
  constructor(props) {
    super(props);
    console.log('consttructor ModalPrompt');
    console.log(this.props);
    let initial_value = '';
    if (this.props.modal_args.value) {
      initial_value = this.props.modal_args.value;
    }
    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true
    };
    // binding this
    this.handleChange = this.handleChange.bind(this);
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._submit = this._submit.bind(this);
    // Create the refs
    this.inputRef = React.createRef();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleSubmit(e) {
    e.preventDefault(e);
    console.log('_handleSubmit(e) SUBMIT ');
    this._submit();
  }

  _handleClick(what, second, third) {
    // let dispatch = this.props.dispatch;
    if (what === 'SUBMIT') {
      return (e) => {
        e.preventDefault();
        this._submit();
      }
    }
  }

  _submit() {
    /*
    let dom_el = this.inputRef.current; // reference to a DOM input text
    let input_value = dom_el.value;
    */
    let input_value = '';
    if (this.state.modalinput) {
      input_value = this.state.modalinput;
    }
    input_value = input_value.trim();

    let modal_args = this.props.modal_args;
    let callback_validate = modal_args.callback_validate;
    let is_valid = true;
    if (callback_validate) {
      let validation = callback_validate(input_value); // do validation
      if (validation === true) {
        is_valid = true;
      } else {
        // form validation fail
        is_valid = false;
        this.setState({
          error: true,
          error_message: validation
        });
      }
      console.log('validation: ', validation);
    }

    if (is_valid === true) {
      console.log('TEST 1');
      let callback_submit = modal_args.callback_submit;
      if (callback_submit) {
        callback_submit(input_value);
      }
    }
  }

  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
  };

  render() {
    let pending = true;
    let zero = true;

    let my = true;

    if (this.state.pending === false) {
      pending = false;
    }

    let modal_data = this.props.modal_data;
    console.log(modal_data);


    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    //--------------------------------***********************

    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;
    let placeholder = modal_args.placeholder;
    let initial_value = modal_args.value;

    let error = this.state.error;
    let jsx_error = null;
    if (error === true) {
      jsx_error = (
        <div className="error red">GRESKA U VALIDACIJI: {this.state.error_message}</div>
      );
    }

    return (
      <>
        <div className="modal-item-content thisis-ModalPrompt">
          <h2>{title}</h2>
          <p>{message}</p>

          <form onSubmit={this._handleSubmit}>
            <input
              name="modalinput"
              type="text"
              placeholder={placeholder}
              value={this.state.modalinput}
              onChange={this.handleChange}
              ref={this.inputRef}
            />
            <div className="flex-bar center gaps buttons-group-style-2">
              <div className="xi btn btn-size-1" onClick={dispatch_close_modal}>Cancel</div>
              <div className="xi btn btn-size-1 btn-style-blue-1" onClick={this._handleClick('SUBMIT')}>Submit</div>
            </div>
          </form>

          {jsx_error}
        </div>
      </>
    );
  }
}
