import React, { Component } from 'react';
import { frfr_html } from '../utils/frfr-html-utils.js';


export default class RssFeedsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
    this._handleClick = this._handleClick.bind(this);

    // this._callbackClose = this._callbackClose.bind(this);
    // this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
  }

  _handleClick(what, second) {
    // let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return () => {
        console.log('CLICKED');
        // dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {

    let item = this.props.item;
    let props = {
      item: item
    };
    console.log('**** FEEDS ITEM');
    console.log(item);

    let htmlentities = frfr_html.html_entities;
    htmlentities = (text) => {
      return text; // htmlentities not needed for react jsx variables
    };
    let display_timing_1 = htmlentities(props.item.domain);
    let display_timing_2 = htmlentities(props.item.time_2);

    return (
      <>

        <div className="feed-item" data-id="' + props.item.id + '" data-feed-time="' + htmlentities(props.item.published) + '">
          <div className="feed-title">
            <a className="title" rel="nofollow" target="_blank" href={htmlentities(props.item.url_open)}>{htmlentities(props.item.title)}</a>
          </div>
          <div className="feed-timing">{display_timing_1} &bull; {display_timing_2}</div>
          <div className="description">{htmlentities(props.item.description)}</div>
        </div>

      </>
    );
  }
}


