import React, { Component } from 'react';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen } from '../actions/frfr-multimodal-actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import ToolbarUserTheir from './ToolbarUserTheir';
import PostsItemLinkUniversal from './PostsItemLinkUniversal';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';


export default class RouteFolders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewstyle: 'DEFAULT'
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._handleSubmitPutYourCut = this._handleSubmitPutYourCut.bind(this);
    this._submitPlusPastedLink = this._submitPlusPastedLink.bind(this);
    this._refresh = this._refresh.bind(this);
    // Create the ref
    this.putyourcutRef = React.createRef();

    // this._dragstart_handler = this._dragstart_handler.bind(this);
  }

  /*
  _dragstart_handler(ev) {
    console.log("dragStart");
    ev.dataTransfer.setData("text", ev.target.id);
  }
  */

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'VS_DEFAULT') {
      return () => {
        this.setState({ viewstyle: 'DEFAULT' });
      }
    }
    if (what === 'VS_LIST') {
      return () => {
        this.setState({ viewstyle: 'LIST' });
      }
    }
    if (what === 'VS_ICONS') {
      return () => {
        this.setState({ viewstyle: 'ICONS' });
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

    // PUT YOUR CUT EVENT
    if (name === 'putyourcuthere') {
      console.log('CHANGED INPUT');
      let dom_input = this.putyourcutRef.current;
      if (dom_input) {
        // dom_input.blur(); // UNFOCUS INPUT FIELD
      }
      this._submitPlusPastedLink(value);
    }
  }

  _handleSubmitPutYourCut(e) {
    e.preventDefault(e);
    console.log('_handleSubmitPutYourCut(e) SUBMIT ');
    let dom_input = this.putyourcutRef.current;
    if (dom_input) {
      // dom_input.blur(); // UNFOCUS INPUT FIELD
    }
    let value = this.state.putyourcuthere;
    this._submitPlusPastedLink(value);
  }

  _submitPlusPastedLink(value) {
    console.log('_submitPastedLink() SUBMIT ');
    console.log('PASTED');
    console.log(value); // for example https://yts.lt/
    if (value !== '') {
      let dom_input = this.putyourcutRef.current;
      if (dom_input) {
        // dom_input.blur(); // UNFOCUS INPUT FIELD
      }
      let route_params = this.props.appstate.route_current.route_params;
      let fid = route_params.fid;
      let user_id = this.props.appstate.my_user_id; // MY
      let modal_content_data = {
        mode: 'ADD',
        url: value,
        fid,
        user_id
      };
      let dispatch = this.props.dispatch;
      dispatch(actionMultimodalOpen('MODAL_LINK_EDITOR', modal_content_data));
    }
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let move = this.props.appstate.route_freshness;

    let user_id = route_params.user_id;
    let my = false;
    let my_user_id = this.props.appstate.my_user_id;
    if (user_id === my_user_id) {
      my = true;
    }
    if (my) {
      dispatch(actionPostsMyFolderFetch(move, fid));
    } else {
      dispatch(actionPostsTheirFolderFetch(move, fid, user_id));
    }
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let user_id = route_params.user_id;

    let my = false;
    let my_user_id = this.props.appstate.my_user_id;
    if (user_id === my_user_id) {
      my = true;
    }

    let editable = false;
    let draggable = false;
    if (my) {
      editable = true;
      draggable = true;
    }

    let raw_data;
    if (my) {
      raw_data = this.props.appstate.data_posts_my;
    } else {
      raw_data = this.props.appstate.data_posts_public;
    }
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.data.items;
    }

    console.log(table_arr);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    
    let page_title;
    let browser_url = url_lib.folder(user_id, fid);
    if (fid === 0 || fid == 0) {
      browser_url = url_lib.folder_desktop(user_id);
    }
    browser_utils.browser_url_set(browser_url);
    if (my) {
      page_title = "... My folder - PutCut";
    } else {
      page_title = "... Folder - PutCut";
    }
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_toolbar = null;
    let jsx_toolbar_user_their = null;
    let jsx_putcut_widget = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (my) {
      // toolbar for my folders
      jsx_toolbar = (
        <div className="main-toolbar clearfix">
          <div className="left layout-controls-group">
            <div className="item layout-view" onClick={this._handleClick('VS_DEFAULT')}><img title="Details view" className="change-view-style click-to-style-normal" src={process.env.PUBLIC_URL + "/static/img/view-thumblist.png"} /></div>
            <div className="item layout-view" onClick={this._handleClick('VS_ICONS')}><img title="Icons view" className="change-view-style click-to-style-icons" src={process.env.PUBLIC_URL + "/static/img/view-icons-medium.png"} /></div>
            <div className="item layout-view" onClick={this._handleClick('VS_LIST')}><img title="List view" className="change-view-style click-to-style-list" src={process.env.PUBLIC_URL + "/static/img/view-list.png"} /></div>
          </div>
          <div className="right">
            <div className="sorting">Sort by<img className="sorting-time" src={process.env.PUBLIC_URL + "/static/img/time.png"} /><img className="sorting-rating" src={process.env.PUBLIC_URL + "/static/img/star.png"} /></div>
          </div>
          <div className="clearfix"></div>
        </div>
      );

      // PUT YOUR CUT WIDGET
      let jsx_putcut_widget_closed = (
        <>
          <div className="add-link-group JS-MADE">
            <form className="putcut-folder-form" onSubmit={this._handleSubmitPutYourCut}>
              <input
                type="text"
                name="putyourcuthere"
                className="put-your-cut"
                placeholder="... Put your Cut here ... (Ctrl+V)"
                onChange={this.handleChange}
                ref={this.putyourcutRef}
              />
            </form>
            <div className="clearfix"></div>
          </div>
          <div className="clearfix"></div>
        </>
      );
      let jsx_putcut_widget_opened = (
        <>
          <div className="add-link-group JS-MADE">
            <input type="text" name="putyourcuthere" className="put-your-cut" placeholder="... Put your Cut here ... (Ctrl+V)" autoComplete="off" />
            <div className="clearfix"></div>
            <div className="new-entry-preview" style={{ display: 'block' }}>
              <div className="spinner-area new-entry-spinner" id="spinner_new_entry" style={{ display: 'none' }}>
                <div className="spinner-cell">
                  <div className="the-spinner"></div>
                </div>
                <div className="cancel-scrape-btn button-style-3" title="Cancel scraping and add link manually if scraping is taking too long"><span className="x-icon">&times;</span>cancel scraping</div>
              </div>
              <div className="links-container">
                {/*<!--add link example-->*/}
                <div className="link not-draggable new-entry-editor modal-editor" data-url="" style={{ display: 'block' }}>
                  <div className="nested-add-link-panel">
                    <div className="link-content">
                      <div className="thumb-group" data-selected-picture-id="">
                        <img className="thumbnail" src="" />
                        <div className="thumbnail-btn-prev thumbnail-btn button-style-3" data-editor-type="addnew">&lt;</div>
                        <div className="thumbnail-btn-next thumbnail-btn button-style-3" data-editor-type="addnew">&gt;</div>
                      </div>
                      <div className="link-info">
                        <form>
                          <span className="editor-field-title">Title:</span>
                          <input id="myNewTitle" name="title" type="text" value="" />
                          <div className="domain"><img className="favicon" src="" /><a rel="nofollow" target="_blank" title="" href=""></a><span></span></div>
                          <span className="editor-field-title">Description:</span>
                          <div className="">
                            <textarea id="myNewDescription" name="description" rows="8" cols="60"></textarea>
                          </div>
                          <input id="myNewPictures" name="pictures" type="hidden" value="" />
                          <input id="myNewPicturesSelected" name="selectedPictureID" type="hidden" value="" />
                        </form>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="edit-buttons-group buttons-group-style-2">
                      <div className="add-button button button-size-1 button-style-blue-1 text-style-blue-1 right" style={{ width: 110 }}>Add link</div>
                      <div className="scrape-button button button-size-1 button-style-light-1 text-style-blue-1 right" style={{ width: 110 }}>Scrape again</div>
                      <div className="cancel-button button button-size-1 button-style-light-1 text-style-blue-1 right" style={{ width: 110 }}>Cancel</div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                {/*<!--end of add link example-->*/}
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </>
      );
      jsx_putcut_widget = jsx_putcut_widget_closed;

    } else {
      jsx_toolbar_user_their = (
        <div className="user-toolbar JS-MADE" data-rendered-here="user_toolbar_their">
          <ToolbarUserTheir appstate={this.props.appstate} dispatch={this.props.dispatch} />
        </div>
      );
      // toolbar for recycle bin <ToolbarBin />
      jsx_toolbar = (
        <div className="main-toolbar clearfix">
          <div className="item layout-view" onClick={this._handleClick('VS_DEFAULT')}><img title="Details view" className="change-view-style click-to-style-normal" src={process.env.PUBLIC_URL + "/static/img/view-thumblist.png"} /></div>
          <div className="item layout-view" onClick={this._handleClick('VS_ICONS')}><img title="Icons view" className="change-view-style click-to-style-icons" src={process.env.PUBLIC_URL + "/static/img/view-icons-medium.png"} /></div>
          <div className="item layout-view" onClick={this._handleClick('VS_LIST')}><img title="List view" className="change-view-style click-to-style-list" src={process.env.PUBLIC_URL + "/static/img/view-list.png"} /></div>
        </div>
      );
    }

    if (pending === false) {

      let filtered_arr = table_arr; // NO FILTERING

      jsx_arr = filtered_arr.map((item, index) => {
        zero = false;
        // <div>{item.action}</div>
        return (
          <PostsItemLinkUniversal key={item.post_id} item={item} editable={editable} draggable={draggable} dispatch={this.props.dispatch} />
        );
      });

      if (zero === true) {
        jsx_zero_items = (
          <div className="empty-folder-msg"><p>This folder is empty.</p></div>
        );
      }

    } // end of pending === false

    let cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-default';
    if (this.state.viewstyle === 'LIST') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-list';
    } else if (this.state.viewstyle === 'ICONS') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-icons';
    }

    return (
      <>
        {jsx_toolbar_user_their}

        {jsx_toolbar}

        {jsx_putcut_widget}

        <div className={cl_viewstyle}>

          {jsx_arr}
          {jsx_zero_items}
          {jsx_spinner}

        </div>
      </>
    );
  }
}
