import React, { Component } from 'react';
import { actionNewRoute, actionUserFetch, actionUserFetchMy } from '../actions/actions.js';
import ToolbarUserMy from './ToolbarUserMy';
import ToolbarUserTheir from './ToolbarUserTheir';
import ListFollowing from './ListFollowing';
import ListFollowers from './ListFollowers';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

export default class UserProfile extends Component {

  constructor(props) {
    super(props);
    console.log('constructor UserProfile');
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let user_id = route_params.user_id;
    let my_user_id = this.props.appstate.my_user_id;
    let my = false;
    if (user_id === my_user_id) {
      my = true;
    }
    if (my) {
      dispatch(actionUserFetchMy());
    } else {
      let move = this.props.appstate.route_freshness;
      dispatch(actionUserFetch(move, user_id));
    }
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    console.log('did MOUNT');
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let single_item = [];
    let item;
    let props;

    let route_params = this.props.appstate.route_current.route_params;

    let user_id = route_params.user_id;
    let my = false;
    let my_user_id = this.props.appstate.my_user_id;
    if (user_id === my_user_id) {
      my = true;
    }

    console.log('MY ' + my);

    let raw_data;
    if (my) {
      raw_data = this.props.appstate.data_user_my;
    } else {
      raw_data = this.props.appstate.data_user; // their
    }

    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;

      if (my) {
        single_item = raw_data.data.my_user_data;
      } else {
        single_item = raw_data.data.item;
      }
      item = single_item;
      props = item;
    }

    console.log('*****USER PROFILE single item');
    console.log(single_item);

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_item = null;
    let jsx_toolbar = null;
    let jsx_toolbar_my = null;
    let jsx = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {
      zero = false;

      // CASE PROFILE
      if (my) {
        /*
        jsx_toolbar_my = (
          <div className="user-toolbar JS-MADE" data-rendered-here="user_toolbar_my">
            <ToolbarUserMy item={item} dispatch={this.props.dispatch} />
          </div>
        );
        */
        jsx_item = (
          <div className="user-profile-panel">
            <div className="clearfix"></div>
            <div className="user-profile-data-group row-2">
              <h2>About me</h2>
              <p>First Name: <b>{props.first_name}</b></p>
              <p>Last Name: <b>{props.last_name}</b></p>
              <p>Year of birth: <b>{props.birthday_year}</b></p>
              <p>Country: <b>{props.country}</b></p>
              <p>Occupations: <b>{props.occupations}</b></p>
            </div>
          </div >
        );
      } else {
        // their profile
        jsx_item = (
          <div className="user-profile-panel">
            <div className="clearfix"></div>
            <div className="user-profile-data-group row-2">
              <h2>About {props.display_name}</h2>
              <p>First Name: <b>{props.first_name}</b></p>
              <p>Last Name: <b>{props.last_name}</b></p>
              <p>Year of birth: <b>{props.birthday_year}</b></p>
              <p>Country: <b>{props.country}</b></p>
              <p>Occupations: <b>{props.occupations}</b></p>
            </div>
          </div>
        );
      }
      jsx = (
        <>
          {jsx_item}
        </>
      );



      /*
      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }
      */

    } // end of pending === false

    return (
      <>

        <div className="user-profile-ajax ajax-container user-profile-container">

          {jsx_toolbar_my}
          {jsx_item}
          {jsx_zero_items}
          {jsx_spinner}

        </div>
      </>
    );
  }
}
