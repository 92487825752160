import React, { Component } from 'react';
import MultiModalBrain from '../components/MultiModalBrain';
// import Init from './Init'; // INIT MUST BE CALLED FIRSTE. BEFORE THIS ROUTER...
import InitLoggedIn from './InitLoggedIn';
import InitAutoLogin from './InitAutoLogin';
import AuthFormLoginDrugi from '../components/AuthFormLoginDrugi';
import CheatsToolbar from '../components/CheatsToolbar';
import AppSplashScreen from '../components/AppSplashScreen';

/*
KLJUCNE RECI
STEP
PROCEDURE
PROCEED
PHASE
CYCLE OF CHANGES


Init PREFIXED components/sontainers are meant to be called once or when some phase started

PhaseStarter PREFIX komponente koje (emituju event) dispatchuju ection koji pokrece ASYNC FAZU
KORAK 1 PHASE STARTED
KORAK 2 PHASE COMPLETED

PhaseStarterInit
PhaseFinisher



Phase PREFIX komponenta koja je u stanju da zapocne fazu i da ceka na promenu propsa koji znace zavrsetak faze i zatim da sma odluci o sledecoj fazi.
Problem sa ovim bi bio u tome sto bi onda ta komponenta MORALA DA BUDE I PREZENATCIONA da nesto prikazuje tokom svog trajanja..


- ako Phase komponenta sluzi sam oda zapocne fazu dispatchovanjem action-a,
ona ipak mora odmah da outputuje neku child komponentu sto znaci da Phase komponenta neizbezno mora ili da zna sta je sledeci korak, ili da prosledi sledecoj komponenti koja ce da odluci o sledecem koraku.

PhaseInitStarted >> PhaseInitPending >> PhaseInitCompleted >> 

Sledeca komponenta mora odmah da prikazuje neku drugu komponentu (na primer SPLASH SCREEN ili SPINNER)

- Ako svaka faza ne bi pocela posebnom komponentom onda ne bih mogli da dispatchujemo na component did mount.


Init STARTED ...PENDING...
Init COMPLETED
InitAutoLogin STARTED ...PENDING...
InitAutoLogin COMPLETED

IF ELSE

InitLoggedIn
Wrapper



- problematika: kako da izvrsi vise faza jednu za drugom, a da pri toem ostne ista prezntaciona komponenta na ekranu?
Action bi morao da dispatcuje vise stvari jednu za drugom.
A react komponenta bi trebala da ceka dok se props ne 


*/

export default class RouterApp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    console.log('render() RouterApp');
    let appstate = this.props.appstate;
    let dispatch = this.props.dispatch;
    let route_name = appstate.route_current.route_name;
    let route_params = appstate.route_current.route_params;
    if (typeof route_params !== 'object') {
      route_params = {};
    }
    let app_phases = this.props.appstate.app_phases;
    let phase_init_pending = app_phases.init_pending;
    // let auth_need_initial_autologin = this.props.appstate.auth_need_initial_autologin;
    let phase_initial_autologin_pending = app_phases.initial_autologin_pending;
    let auth = this.props.appstate.auth;



    let phase = 'NONE';

    if (phase_init_pending === true) {
      phase = 'INIT';
      // INIT PHASE PENDING
    } else {
      // INIT PHASE COMLETED. PROCEED TO INIT AUTOLOGIN PHASE
      if (phase_initial_autologin_pending === true) {
        // INIT AUTOLOGIN PHASE PENDING
        // initial AutoLogin phase must be done once
        phase = 'INITIAL_AUTOLOGIN';
      } else {
        // INIT AUTOLOGIN PHASE COMLETED
        // initial AutoLogin already done...

        if (auth.is_logged_in !== true) {
          phase = 'LOGGED_OUT'; // if logged out
        } else {
          phase = 'LOGGED_IN'; // if logged in
        }
      }
    }

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx = null;
    let jsx_pending = null;
    let jsx_cheats = null;

    if (appstate.cheats.enabled === true) {
      jsx_cheats = (
        <CheatsToolbar appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
    }

    let jsx_app = (
      <AppSplashScreen />
    );

    console.log('PHASE: ', phase);
    if (phase === 'INIT') {
      // ...DISPLAY INTRO SPLASH UNTIL COMPLETED
      jsx_app = (
        <AppSplashScreen />
      );
    } else if (phase === 'INITIAL_AUTOLOGIN') {
      jsx_app = (
        <InitAutoLogin appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
    } else if (phase === 'LOGGED_OUT') {
      jsx_app = (
        <AuthFormLoginDrugi appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
    } else if (phase === 'AUTOLOGIN') {
      // autologin after login form success
    } else if (phase === 'LOGGED_IN') {
      jsx_app = (
        <InitLoggedIn route_params={route_params} appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
    }


    return (
      <>
        <MultiModalBrain appstate={this.props.appstate} dispatch={this.props.dispatch} />
        {jsx_cheats}
        {jsx_app}
      </>
    );
  }
}
