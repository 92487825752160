import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

export default class ModalPending extends Component {
  constructor(props) {
    super(props);
    console.log('consttructor ModalSpinner');
    console.log(this.props);
    // binding this
    // this._handleClick = this._handleClick.bind(this);
  }

  render() {
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let modal_args = this.props.modal_args;
    let pending = modal_args.pending;
    let title = modal_args.title;
    let message = modal_args.message;

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_btn = (
      <SpinnerNormal />
    );
    if (pending === false) {
      jsx_btn = (
        <div className="flex-bar center gaps buttons-group-style-2">
          <div className="xi btn button-size-1" onClick={dispatch_close_modal}>Ok</div>
        </div>
      );
    }

    return (
      <>
        <div className="modal-item-content thisis-ModalPending">
          <h2>{title}</h2>
          <p>{message}</p>
          {jsx_btn}
        </div>
      </>
    );
  }
}
