import config from './putcut-react-config.js';  // TODO RAZLIKA OD SAJTA DO SAJTA

import { ajax_utils } from './frfr-ajax-abstract.js';
import { auth_steps } from './frfr-ajax-auth-steps.js'; // token, cokies and localStorage

import { store } from '../index.js' // NOVO dispatch

// AJAX FRAMEWORK VS APP abstract function names but site dependent function content...


let test_a = 'test a';

let resolve_after_ms = async function (x, ms) {
  if (typeof ms !== 'number') {
    ms = 7000;
  }
  return new Promise(resolve => {
    setTimeout(function () { resolve(x) }, ms);
  });
}

let ajax_simulator = function (data) {
  resolve_after_ms(data, 5000);
}

let resolve_after_7_second = async function (x, ms) {
  if (typeof ms === 'undefined') {
    ms = 7000;
  }
  return new Promise(resolve => {
    setTimeout(function () { resolve(x) }, ms);
  });
}




/*
let test_token = '123456789';
test_token = config.initial_token_value;

// https://www.ibm.com/support/knowledgecenter/en/SS6PEW_9.4.0/com.ibm.help.custom.restapis.doc/c_SpecifyingHTTPHeaders.html



let content_type_json = 'application/json';
let content_type_urlencoded = 'application/x-www-form-urlencoded;charset=UTF-8';
// ajaxpost_headers_set('Content-Type', content_type_urlencoded);



// ----INIT
let putcut_header_token_key = config.header_token_key; // 'X-PutCut-Session' je naziv headera za putcut.com
let header_token_key = putcut_header_token_key;
let cookie_token_key = config.cookie_token_name;


auth_steps.local_token_key_set(cookie_token_key); // name of the cookie
auth_steps.header_token_key_set(header_token_key); // name of the header

ajax_utils.init(); // INIT HEADERS
ajax_utils.log();
let initial_getmethod_headers_arr = [
  [header_token_key, test_token]
];
let initial_postmethod_headers_arr = [
  [header_token_key, test_token],
  ['Content-Type', content_type_urlencoded]
];
ajax_utils.getmethod_headers_set_arr(initial_getmethod_headers_arr);
ajax_utils.postmethod_headers_set_arr(initial_postmethod_headers_arr);
ajax_utils.log();
*/



/*
website.net/api/v1/someapie/get:1 Failed to load resource: the server responded with a status of 404 (Not Found)

:3000/#:1 Access to fetch at 'https://website.net/api/v1/someapi/get' from origin 'http://localhost:3000' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: It does not have HTTP ok status.
*/

// https://stackoverflow.com/questions/21783079/ajax-in-chrome-sending-options-instead-of-get-post-put-delete

// https://developer.mozilla.org/en-US/docs/Web/Security/Same-origin_policy

// https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS

// RESENJE:
// https://developer.mozilla.org/en-US/docs/Web/HTTP/CORS#Access-Control-Allow-Headers
/*
The Access-Control-Allow-Headers header is used in response to a preflight request to indicate which HTTP headers can be used when making the actual request.

Access-Control-Allow-Headers: <field-name>[, <field-name>]*
*/
// Access-Control-Allow-Headers: X-PutCut-Session, X-Session-ID
// Access-Control-Allow-Origin: *
// Access-Control-Allow-Headers: X-PutCut-Session, X-Session-ID




// NAJNOVIJE GOTOVO



// let universal_fetch_get = async function (fetch_url, description) {
let universal_fetch_get = async (fetch_url, description) => {
  console.log('++ UNIVERSAL FETCH GET pre  ' + description + '  ' + fetch_url);
  let result = await ajax_utils.getmethod_send(fetch_url);
  // console.log('posle fetchovanja UNIVERSAL GET');
  console.log('++ UNIVERSAL FETCH GET POSLE  ' + description + '  ' + fetch_url);
  console.log(result);
  // console.log(JSON.stringify(result)); // JSON-string from `response.json()` call
  return result;
};


// let universal_fetch_post = async function (fetch_url, data, description) {
let universal_fetch_post = async (fetch_url, data, description) => {
  console.log('++ UNIVERSAL FETCH POST pre  ' + description + '  ' + fetch_url);
  console.log(data);
  let result = await ajax_utils.postmethod_send(fetch_url, data);
  // console.log('posle fetchovanja UNIVERSAL POST');
  console.log('++ UNIVERSAL FETCH POST POSLE  ' + description + '  ' + fetch_url);
  console.log(result);
  // console.log(JSON.stringify(result)); // JSON-string from `response.json()` call
  return result;
};



// NOVO MINI FETCH

let minimal_ajax_get = async (fetch_url, log, description) => {
  // ASYNC
  // NO ERROR CATCHING
  if (log === true) {
    // console.log('++ MINI FETCH GET pre  ' + description + '  ' + fetch_url);
    /*
    console.log('++ minimal_ajax_get BEFORE  ' + description + '  ' + fetch_url);
    // https://developer.mozilla.org/en-US/docs/Web/API/console#Using_string_substitutions
    console.log('test %cCOLORIZE TEST', 'background-color: pink;padding: 0px');
    let string_c = 'test %cCOLORIZE TEST';
    console.log(string_c, 'background-color: #c8c0f6;padding: 0px');
    */
    let log_string = '%c' + 'minimal_ajax_get BEFORE  ' + description + '  ' + fetch_url;
    console.log(log_string, 'background-color: #c8c0f6;border-radius:5px;padding:1px');

  }
  let result = await ajax_utils.getmethod_send(fetch_url);
  if (log === true) {
    // console.log('++ minimal_ajax_get AFTER  ' + description + '  ' + fetch_url);
    let log_string = '%c' + 'minimal_ajax_get AFTER  ' + description + '  ' + fetch_url;
    console.log(log_string, 'background-color: #c8c0f6;border-radius:0px;padding:1px');
    console.log(result);
    // console.log(JSON.stringify(result)); // JSON-string from `response.json()` call
  }
  return result;
};

/*
export let mini_fetch_post = async (fetch_url, data, description) => {
  console.log('++ MINI FETCH POST pre  ' + description + '  ' + fetch_url);
  console.log(data);
  let result = await ajax_utils.postmethod_send(fetch_url, data);
  // console.log('posle fetchovanja UNIVERSAL POST');
  console.log('++ MINI FETCH POST POSLE  ' + description + '  ' + fetch_url);
  console.log(result);
  // console.log(JSON.stringify(result)); // JSON-string from `response.json()` call
  return result;
};
*/
let minimal_ajax_post = async (fetch_url, data, log, description) => {
  // ASYNC
  // NO ERROR CATCHING
  if (log === true) {
    // console.log('++ minimal_ajax_post BEFORE  ' + description + '  ' + fetch_url);
    let log_string = '%c' + 'minimal_ajax_post BEFORE  ' + description + '  ' + fetch_url;
    console.log(log_string, 'background-color: #f8a048;border-radius:5px;padding:1px');
    console.log(data);
  }
  let result = await ajax_utils.postmethod_send(fetch_url, data);
  if (log === true) {
    // console.log('++ minimal_ajax_post AFTER  ' + description + '  ' + fetch_url);
    let log_string = '%c' + 'minimal_ajax_post AFTER  ' + description + '  ' + fetch_url;
    console.log(log_string, 'background-color: #f8a048;border-radius:0px;padding:1px');
    console.log(result);
    // console.log(JSON.stringify(result)); // JSON-string from `response.json()` call
  }
  return result;
};


export let mini_ajax_get = (fetch_url, callback, cb_error, log, description) => {
  if (typeof description !== 'string') {
    description = '';
  }
  minimal_ajax_get(fetch_url, log, description)
    .then(response => response.json())
    .then(result => {
      console.log('mini_ajax_get DONE :) ');
      console.log(result);
      return result;
    })
    .then(result => {
      // AFTER SUCCESS MAKE CALLBACK
      if (callback) {
        if (typeof callback === 'function') {
          callback(result); // SUCCESS CALLBACK
        } else {
          console.log('callback is not a function');
        }
      }
    })
    .catch(e => {
      console.error(':(  mini_ajax_get CATCH ERROR - e: ');
      console.log(e);
      if (cb_error) {
        if (typeof cb_error === 'function') {
          cb_error(e); // ERROR CALLBACK
        } else {
          console.log('cb_error is not a function');
        }
      }
    });
};



export let mini_ajax_post = (fetch_url, data, callback, cb_error, log, description) => {
  if (typeof description !== 'string') {
    description = '';
  }
  minimal_ajax_post(fetch_url, data, log, description)
    .then(response => response.json())
    .then(result => {
      console.log('mini_ajax_post DONE :) ');
      console.log(result);
      return result;
    })
    .then(result => {
      // AFTER SUCCESS MAKE CALLBACK
      if (callback) {
        if (typeof callback === 'function') {
          callback(result); // SUCCESS CALLBACK
        } else {
          console.log('callback is not a function');
        }
      }
    })
    .catch(e => {
      console.error(':(  mini_ajax_post CATCH ERROR - e: ');
      console.log(e);
      if (cb_error) {
        if (typeof cb_error === 'function') {
          cb_error(e); // ERROR CALLBACK
        } else {
          console.log('cb_error is not a function');
        }
      }
    });
};





export { ajax_simulator, resolve_after_ms, test_a, universal_fetch_get, universal_fetch_post };

