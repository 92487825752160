import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import { actionMultimodalOpen } from '../actions/frfr-multimodal-actions.js';
import NavMainMenu from './NavMainMenu';
import NavMyAccountGroup from './NavMyAccountGroup';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focused_search: true
    };
    this.handleChange = this.handleChange.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._handleSubmitSearch = this._handleSubmitSearch.bind(this);
    this._handleSubmitPutYourCutPlus = this._handleSubmitPutYourCutPlus.bind(this);
    this._submitPastedLink = this._submitPastedLink.bind(this);
    // Create the ref
    this.putyourcutPlusRef = React.createRef();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });

    // PUT YOUR CUT EVENT
    if (name === 'putyourcuthere_plus') {
      console.log('CHANGED INPUT');
      let dom_input = this.putyourcutPlusRef.current;
      if (dom_input) {
        // dom_input.blur(); // UNFOCUS INPUT FIELD
      }
      this._submitPastedLink(value);
    }
  }

  _handleSubmitSearch(e) {
    e.preventDefault(e);
    console.log('_handleSubmitSearch(e) SUBMIT ');
    let dispatch = this.props.dispatch;
    //
    let search_query = this.refs.search.value;
    dispatch(actionNewRoute('SEARCH', { search_query: search_query }));
  }

  _handleSubmitPutYourCutPlus(e) {
    e.preventDefault(e);
    console.log('_handleSubmitPutYourCutPlus(e) SUBMIT ');
    let dom_input = this.putyourcutPlusRef.current;
    if (dom_input) {
      // dom_input.blur(); // UNFOCUS INPUT FIELD
    }
    let value = this.state.putyourcuthere_plus;
    this._submitPastedLink(value);
  }

  _submitPastedLink(value) {
    console.log('_submitPutYourCutPlus(e) SUBMIT ');
    console.log('PASTED');
    console.log(value); // for example https://yts.lt/
    if (value !== '') {
      let dom_input = this.putyourcutPlusRef.current;
      if (dom_input) {
        // dom_input.blur(); // UNFOCUS INPUT FIELD
      }
      let fid = 0; // za sada ovaj model snima samo na desktop... TODO
      let user_id = this.props.appstate.my_user_id; // MY
      let modal_content_data = {
        mode: 'ADD',
        url: value,
        fid,
        user_id
      };
      let dispatch = this.props.dispatch;
      dispatch(actionMultimodalOpen('MODAL_LINK_EDITOR', modal_content_data));
    }
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'FOCUSED_SEARCH') {
      return (e) => {
        e.preventDefault();
        if (this.state.focused_search !== true) {
          this.setState({
            focused_search: true
          });
        }
      }
    }
    if (what === 'FOCUSED_PLUS') {
      return (e) => {
        e.preventDefault();
        if (this.state.focused_search !== false) {
          this.setState({
            focused_search: false
          });
        }
      }
    }
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {

    console.log(this.props);

    let cl_header = "header header-case-is-search";
    if (this.state.focused_search === false) {
      cl_header = "header header-case-is-plus";
    }

    /*
    REACT UCENJE:
    index.js:1375 Warning: Failed prop type: You provided a `value` prop to a form field without an `onChange` handler. This will render a read-only field. If the field should be mutable use `defaultValue`. Otherwise, set either `onChange` or `readOnly`.
    */

    let jsx_subheader = null;
    /*
    jsx_subheader = (
      <div className="new-entry-preview new-entry-preview-plus" style={{ display: 'none' }}>
        <div className="spinner-area new-entry-spinner" style={{ display: 'none' }}>
          <div className="spinner-cell">
            <div className="the-spinner"></div>
          </div>
          <div className="cancel-scrape-btn button-style-3" title="Cancel scraping and add link manually if scraping is taking too long"><span className="x-icon">&times;</span>cancel scraping</div>
        </div>
        <div className="links-container">
          <!--add link example-->
          <div className="link not-draggable new-entry-editor modal-editor" data-url="" style={{ display: 'none' }}>
            <div className="nested-add-link-panel">
              <div className="link-content">
                <div className="thumb-group" data-selected-picture-id="">
                  <img className="thumbnail" src="" />
                  <div className="thumbnail-btn-prev thumbnail-btn button-style-3" data-editor-type="addnew">&lt;</div>
                  <div className="thumbnail-btn-next thumbnail-btn button-style-3" data-editor-type="addnew">&gt;</div>
                </div>
                <div className="link-info">

                  <form>
                    <span className="editor-field-title">Title:</span>
                    <input id="myNewTitle" name="title" type="text" value="" />
                    <div className="domain"><img className="favicon" src="" /><a rel="nofollow" target="_blank" title="" href=""></a><span></span></div>
                    <span className="editor-field-title">Description:</span>
                    <div className="">
                      <textarea id="myNewDescription" name="description" rows="8" cols="60"></textarea>
                    </div>
                    <input id="myNewPictures" name="pictures" type="hidden" value="" />
                    <input id="myNewPicturesSelected" name="selectedPictureID" type="hidden" value="" />
                  </form>

                </div>
              </div>
              <div className="clearfix"></div>
              <div className="edit-buttons-group buttons-group-style-2">
                <div className="add-button button button-size-1 button-style-blue-1 text-style-blue-1 right" style={{ width: 110 }}>Add link</div>
                <div className="scrape-button button button-size-1 button-style-light-1 text-style-blue-1 right" style={{ width: 110 }}>Scrape again</div>
                <div className="cancel-button button button-size-1 button-style-light-1 text-style-blue-1 right" style={{ width: 110 }}>Cancel</div>
              </div>
              <div className="edit-buttons-group buttons-group-style-2">
                <div className="folder-selector right">
                  <select id="folder-select-value">
                    <option value="0">Desktop</option>
                  </select>
                </div>
                <span className="folder-selector-label right">Save link to folder: </span>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
          <!--end of add link example-->
        </div>
      </div>
      <div className="clear"></div>
    );
    */

    return (
      <>
        <div className={cl_header}>
          <div className="header-big">
            <div className="inner">
              <div className="logo">
                <a href={process.env.PUBLIC_URL}>
                  <img src={process.env.PUBLIC_URL + "/static/img/logo-blue.png"} />
                </a>
              </div>
              <div className="plus-box add-link-plus-group">
                <form className="plus-putcut" onSubmit={this._handleSubmitPutYourCutPlus}>
                  <input
                    type="text"
                    name="putyourcuthere_plus"
                    className="put-your-cut-plus"
                    placeholder="... Put your Cut here ... (Ctrl+V)"
                    autoComplete="off"
                    onChange={this.handleChange}
                    ref={this.putyourcutPlusRef}
                  />
                </form>
                <div className="plus-button click-to-add-plus-form" title="Add new link" onClick={this._handleClick('FOCUSED_PLUS')}></div>


                <div className="subheader">

                  {jsx_subheader}

                </div>


              </div>
              <div className="search-box">
                <form className="search-putcut" onSubmit={this._handleSubmitSearch}>
                  <input
                    ref="search"
                    type="search"
                    name="q"
                    className="search-field"
                    placeholder="search in putcut..."
                    onFocus={this._handleClick('FOCUSED_SEARCH')}
                  />
                </form>
              </div>

              <NavMyAccountGroup appstate={this.props.appstate} dispatch={this.props.dispatch} />

              <NavMainMenu dispatch={this.props.dispatch} />

            </div>
          </div>
        </div>


        <div className="fix-header-scrolled">
        </div>
      </>

    );
  }
}

