const DOMAIN_PROD = "putcut.com";
const WEBSITE_PREFIX = process.env.PUBLIC_URL; //'https://putcut.com';
const API_PREFIX_DEV = 'https://webservices-v4.putcut.com';
const API_PREFIX_PROD = 'https://webservices.putcut.com';
const config = {
  cookie_token_name: 'putcut_session',
  header_token_key: 'X-PutCut-Session',
  temp_token_key: 'X-PutCut-Session',
  test_token_value: '123456789',
  initial_token_value: 'none_token_placeholder',
  website_url_prefix: WEBSITE_PREFIX,
  api_prefix_dev: API_PREFIX_DEV,
  api_prefix_prod: API_PREFIX_PROD,
  env_prod_domain: DOMAIN_PROD
};
export default config;
