// import { routes, main_menu_options } from './routes.js';
import { multimodal_reducer_helper } from './multimodal-reducer-helper';
import { arr_utils } from '../utils/frfr-array-utils.js';
import { routes } from './putcut-routes.js';


// let routes = [];

let default_my_user_data = {
  is_logged_in: false,
  role: 'INITIAL',
  is_superadmin: false,
  is_moderator: false,
  username: '',
  token: '',
  blabla: 'default LOGGED OUT user data'
};





const initialState = {
  app_phases: {
    init_pending: true,
    initial_autologin_pending: true,
  },
  auth: {
    is_logged_in: false,
    pending: true,
  },
  routing_need_initial_rerouting: true,
  route_simple: '***DELETE',
  route_freshness: 0,
  route_current: {
    route_name: 'INITIAL',
    route_type: 'DEFAULT',
    route_params: {},
    data: {
      route_title: 'Dobrodošli',
      page_description: 'Neki opis',
      breadcrumbs: [{
        route_name: 'HOME',
        route_title: 'Početna'
      }],
    }
  },
  routes: routes,
  cheats: {
    enabled: false
  },
  my_user_id: 'PENDING',
  context_menu: {
    opened: false,
    postop: 0,
    posleft: 0
  },
  sidebar_active_item: 'nothing zero none',
  selected_search_section: 3,
  data_my_user_data: {
    pending: true,
    data: {}
  },
  data_folders_my: {
    pending: true,
    data: {}
  },
  data_folders_public: {
    pending: true,
    move: 0,
    key: 'INITIAL NONE',
    data: {}
  },
  data_activities: {
    pending: true,
    pending_more: false,
    data: {},
    activities_items: []
  },
  data_feeds_items: {
    pending: true,
    data: {},
  },
  data_search_proba: {
    pending: true,
    move: 0,
    data: {},
  },
  data_posts_my: {
    pending: true,
    move: 0,
    data: {}
  },
  data_bin: {
    pending: true,
    move: 0,
    data: {}
  },
  data_posts_public: {
    pending: true,
    move: 0,
    data: {}
  },
  data_posts_single: {
    pending: true,
    move: 0,
    data: {}
  },
  data_user: {
    pending: true,
    move: 0,
    key: 'INITIAL NONE',
    data: {}
  },
  data_user_my: {
    pending: true,
    data: {}
  },
  data_social_following: {
    pending: true,
    move: 0,
    key: 'INITIAL NONE',
    data: {}
  },
  data_social_followers: {
    pending: true,
    move: 0,
    key: 'INITIAL NONE',
    data: {}
  },
  multimodal: {
    modals: []
  },
  separator_separator_separator: '-----i am separator-----',
  counter: 0,
  readme: 'Ovo je prvobitni state',
  my_user_data: {},
  user_current: {
    display_name: 'Administrator',
    avatar_src: 'assets/avatar-admin.png',
    avatar_src_2: 'assets/avatar-admin-orange.png'
  }
}

const rootReducer = (state = initialState, action) => {
  console.log(action);
  let app_phases;
  let move;
  let key;
  switch (action.type) {
    case 'BLA_BLA_BLA':
      return state;
    case 'CHEATS_ENABLE':
      return Object.assign({}, state, {
        cheats: {
          enabled: true,
        }
      });
    case 'INIT_COMPLETED':
      app_phases = Object.assign({}, state.app_phases, {
        init_pending: false
      });
      return Object.assign({}, state, {
        app_phases: app_phases
      });
    case 'TEST_AUTH_SIMULATE_LOGIN':
      app_phases = Object.assign({}, state.app_phases, {
        initial_autologin_pending: false
      });
      return Object.assign({}, state, {
        app_phases: app_phases,
        auth: {
          is_logged_in: true,
          pending: false
        },
        my_user_data: default_my_user_data
      });
    case 'AUTH_LOGIN_FAIL':
      app_phases = Object.assign({}, state.app_phases, {
        initial_autologin_pending: false
      });
      return Object.assign({}, state, {
        app_phases: app_phases,
        auth: {
          is_logged_in: false,
          pending: false
        },
        my_user_data: default_my_user_data
      });
    case 'AUTH_LOGOUT_DONE':
      return Object.assign({}, state, {
        auth: {
          is_logged_in: false,
          pending: false
        },
        my_user_data: default_my_user_data
      });
    case 'AUTH_TRY_LOGIN':
      return Object.assign({}, state, {
        auth: {
          is_logged_in: false,
          pending: true
        }
      });
    case 'AUTH_TRY_AUTOLOGIN':
      return Object.assign({}, state, {
        auth: {
          is_logged_in: false,
          pending: true
        }
      });
    case 'AUTH_AUTOLOGIN_COMPLETE':
      let my = action.payload;
      app_phases = Object.assign({}, state.app_phases, {
        initial_autologin_pending: false
      });
      return Object.assign({}, state, {
        app_phases: app_phases,
        auth: {
          is_logged_in: true,
          pending: false
        },
        data_my_user_data: {
          pending: false,
          data: my.my_user_data
        },
        my_user_id: my.my_user_data.user_id
      });

    case 'MULTIMODAL_ACTION':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_BEFORE_CLOSE_MODAL':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_CLOSE_MODAL':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_OPEN_MODAL_FRFR_ERF':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_OPEN_MODAL_FRFR_SUCCESS':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_OPEN_MODAL_SUCCESS':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_OPEN_MODAL_CATCH':
      return multimodal_reducer_helper(state, action);
    case 'MULTIMODAL_OPEN_MODAL_ADD_LINK':
      return multimodal_reducer_helper(state, action);

    case 'FOLDERS_MY_FETCHING':
      return Object.assign({}, state, {
        data_folders_my: {
          pending: true,
          data: {}
        }
      });
    case 'FOLDERS_MY_FETCHED':
      return Object.assign({}, state, {
        data_folders_my: {
          pending: false,
          data: action.payload
        }
      });
    case 'FOLDERS_PUBLIC_FETCHING':
      return Object.assign({}, state, {
        data_folders_public: {
          pending: true,
          move: action.meta.move,
          key: action.meta.key,
          data: {}
        }
      });
    case 'FOLDERS_PUBLIC_FETCHED':
      move = state.data_folders_public.move; // pending move
      key = state.data_folders_public.key;
      if (action.meta.key === key) {
        return Object.assign({}, state, {
          data_folders_public: {
            pending: false,
            move: move,
            key: key,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        // fetched data is not that waht we need (not that key). do nothing
        return state;
      }

    case 'ACTIVITIES_FETCHING':
      return Object.assign({}, state, {
        data_activities: {
          pending: true,
          pending_more: false,
          data: {},
          activities_items: []
        }
      });
    case 'ACTIVITIES_FETCHED':
      return Object.assign({}, state, {
        data_activities: {
          pending: false,
          pending_more: false,
          data: action.payload,
          activities_items: action.payload.items
        }
      });
    case 'ACTIVITIES_MORE_FETCHING':
      let new_actmf1 = Object.assign({}, state.data_activities, {
        pending_more: true
      });
      return Object.assign({}, state, {
        data_activities: new_actmf1
      });
    case 'ACTIVITIES_MORE_FETCHED':
      let activities_more_arr = action.payload.items;
      let old_act_arr = state.data_activities.activities_items;
      let new_act_arr = arr_utils.pure_merge_without_duplicates(old_act_arr, activities_more_arr);
      let new_actmf2 = Object.assign({}, state.data_activities, {
        pending_more: false,
        activities_items: new_act_arr
      });
      return Object.assign({}, state, {
        data_activities: new_actmf2
      });
    case 'FEEDS_FETCHING':
      return Object.assign({}, state, {
        data_feeds_items: {
          pending: true,
          data: {}
        }
      });
    case 'FEEDS_FETCHED':
      return Object.assign({}, state, {
        data_feeds_items: {
          pending: false,
          data: action.payload
        }
      });
    case 'SEARCH_FETCHING':
      return Object.assign({}, state, {
        data_search_proba: {
          pending: true,
          move: action.meta.move,
          data: {}
        }
      });
    case 'SEARCH_FETCHED':
      move = state.data_search_proba.move;
      if (action.meta.move === move) {
        return Object.assign({}, state, {
          data_search_proba: {
            pending: false,
            move: move,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        return state;
      }
    case 'USER_FETCHING':
      return Object.assign({}, state, {
        data_user: {
          pending: true,
          move: action.meta.move,
          key: action.meta.key,
          data: {}
        }
      });
    case 'USER_FETCHED':
      move = state.data_user.move;
      key = state.data_user.key;
      if (action.meta.key === key) {
        return Object.assign({}, state, {
          data_user: {
            pending: false,
            move: move,
            key: key,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        // fetched data is not that waht we need (not that key). do nothing
        return state;
      }
    case 'USER_MY_FETCHING':
      return Object.assign({}, state, {
        data_user_my: {
          pending: true,
          data: {}
        }
      });
    case 'USER_MY_FETCHED':
      return Object.assign({}, state, {
        data_user_my: {
          pending: false,
          data: action.payload
        }
      });
    case 'POSTS_MY_FETCHING':
      return Object.assign({}, state, {
        data_posts_my: {
          pending: true,
          move: action.meta.move,
          data: {}
        }
      });
    case 'POSTS_MY_FETCHED':
      move = state.data_posts_my.move;
      if (action.meta.move === move) {
        return Object.assign({}, state, {
          data_posts_my: {
            pending: false,
            move: move,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        return state;
      }
    case 'BIN_MY_FETCHING':
      return Object.assign({}, state, {
        data_bin: {
          pending: true,
          move: action.meta.move,
          data: {}
        }
      });
    case 'BIN_MY_FETCHED':
      move = state.data_bin.move;
      if (action.meta.move === move) {
        return Object.assign({}, state, {
          data_bin: {
            pending: false,
            move: move,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        return state;
      }
    case 'POSTS_PUBLIC_FETCHING':
      return Object.assign({}, state, {
        data_posts_public: {
          pending: true,
          move: action.meta.move,
          data: {}
        }
      });
    case 'POSTS_PUBLIC_FETCHED':
      move = state.data_posts_public.move;
      if (action.meta.move === move) {
        return Object.assign({}, state, {
          data_posts_public: {
            pending: false,
            move: move,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        return state;
      }
    case 'GUI_FOLDER_CONTEXT_MENU_OPEN':
      let temp_context_menu = action.payload;
      temp_context_menu.opened = true;
      return Object.assign({}, state, {
        context_menu: temp_context_menu
      });
    /*
      payload: {
        type: 'FOLDER',
        posleft: posleft,
        postop: postop,
        fid: fid,
        folder_level: folder_level,
        folder_name: folder_name
      }
    */
    case 'GUI_FOLDER_CONTEXT_MENU_CLOSE':
      return Object.assign({}, state, {
        context_menu: {
          opened: false
        }
      });

    case 'GUI_SEARCH_SECTION_SELECTED':
      return Object.assign({}, state, {
        selected_search_section: action.payload
      });

    case 'POSTS_SINGLE_FETCHING':
      return Object.assign({}, state, {
        data_posts_single: {
          pending: true,
          move: action.meta.move,
          data: {}
        }
      });
    case 'POSTS_SINGLE_FETCHED':
      move = state.data_posts_single.move;
      if (action.meta.move === move) {
        return Object.assign({}, state, {
          data_posts_single: {
            pending: false,
            move: move,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        return state;
      }

    case 'SOCIAL_FOLLOWING_FETCHING':
      return Object.assign({}, state, {
        data_social_following: {
          pending: true,
          move: action.meta.move,
          key: action.meta.key,
          data: {}
        }
      });
    case 'SOCIAL_FOLLOWING_FETCHED':
      move = state.data_social_following.move;
      key = state.data_social_following.key;
      if (action.meta.key === key) {
        return Object.assign({}, state, {
          data_social_following: {
            pending: false,
            move: move,
            key: key,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        // fetched data is not that waht we need (not that key). do nothing
        return state;
      }
    case 'SOCIAL_FOLLOWERS_FETCHING':
      return Object.assign({}, state, {
        data_social_followers: {
          pending: true,
          move: action.meta.move,
          key: action.meta.key,
          data: {}
        }
      });
    case 'SOCIAL_FOLLOWERS_FETCHED':
      move = state.data_social_followers.move;
      key = state.data_social_followers.key;
      if (action.meta.key === key) {
        return Object.assign({}, state, {
          data_social_followers: {
            pending: false,
            move: move,
            key: key,
            data: action.payload
          }
        });
      } else {
        // fetched data is obsolete. do nothing
        // fetched data is not that waht we need (not that key). do nothing
        return state;
      }


    // ----------------------------------------



    case 'ROUTE_NEW':
      let new_route = {};
      let sidebar_active_item = "nothing zero none";
      let route_founded = false;
      let route_founded_item = {};
      state.routes.forEach((item, index) => {
        if (action.payload.target === item.route_key) {
          // izaberi tu rutu...
          route_founded = true;
          route_founded_item = item;
          if (route_founded_item.sidebar_active_item) {
            sidebar_active_item = route_founded_item.sidebar_active_item;
          }
        }
      });
      /*
      if (route_founded) {
        return Object.assign({}, state, {
          route_current: new_route,
          route_freshness: action.payload.freshness
        });
      } else {
        */
      // NOVO ZA SIMPLE
      /*
      return Object.assign({}, state, {
        route_simple: action.payload.target,
        route_freshness: action.payload.freshness
      });
      */
      // NAJNOVIJE
      new_route = {
        route_name: action.payload.target,
        route_type: 'DEFAULT',
        route_params: action.payload.route_params
      };
      return Object.assign({}, state, {
        route_current: new_route,
        route_freshness: action.payload.freshness,
        sidebar_active_item: sidebar_active_item
      });
      // }
      // else
      return state;

    case 'ROUTE_REFRESH':
      return Object.assign({}, state, {
        route_freshness: action.payload.freshness
      });
      return state;

    case '_OBS_ROUTE_NEW':
      return Object.assign({}, state, {
        route_simple: action.payload,
        route_name: action.payload
      });
    default:
      return state;
  }
};

export default rootReducer
