import React, { Component } from 'react';
import MultiModalItemContentRouter from './MultiModalItemContentRouter';
import { actionMultimodalClose, actionMultimodalDelete } from '../actions/frfr-multimodal-actions.js';

export default class MultiModalItem extends React.Component {
  //
  constructor(props) {
    super(props);
    // binding this
    this._handleClick = this._handleClick.bind(this);
    this._onTransitionEnd = this._onTransitionEnd.bind(this);
  }

  _onTransitionEnd(e, item) {
    console.log('_onTransitionEnd EVENT');
    // console.log(e);
    console.log(item);
    if (item.animation_before_delete === true) {
      let dispatch = this.props.dispatch;
      let modal_id = this.props.id;
      this._delete_modal(dispatch, modal_id);
    }
  };

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'CLOSE') {
      return () => {
        console.log('CLICKED');
        this._delete_modal(dispatch, second);
      }
    }
    if (what === 'ANIMATED_CLOSE') {
      return () => {
        console.log('CLICKED');
        this._animate_to_delete_modal(dispatch, second);
      }
    }
  };

  _animate_to_delete_modal(dispatch, payload) {
    /*
    dispatch({
      type: 'MULTIMODAL_BEFORE_CLOSE_MODAL',
      payload: payload
    });
    */
    dispatch(actionMultimodalClose(payload));
  }

  _delete_modal(dispatch, payload) {
    /*
    dispatch({
      type: 'MULTIMODAL_CLOSE_MODAL',
      payload: payload
    });
    */
    dispatch(actionMultimodalDelete(payload));
  }

  render() {
    let item = this.props.item;
    let modal_id = this.props.id;

    console.log('*****MM ITEM');
    console.log(item);
    console.log(modal_id);

    let type = item.type;


    // testing
    let cl = 'testsingle initial';
    if (item.animation_before_delete === true) {
      cl = 'testsingle test-fadeout';
    }
    let jsx_test_clickable = (
      <div className={cl} onTransitionEnd={(e) => this._onTransitionEnd(e, item)} onClick={this._handleClick('ANIMATED_CLOSE', modal_id)}>nesto {modal_id}</div>
    );
    let jsx_test_fadeout = (
      <div className={cl} onTransitionEnd={(e) => this._onTransitionEnd(e, item)}>nesto {modal_id}</div>
    );
    let jsx_test = jsx_test_clickable;
    if (item.animation_before_delete === true) {
      jsx_test = jsx_test_fadeout;
    }





    let jsx_multimodal_item = null;

    let _dispatch_close_modal = (modal_id) => {
      let dispatch = this.props.dispatch;
      return () => {
        console.log('* TEST 1');
        this._animate_to_delete_modal(dispatch, modal_id);
      }
    }

    let jsx_cl_wrap = 'multimodal-item'; // wrapper class
    let jsx_cl_panel = 'modal-panel basic'; // panel class

    let jsx_btn_close_clickable = (
      <div className="modal-close" onClick={this._handleClick('ANIMATED_CLOSE', modal_id)}>×</div>
    );
    let jsx_btn_close_fadeout = (
      <div className="modal-close">×</div>
    );

    if (type === 'BASIC') {
      // CASE BASIC (SIZE) MULTIMODAL ITEM

      // <a class="modal-close">×</a>
      let jsx_btn_close = null;
      let jsx_x_character = '×';

      jsx_cl_wrap = 'multimodal-item basic'; // wrapper class
      jsx_cl_panel = 'modal-panel basic'; // panel class

      jsx_btn_close = jsx_btn_close_clickable;
      if (item.animation_before_delete === true) {
        jsx_btn_close = jsx_btn_close_fadeout;
        jsx_cl_wrap = 'multimodal-item animate-out';
      }

      jsx_multimodal_item = (
        <div className={jsx_cl_wrap} onTransitionEnd={(e) => this._onTransitionEnd(e, item)} >

          <div className={jsx_cl_panel}>
            {jsx_btn_close}
            <MultiModalItemContentRouter modal_id={this.props.id} multimodal_item={this.props.item} dispatch={this.props.dispatch} dispatch_close_modal={_dispatch_close_modal(this.props.id)} />
          </div>

        </div>
      );
    }


    if (type === 'THEATER') {
      jsx_cl_wrap = 'multimodal-item theater'; // wrapper class
      jsx_cl_panel = 'modal-panel'; // panel class
      let jsx_btn_close = jsx_btn_close_clickable;
      if (item.animation_before_delete === true) {
        jsx_btn_close = jsx_btn_close_fadeout;
        jsx_cl_wrap = 'multimodal-item animate-out';
      }
      jsx_multimodal_item = (
        <div className={jsx_cl_wrap} onTransitionEnd={(e) => this._onTransitionEnd(e, item)} >

          <div className={jsx_cl_panel}>
            {jsx_btn_close}
            <MultiModalItemContentRouter modal_id={this.props.id} multimodal_item={this.props.item} dispatch={this.props.dispatch} dispatch_close_modal={_dispatch_close_modal(this.props.id)} />
          </div>

        </div>
      );
    }


    return (
      <>
        {jsx_multimodal_item}
      </>
    );
  }
}
