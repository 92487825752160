import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionRouteRefresh } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

import ModalConfirm from './ModalConfirm';



export default class ModalPostDelete extends Component {
  constructor(props) {
    super(props);
    let initial_value = '';

    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true
    };
    this._cb_submit_delete = this._cb_submit_delete.bind(this);
  }

  _cb_submit_delete(name) {
    console.log('_cb_submit_delete(e) SUBMIT ');
    let fetch_url = api_lib.posts_post_delete();
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let post_id = modal_data.post_id; // post_id
    let outbox = {
      postid: post_id
    };
    // data for post method
    console.log('outbox');
    console.log(outbox);
    let callback = () => {
      let dispatch = this.props.dispatch;
      dispatch(actionRouteRefresh());
      this.props.dispatch_close_modal();
    }
    // 
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let callback_cancel = (val) => {
      console.log('*** callback_cancel...');
      dispatch_close_modal();
    };

    let callback_submit = this._cb_submit_delete;

    let modal_args = {
      title: 'Are you sure?',
      message: 'Do you want to permanently delete this post? This action cannot be undone.',
      confirm_btn_title: 'Delete',
      callback_cancel: callback_cancel,
      callback_submit: callback_submit
    };

    return (
      <>
        <ModalConfirm modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
