import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';



export default class ModalFileUpload extends Component {
  constructor(props) {
    super(props);
    console.log('consttructor ModalFileUpload');
    console.log(this.props);
    this.state = {
      error: false,
      error_message: '',
      pending: true
    };
    // binding this
    this._handleSubmit = this._handleSubmit.bind(this);
    this._handleClick = this._handleClick.bind(this);
    this._submit_file = this._submit_file.bind(this);
    // Create the refs
    this.fileRef = React.createRef();
  }

  _handleClick(what, second, third) {
    // let dispatch = this.props.dispatch;
    if (what === 'SUBMIT') {
      return (e) => {
        e.preventDefault();
        this._submit_file();
      }
    }
  }

  _handleSubmit(e) {
    e.preventDefault(e);
    console.log('_handleSubmit(e) SUBMIT ');
    this._submit_file();
  }

  _submit_file() {
    console.log('_submit_file() ');
    let input_value = '';
    if (this.state.modalfileinput) {
      input_value = this.state.modalfileinput;
    }
    // input_value = input_value.trim();

    let modal_args = this.props.modal_args;
    // let callback_validate = modal_args.callback_validate;
    let is_valid = true;
    /*
    if (callback_validate) {
      let validation = callback_validate(input_value); // do validation
      if (validation === true) {
        is_valid = true;
      } else {
        // form validation fail
        is_valid = false;
        this.setState({
          error: true,
          error_message: validation
        });
      }
      console.log('validation: ', validation);
    }
    */

    let dom_el = this.fileRef.current; // refernnce to a DOM input file element

    if (is_valid === true) {
      console.log('TEST 1');
      let callback_submit = modal_args.callback_submit;
      if (callback_submit) {
        callback_submit(dom_el);
      }
    }
  }

  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
  };

  render() {
    let pending = true;
    let zero = true;

    let my = true;

    if (this.state.pending === false) {
      pending = false;
    }

    let modal_data = this.props.modal_data;
    console.log(modal_data);

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    //--------------------------------***********************

    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;

    // file upoad input //
    /*
    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file
    https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#Unique_file_type_specifiers
    https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept
    https://developer.mozilla.org/en-US/docs/Web/HTTP/Content_negotiation/List_of_default_Accept_values

    Accept: text/html
 
    // Default for navigation requests
    // Accept: text/html, application/xhtml+xml, application/xml; ...
    */


    // General default
    let accept_default = "*/*";
    let accept_text = 'text/html'; // ???
    let accept_image_default = 'image/*'; // 

    let accept = '';
    if (modal_args.file_types) {
      accept = modal_args.file_types;
    }

    let jsx_error = null;
    /*
    let error = this.state.error;
    if (error === true) {
      jsx_error = (
        <div className="error red">GRESKA U VALIDACIJI: {this.state.error_message}</div>
      );
    }
    */

    return (
      <>
        <div className="modal-item-content thisis-modalFileUpload">
          <h2>{title}</h2>
          <p>{message}</p>

          <form className="CL_PLACEHOLDER" onSubmit={this._handleSubmitPutYourCutPlus}>

            <input
              name="modalfileinput"
              type="file"
              accept={accept}
              className="modal-file-upload-field"
              ref={this.fileRef}
            />

            <div className="flex-bar center gaps buttons-group-style-2">
              <div className="xi btn btn-size-1"  onClick={dispatch_close_modal}>Cancel</div>
              <div className="xi btn-size-1 button-style-blue-1" onClick={this._handleClick('SUBMIT')}>Submit</div>
            </div>

          </form>


          {jsx_error}
        </div>
      </>
    );
  }
}
