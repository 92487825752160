import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from './frfr-multimodal-actions.js';
import { auth_steps } from '../utils/frfr-ajax-auth-steps.js';

import { framework_vs_app } from '../utils/framework-vs-app.js';
import { universal_fetch_get, universal_fetch_post } from '../utils/ajax.js';

// REDUX ACTIONS, ACTION CRETAORS, THUNKS...

// AUTH ACTIONS


export let actionAuthSimulateLogin = function (data) {
  // thunk
  return function thunk(dispatch) {
    // step 1
    console.log('dispatching login submit... ', data);
    dispatch({
      type: 'AUTH_TRY_LOGIN',
      payload: data
    });
    // step 2
    framework_vs_app.resolve_after_ms('simulated login', 2000)
      .then(response => {
        console.log('last then');
        dispatch({ type: 'TEST_AUTH_SIMULATE_LOGIN', payload: '' });
        // step 3 will be when step 2 finished and make callback
        //
      })
    // step 3 will be when step 2 finished and make callback
  };
};


export let actionAuthLogout = function () {
  return function thunk(dispatch) {
    // logout procedure
    dispatch({
      type: 'AUTH_TRY_LOGOUT',
    });
    auth_steps.logout_procedure()
      .then(() => {
        console.log('...logout THEN...');
        // logout done
        dispatch({
          type: 'AUTH_LOGOUT_DONE',
          payload: ''
        });
      });
    framework_vs_app.after_logout_procedure();
    // logout done
  };
};


export let actionAuthAutoLogin = function () {
  return function thunk(dispatch) {

    // step 1
    dispatch({
      type: 'AUTH_TRY_AUTOLOGIN',
      payload: ''
    });

    // step 2 async...
    auth_steps.before_autologin_preconditions()
      .then((frontend_preconditions) => {
        // checking frontend preconditions for autologin...
        console.log(frontend_preconditions);
        if (!frontend_preconditions) {
          // status is false
          // _that._showLoginRoute()
          console.log('then cls status not true REDIRECT LOGIN we will dispatch...');
          console.log('REDIRECT LOGIN we will dispatch...');
          dispatch({ type: '++STEP 1 FAIL', payload: 'login' });
          dispatch({ type: 'AUTH_LOGIN_FAIL' });
        } else {
          // status is true
          // AUTOLOGIN STEP_1 PROCEDURA USPELA
          console.log('++TEST 19 c');
          // universal_fetch_get(fetch_url, 'fething autologin step2...')
          framework_vs_app.autologin_fetch()
            .then(response => {
              // log original response (before extract json)
              console.log('+++ then USPELO ...');
              console.log(response);
              return response;
            })
            .then(response => response.json())
            .then(response => {
              console.log('step2 last then');
              let step_2_status = framework_vs_app.check_autologin_response(response)
              console.log(step_2_status);
              if (!step_2_status.is_logged_in) {
                // status is false
                console.log('then cls status not true REDIRECT LOGIN we will dispatch...');
                console.log('REDIRECT LOGIN we will dispatch...');
                // dispatch({ type: 'JUMP_ROUTE', payload: 'login' });
                dispatch({ type: '++STEP 2 FAIL', payload: 'login' });
                dispatch({ type: 'AUTH_LOGIN_FAIL', payload: step_2_status });
              } else {
                // AUTOLOGIN PROCEDURA USPELA
                console.log('REDIRECT TO ... we will dispatch...');
                dispatch({ type: 'AUTH_AUTOLOGIN_COMPLETE', payload: step_2_status.ajax_response });
                // dispatch({ type: 'JUMP_ROUTE', payload: '++NEKA_RUTA_NAKON_LOGOVANJA_TODO' }); // TODO
              }
            })
            .catch(function (error) {
              console.log('error od ...');
              console.log(error);
              dispatch(actionMultimodalOpen_catch(error, 'autologin_step2'));
              /*
              Possible Unhandled Promise Rejection (id: 0):
              TypeError: Cannot read property 'json' of undefined
              */
            }); //end of step 2

        }
      })
      .catch(function (error) {
        console.log('error od ...');
        console.log(error);
        dispatch(actionMultimodalOpen_catch(error, '_autologin_step1'));
        /*
        Possible Unhandled Promise Rejection (id: 0):
        TypeError: Cannot read property 'json' of undefined
        */
      }); // end of step 1
    // step 3 will be when step 2 finished and make callback
  };
};


export let actionAuthLoginFormSubmit = function (data) {
  // thunk
  return function thunk(dispatch) {
    // step 1
    console.log('dispatching login submit... ', data);
    dispatch({
      type: 'AUTH_TRY_LOGIN',
      payload: data
    });

    /*
    dispatch({
      type: 'TEST_AUTH_SIMULATE_LOGIN',
      payload: data
    });
    */

    // step 2
    framework_vs_app.check_formlogin_data(data); // log username...
    // universal_fetch_post(fetch_url, data, 'fething login form submit')
    framework_vs_app.formlogin_fetch(data)
      .then(response => {
        // log original response (before extract json)
        console.log('+++ then USPELO ...');
        console.log(response);
        return response;
      })
      .then(response => response.json())
      .then(response => {
        console.log('step2 last then');
        // let step_2_status = after_formlogin_response(response)
        framework_vs_app.check_formlogin_response(response)
          .then((step_2_status) => {
            console.log(step_2_status);
            // step 3 will be when step 2 finished and make callback
            if (step_2_status.is_success) {
              // FORM LOGIN USPELO
              dispatch({ type: 'INFO_LOGIN_SUCCESS', payload: '' });
              dispatch({ type: 'TEST_001', payload: '' });
              console.log('ponovno actionAuthAutoLogin...');
              dispatch(actionAuthAutoLogin());
            } else {
              dispatch({ type: 'AUTH_LOGIN_FAIL', payload: '' });
              if (response.error === true && typeof response.frfr_erf !== 'undefined') {
                let frfr_error_response = response;
                dispatch(actionMultimodalOpen_frfr_erf(frfr_error_response));
              }
            }
          })
        // end of after_formlogin_response
      })
      .catch(function (error) {
        console.log('error od ...');
        console.log(error);
        dispatch(actionMultimodalOpen_catch(error, 'universal_fetch_post'));
        dispatch({ type: 'AUTH_LOGIN_FAIL', payload: '' });
        /*
        Possible Unhandled Promise Rejection (id: 0):
        TypeError: Cannot read property 'json' of undefined
        */
      });
    // step 3 will be when step 2 finished and make callback
  };
};


