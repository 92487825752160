import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import { actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import Header from './Header';
import NavMyAccountGroup from './NavMyAccountGroup';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';
import FoldersMy from './FoldersMy';
import RouterMainContent from './RouterMainContent';
import RouterSidebarTheir from '../containers/RouterSidebarTheir';
import ContextMenu from './ContextMenu';


// React style property ubaciti u konverter

export default class Wrapper extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
    this._handleSubmitSearch = this._handleSubmitSearch.bind(this);
  }

  _handleSubmitSearch(e) {
    e.preventDefault(e);
    console.log('_handleSubmitSearch(e) SUBMIT ');
    let dispatch = this.props.dispatch;
    //
    let search_query = this.refs.search_folders.value;
    dispatch(actionNewRoute('SEARCH', { search_query: search_query, search_section: 4 }));
  }


  _handleClick(what, second, third) {

    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'OPEN_URL') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // BROWSER OPEN URL
        let rel_url = second;
        browser_utils.browser_go_url_relative(rel_url);
      }
    }
    if (what === 'COMING_SOON') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalComingSoon());
      }
    }
  }

  render() {
    console.log('render() ');
    console.log(this.props);

    let href_about = url_lib.about();


    // NAJNOVIJE PUTCUT
    let sidebar_banners = null

    /*
    
    putcut.template.layout1 = function() {
                  console.log("putcut.template.layout1() ");
                var props = {
                  temp_desktop_user_id: 'undefinedmyuserid',
              temp_desktop_url: ''
            };
            // TEMPORARY
      var html = '
    
    */

    let jsx_main_content = (
      <RouterMainContent appstate={this.props.appstate} dispatch={this.props.dispatch} />
    );

    let jsx_context_menu = null;
    if (this.props.appstate.context_menu.opened === true) {
      jsx_context_menu = (
        <ContextMenu appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
    }

    let appstate = this.props.appstate;

    let cl_home_item = 'item home-item not-tree-item';
    let cl_feed_item = 'item feed-item not-tree-item';

    let sidebar_active_item = appstate.sidebar_active_item;
    if (sidebar_active_item === 'home-item') {
      cl_home_item = cl_home_item + ' active';
    } else if (sidebar_active_item === 'feed-item') {
      cl_feed_item = cl_feed_item + ' active';
    }

    let active_item = appstate.sidebar_active_item;
    let active_item_fid = "nothing zero none";
    if (active_item === "tree-item") {
      let route_params = appstate.route_current.route_params;
      active_item_fid = route_params.fid;
    }

    return (
      <>

        {/* <!--START OF FRONTEND HTML--> */}

        <div id="wrapper">


          <Header appstate={this.props.appstate} dispatch={this.props.dispatch} />


          <div className="fix-header-scrolled">
          </div>
          <div className="page-body">
            <div className="inner">


              <div className="sidebar">
                <div className="folders my-folders">
                  <div className="item search-item">
                    <div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/search.png"} /></div>

                    <form className="search-folders" onSubmit={this._handleSubmitSearch}>
                      <input
                        ref="search_folders"
                        className="search-folders-field search-query"
                        type="search"
                        name="q"
                        placeholder="search in your folders..."
                      />
                    </form>

                  </div>
                  <div className={cl_home_item} onClick={this._handleClick('ROUTE', 'ACTIVITIES')}>Home<div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/home.png"} /></div></div>
                  <div className={cl_feed_item} onClick={this._handleClick('ROUTE', 'FEEDS')}>Feed<div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/feed.png"} /></div></div>
                  <FoldersMy appstate={this.props.appstate} active_item={sidebar_active_item} active_item_fid={active_item_fid} dispatch={this.props.dispatch} />
                  <div className="item facebook-pages-item not-tree-item" onClick={this._handleClick('COMING_SOON')}>Facebook pages<div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/facebook.png"} /></div></div>
                  <div className="item gmail-links-item not-tree-item" onClick={this._handleClick('COMING_SOON')}>Gmail links<div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/new-google.png"} /></div></div>
                </div>
                <div className="sidebar-badges">
                  <div className="chrome-badge">
                    <a className="" target="_blank" title="Add PutCut extension to your Chrome browser" href="https://chrome.google.com/webstore/detail/putcut/fnkpglljdlnifgakheebjdcldmgcmlgj"><img src={process.env.PUBLIC_URL + "/static/img/ChromeWebStore_BadgeWBorder_v2_206x58.png"} /></a>
                  </div>
                  <div className="firefox-badge">
                    <a className="" target="_blank" title="Add PutCut extension to Firefox browser" href="https://addons.mozilla.org/en-US/firefox/addon/putcut/"><span>+</span> Add to Firefox</a>
                  </div>
                </div>
              </div>


              <div className="main" data-rendered-here="">

                {jsx_main_content}

              </div>


              <div className="sidebar-2" data-rendered-sidebar-2="default">

                <RouterSidebarTheir appstate={this.props.appstate} active_item={sidebar_active_item} active_item_fid={active_item_fid} dispatch={this.props.dispatch} />

                <div className="sidebar-banners-container">
                  {sidebar_banners}
                </div>

              </div>
              <div className="clearfix clear"></div>
            </div>
          </div>

          {jsx_context_menu}

          <div className="clearfix clear"></div>

          <div className="footer-push"></div>
        </div>

        <div className="footer">
          <div className="inner">
            {/* <!--<p>Copyright &copy; 2016 <a href={process.env.PUBLIC_URL + "/">PutCut</a>. All rights reserved. PutCut is a registered trademark of PutCut Media d.o.o.</p>--> */}
            <a className="question-mark-link" href={href_about} onClick={this._handleClick('OPEN_URL', href_about)}><div className="question-mark">?</div></a>
          </div>
        </div>


      </>

    );
  }
}

