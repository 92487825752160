import React, { Component } from 'react';
import { actionFeedsItemsFetch } from '../actions/actions.js';
import { actionMultimodalOpen, actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import RssFeedsItem from './RssFeedsItem';

export default class RouteRssFeeds extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter_selected: 'ALL'
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'REFRESH') {
      return () => {
        console.log('CLICKED REFRESH');
        dispatch(actionFeedsItemsFetch());
      }
    }
    if (what === 'RSS') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // OPEN RSS SUBSCRIPTIONS EDITOR
        // dispatch(actionMultimodalRssSubsEdit());
        dispatch(actionMultimodalOpen('MODAL_RSS_SUBS_EDIT'));
      }
    }
    if (what === 'COMING_SOON') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalComingSoon());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
    if (name === 'filter') {
      // ukoliko je izmenjeno polje filter
      this.setState({
        filter_selected: value
      });

    }
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    dispatch(actionFeedsItemsFetch());
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let pending_more = false;
    let zero = true;
    let table_arr = [];
    let filters_arr = [];
    let filter_selected = this.state.filter_selected;

    let last_id = 0;

    let raw_data = this.props.appstate.data_feeds_items;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.data.items;
    }

    console.log(table_arr);


    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let browser_url = url_lib.feeds();
    browser_utils.browser_url_set(browser_url);
    let page_title = 'RSS Feeds - PutCut';
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {

      // fill filters_arr
      table_arr.forEach(function (item, index) {
        if (filters_arr.indexOf(item.domain) == -1) {
          // .indexOf() method returns -1 if the value to search for never occurs.
          // ako taj domen nismo vec stavili u listu filtera
          filters_arr.push(item.domain); // dodaj ga na listu filtera
        }
      });
      if (filters_arr.indexOf(filter_selected) == -1) {
        // ako filter_selected ne postoji u filters_arr onda resetuj na default filter_selected
        filter_selected = 'ALL'; // default filter
      }

      let filtered_arr = table_arr.filter((item, index) => {
        if (item.domain === filter_selected || filter_selected === 'ALL') {
          return true;
        } else {
          return false;
        }
      });

      jsx_arr = filtered_arr.map((item, index) => {
        /*
        // FILL filters_arr
        if (filters_arr.indexOf(item.domain) == -1) {
          // .indexOf() method returns -1 if the value to search for never occurs.
          // ako taj domen nismo vec stavili u listu filtera
          filters_arr.push(item.domain); // dodaj ga na listu filtera
        }
        */

        zero = false;
        last_id = item.id;
        // <div>{item.action}</div>
        return (
          <RssFeedsItem key={item.id} item={item} dispatch={this.props.dispatch} />
        );
      });

      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }

    } // ens of pending === false


    let jsx_options_arr = filters_arr.map((domain, index) => {
      if (domain === filter_selected) {
        // select
        return (
          <option selected key={index} value={domain
          } > {domain}</option >
        );
      }
      return (
        <option key={index} value={domain
        } > {domain}</option >
      );
    });


    return (
      <>
        <div className="main" data-rendered-here="feeds">


          <div className="main-toolbar JS-MADE">
            <div className="feeds-controls">

              <div className="button-item refresh-feeds-btn btn btn-size-0 float-left" title="Refresh" onClick={this._handleClick('REFRESH')}><img src={process.env.PUBLIC_URL + "/static/img/refresh.png"} />
                <div className="clearfix"></div>
              </div>

              <div className="button-item edit-feeds-btn btn btn-size-1 float-left" title="Manage RSS feed subscriptions" onClick={this._handleClick('RSS')}><img src={process.env.PUBLIC_URL + "/static/img/edit.png"} /><span>Edit subscriptions</span>
                <div className="clearfix"></div>
              </div>

              <div className="click-to-am button-item add-feed-manual-btn btn btn-size-1 float-left" style={{ width: 125 }} title="If you have a direct link to RSS feed, you can add it manually" onClick={this._handleClick('COMING_SOON')}>Add feed manually
                <div className="clearfix"></div>
              </div>

              <div className="select-button float-right" style={{ width: 110 }}>
                <select
                  className="feeds-filter-selector"
                  name="filter"
                  onChange={this.handleChange}
                >
                  <option value="ALL" selected="">All feeds</option>
                  {jsx_options_arr}
                </select>
                <div className="clearfix"></div>
              </div>

            </div>
            <div className="clearfix"></div>
          </div>


          <div className="ajax-container feeds-container" data-rendered-last-id="1821">

            {jsx_arr}
            {jsx_zero_items}
            {jsx_spinner}

          </div>

        </div>
      </>
    );
  }
}

    //




