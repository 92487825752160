import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch, actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

import ModalFileUpload from './ModalFileUpload';
import { conditionalExpression } from '@babel/types';



export default class ModalAvatarUpload extends Component {
  constructor(props) {
    super(props);
    let initial_value = '';
    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true
    };
    this._cb_submit_upload = this._cb_submit_upload.bind(this);
  }


  _cb_submit_upload(dom_el) {
    // _cb_submit_rename(name) {
    console.log('_cb_submit_upload(dom_el) SUBMIT ');
    console.log(dom_el);

    let fetch_url = api_lib.user_my_profile_avatar_update(); // TODO ??? *****
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/append
    let form = new FormData(); // Currently empty
    if (dom_el) {
      // let parent_node = parent_ref.current;
      form.append('file', dom_el.files[0]);
    } else {
      // not dom element... ???
    }
    console.log(form);
    let file = dom_el.files[0];
    let outbox = {
      readme: 'Saljemo fajl POST metodom.',
      file: file
    };
    // data for post method
    console.log('outbox');
    // console.log(outbox);
    console.warn(outbox);
    let dispatch = this.props.dispatch;
    let callback = () => {
      // let dispatch = this.props.dispatch;
      // dispatch(actionFoldersMyFetch());
      // ???
      dispatch(actionMultimodalComingSoon());
      this.props.dispatch_close_modal();
    }
    // step 1
    // this.setState({ scraped_data: {}, pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');

    dispatch(actionMultimodalComingSoon());
  }


  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let dispatch_close_modal = this.props.dispatch_close_modal;

    let callback_submit = this._cb_submit_upload;

    let accept_file_types = "image/jpeg,image/jpg,image/gif,image/png";

    /*
      title: 'Upload Picture!',
      message: 'TODO * Upload your new avatar picture:',
    */

    let modal_args = {
      title: 'Upload Avatar image',
      message: 'Upload your new image for your profile:',
      file_types: accept_file_types,
      callback_submit: callback_submit
    };

    return (
      <>
        <ModalFileUpload modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
