import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch, actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

import ModalCustomJSX from './ModalCustomJSX';

// TEMP VERSION WITH CLASSIC SUBMIT FORM

export default class ModalAvatarUploadTemp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let dispatch_close_modal = this.props.dispatch_close_modal;

    // let callback_submit = this._cb_submit_upload;

    let accept_file_types = "image/jpeg,image/jpg,image/gif,image/png";

    /*
      title: 'Upload Picture!',
      message: 'TODO * Upload your new avatar picture:',
    */

    let modal_args_temp = {
      title: 'Upload Avatar image',
      message: 'Upload your new image for your profile:',
      file_types: accept_file_types
    };

    let jsx_error = null;

    let jsx_custom = (
      <>
        <div className="modal-item-content">
          <h2>{modal_args_temp.title}</h2>
          <p>{modal_args_temp.message}</p>

          <form action="https://webservices-v4.putcut.com/api/v2/users/myprofile/avatar/update">

            <input
              name="modalfileinput"
              type="file"
              accept={modal_args_temp.accept_file_types}
              className="modal-file-upload-field"
            />
            <input
              type="hidden"
              name="is_real_submit"
              value="1"
            />

            <div className="flex-bar center gaps buttons-group-style-2">
              <div className="bari btn btn-size-1" style={{ width: 110 }} onClick={dispatch_close_modal}>Cancel</div>
              <button
                type="submit"
                className="xi btn btn-size-1 button-style-blue-1"
              >Submit</button>
            </div>

          </form>


          {jsx_error}
        </div>
      </>
    );

    let modal_args = {
      custom_content_jsx: jsx_custom
    };

    return (
      <>
        <ModalCustomJSX modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
