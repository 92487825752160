import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionNewRoute } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';



export default class ModalAddEditLink extends Component {
  constructor(props) {
    super(props);
    let pending = true;
    let mode = 'NONE';
    let modal_data = this.props.modal_data;
    if (modal_data.mode === 'ADD') {
      mode = 'ADD';
    } else if (modal_data.mode === 'EDIT') {
      mode = 'EDIT';
    }
    let title = 'i';
    let description = 'ii';

    let selected_picture_resource = '';
    if (mode === 'EDIT') {
      let editor_data = modal_data.editor_data;
      title = editor_data.title;
      description = editor_data.description;
      selected_picture_resource = editor_data.selected_picture_bestresource;
      pending = false;
    }
    // initial state
    this.state = {
      pending: pending,
      scraped_data: {},
      title: title,
      description: description,
      selected_picture_resource: selected_picture_resource
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
      nesto: 'nestodrugo'
    });
    console.log(name);
    console.log(value);
    if (name === 'putyourcutmodal') {
      // this._scrape();
      // now is detected by componentDidUpdate ... this.state.putyourcutmodal
    }
  }

  _handleClick(what, second, third) {
    // let dispatch = this.props.dispatch;
    if (what === 'SCRAPE_AGAIN') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        this._scrape();
      }
    }
    if (what === 'ADD_LINK') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        this._submit();
      }
    }
  }


  // EX REFRESH
  _scrape() {
    console.log(' ***** _scrape() *****');
    console.log(this.state);
    let user_id = this.props.user_id;
    let fetch_url = api_lib.scrape_url(user_id);
    let modal_data = this.props.modal_data;
    let initial_scraping_url = modal_data.url;
    // let scrape_this_url = 'https://www.imdb.com/?ref_=nv_home'; // TEMP TEST...
    let scrape_this_url = initial_scraping_url;
    if (typeof this.state.putyourcutmodal !== 'undefined') {
      scrape_this_url = this.state.putyourcutmodal;
    }
    console.log(scrape_this_url);
    let outbox = { url: scrape_this_url }; // data for post method
    // this._post_scrape(fetch_url, outbox);
    let callback = (result) => {
      this.setState({
        scraped_data: result,
        pending: false,
        title: result.item.title,
        description: result.item.description,
        selected_picture_resource: result.item.selected_picture_resource
      });
    }
    // step 1
    this.setState({ scraped_data: {}, pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, 'scraping link');
  }

  _submit() {
    console.log('_handleSubmit(e) SUBMIT ');
    // let dispatch = this.props.dispatch;
    //
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let mode = 'NONE';
    if (modal_data.mode === 'ADD') {
      mode = 'ADD';
    } else if (modal_data.mode === 'EDIT') {
      mode = 'EDIT';
    }
    let initial_scraping_url = modal_data.url;
    let add_link_url = initial_scraping_url;
    if (typeof this.state.putyourcutmodal !== 'undefined') {
      add_link_url = this.state.putyourcutmodal;
    }
    let fid = modal_data.fid; // folder_id
    let user_id = modal_data.user_id;

    let selected_picture_resource = this.state.selected_picture_resource;
    let pictures_arr = [selected_picture_resource];
    let pictures = JSON.stringify(pictures_arr);
    /*
      url: 'https://yts.lt/',
      folder: 0,
      title: 'title delete me',
      description: 'description delete me',
      pictures: ["https://yts.lt/assets/images/website/og_yts_logo.png"],
      selectedPictureID: 0,
      via: 'website',
      version: 2019
    */
    let fetch_url;

    let outbox;
    if (mode === 'ADD') {
      fetch_url = api_lib.posts_link_create(); // CREATE
      outbox = {
        url: add_link_url,
        folder: fid,
        title: this.state.title,
        description: this.state.description,
        pictures: pictures,
        selectedPictureID: 0,
        via: 'website',
        version: 2019
      }; // data for post method
      console.log(this.state);
      let callback = () => {
        let dispatch = this.props.dispatch;
        dispatch(actionNewRoute('FOLDERS', { fid: fid, user_id: user_id }));
        this.props.dispatch_close_modal();
      }
      // POST METHOD CREATE LINK
      mini_ajax_post(fetch_url, outbox, callback, null, true, 'create link');
    } else if (mode === 'EDIT') {
      fetch_url = api_lib.posts_link_update(); // UPDATE
      /*
        // TODO
        {
          editedlinkid: data.editedlinkid,
          title: data.title,
          description: data.description,
          pictures: data.pictures,
          selectedPictureID: data.selectedPictureID

editedlinkid: _08598be
title: After 24 years, Doom II’s final secret has been found!
description: Finally Doom II can be played to 100% ččč
pictures: ["https://assets.rockpapershotgun.com/images/2018/08/doom24.jpg"]
selectedPictureID: 0

        }
      */
      let post_id = modal_data.post_id;
      outbox = {
        editedlinkid: post_id,
        title: this.state.title,
        description: this.state.description,
        pictures: pictures,
        selectedPictureID: 0
      }; // data for post method
      let callback = () => {
        let dispatch = this.props.dispatch;
        dispatch(actionNewRoute('FOLDERS', { fid: fid, user_id: user_id }));
        this.props.dispatch_close_modal();
      }
      // POST METHOD UPDATE LINK
      mini_ajax_post(fetch_url, outbox, callback, null, true, 'update link');
    }
    // 
  }

  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
    let modal_data = this.props.modal_data;
    let mode = 'NONE';
    if (modal_data.mode === 'ADD') {
      mode = 'ADD';
      this._scrape(); // RESCRAPE
    } else if (modal_data.mode === 'EDIT') {
      mode = 'EDIT';
    }
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    if (this.state.putyourcutmodal !== prevState.putyourcutmodal) {
      this._scrape(); // RESCRAPE
    } else {
      console.log('...SKIP SCRAPING');
    }
  }





  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    let my = true;

    if (this.state.pending === false) {
      pending = false;
    }

    let mode = 'NONE';
    let modal_data = this.props.modal_data;
    if (modal_data.mode === 'ADD') {
      mode = 'ADD'
    } else if (modal_data.mode === 'EDIT') {
      mode = 'EDIT';
    }
    let initial_scraping_url = modal_data.url;
    console.log(modal_data);


    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_putcut_widget = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }



    // PUT YOUR CUT WIDGET
    let jsx_putcut_widget_closed = (
      <>
        <div className="add-link-group JS-MADE">
          <input type="text" className="put-your-cut" placeholder="... Put your Cut here ... (Ctrl+V)" />
          <div className="clearfix"></div>
        </div>
        <div className="clearfix"></div>
      </>
    );


    let jsx_modal_title = null;

    if (pending === true) {
      // CASE PENDING...
      let jsx_putcut_widget_pending = (
        <>
          {jsx_modal_title}
          <div className="add-link-group JS-MADE">
            <input
              type="text"
              className="put-your-cut"
              placeholder="... Put your Cut here ... (Ctrl+V)"
              autoComplete="off"
              autoFocus
            />
            <div className="clearfix"></div>
            <div className="new-entry-preview" style={{ display: 'block' }}>
              <div className="spinner-area new-entry-spinner" id="spinner_new_entry" style={{ display: 'block' }}>
                <SpinnerNormal />
                <div className="cancel-scrape-btn button-style-3" title="Cancel scraping and add link manually if scraping is taking too long"><span className="x-icon">&times;</span>cancel scraping</div>
              </div>
              <div className="links-container">
                {/*<!--add link example-->*/}
                {/*<!--end of add link example-->*/}
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </>
      );
      jsx_putcut_widget = jsx_putcut_widget_pending;


    } else {
      // PENDING FALSE
      console.log('**** FILL DATA');
      console.log(this.state);
      let item;
      let title = this.state.title;
      let description = this.state.description;
      let selected_picture_resource = this.state.selected_picture_resource;

      let dispatch_close_modal = this.props.dispatch_close_modal;

      console.log(item);

      let jsx_putyourcut_field = null;
      let jsx_buttons = null;

      if (mode === 'ADD') {
        // MODE ADD
        item = this.state.scraped_data.item;

        jsx_putyourcut_field = (
          <input
            name="putyourcutmodal"
            type="text"
            className="put-your-cut"
            placeholder="... Put your Cut here ... (Ctrl+V)"
            autoComplete="off"
            defaultValue={initial_scraping_url}
            onChange={this.handleChange}
          />
        );
        jsx_buttons = (
          <>
            <div className="xi cancel-button btn btn-size-1" style={{ width: 110 }} onClick={dispatch_close_modal}>Cancel</div>
            <div className="xi scrape-button btn btn-size-1" style={{ width: 110 }} onClick={this._handleClick('SCRAPE_AGAIN')}>Scrape again</div>
            <div className="xi add-button btn btn-size-1 btn-style-blue-1" style={{ width: 110 }} onClick={this._handleClick('ADD_LINK')}>Add link</div>
          </>
        );
      } else {
        // MODE EDIT
        let editor_data = modal_data.editor_data;
        item = editor_data;

        jsx_modal_title = (
          <h2>Edit link</h2>
        );

        jsx_putyourcut_field = (
          <input
            name="putyourcutmodal"
            type="text"
            className="put-your-cut"
            placeholder="... Put your Cut here ... (Ctrl+V)"
            autoComplete="off"
            defaultValue={initial_scraping_url}
            readOnly
          />
        );
        jsx_buttons = (
          <>
            <div className="xi cancel-button btn btn-size-1" style={{ width: 110 }} onClick={dispatch_close_modal}>Cancel</div>
            <div className="xi scrape-button btn btn-size-1" style={{ width: 110 }} onClick={this._handleClick('SCRAPE_AGAIN')}>Scrape again</div>
            <div className="xi add-button btn btn-size-1 btn-style-blue-1" style={{ width: 110 }} onClick={this._handleClick('ADD_LINK')}>Save</div>
          </>
        );
      }

      // <img className="thumbnail" src={item.selected_picture_resource} />

      let jsx_putcut_widget_opened = (
        <>
          {jsx_modal_title}
          <div className="add-link-group JS-MADE">
            {jsx_putyourcut_field}
            <div className="clearfix"></div>
            <div className="new-entry-preview" style={{ display: 'block' }}>
              <div className="spinner-area new-entry-spinner" id="spinner_new_entry" style={{ display: 'none' }}>
                <div className="spinner-cell">
                  <div className="the-spinner"></div>
                </div>
                <div className="cancel-scrape-btn button-style-3" title="Cancel scraping and add link manually if scraping is taking too long"><span className="x-icon">&times;</span>cancel scraping</div>
              </div>
              <div className="links-container">
                {/*<!--add link example-->*/}
                <div className="link not-draggable new-entry-editor modal-editor" data-url="" style={{ display: 'block' }}>
                  <div className="nested-add-link-panel">
                    <div className="link-content">
                      <div className="thumb-group" data-selected-picture-id="">
                        <img className="thumbnail" src={selected_picture_resource} />
                        <div className="thumbnail-btn-prev thumbnail-btn button-style-3" data-editor-type="addnew">&lt;</div>
                        <div className="thumbnail-btn-next thumbnail-btn button-style-3" data-editor-type="addnew">&gt;</div>
                      </div>
                      <div className="link-info">
                        <form>
                          <span className="editor-field-title">Title:</span>
                          <input
                            id="myNewTitle"
                            name="title"
                            type="text"
                            value={title}
                            onChange={this.handleChange}
                          />
                          <div className="domain"><img className="favicon" src={item.favicon} /><a rel="nofollow" target="_blank" title="" href=""></a><span>{item.domain}</span></div>
                          <span className="editor-field-title">Description:</span>
                          <div className="">
                            <textarea
                              id="myNewDescription"
                              name="description"
                              rows="8" cols="60"
                              value={description}
                              onChange={this.handleChange}
                            />
                          </div>
                          <input id="myNewPictures" name="pictures" type="hidden" value="" />
                          <input id="myNewPicturesSelected" name="selectedPictureID" type="hidden" value="" />
                        </form>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="edit-buttons-group flex-bar right gaps buttons-group-style-2">
                      {jsx_buttons}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
                {/*<!--end of add link example-->*/}
              </div>
            </div>
          </div>
          <div className="clear"></div>
        </>
      );
      jsx_putcut_widget = jsx_putcut_widget_opened;
    }



    return (
      <>
        {jsx_putcut_widget}
      </>
    );
  }
}
