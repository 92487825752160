import { actionMultimodalOpen, actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { store } from '../index.js';


// KORISTIMO JQUERY UI http://jqueryui.com/
// TO DO jQuery : drag and drop : find the id of the target
// http://stackoverflow.com/questions/3943868/jquery-drag-and-drop-find-the-id-of-the-target
// http://stackoverflow.com/questions/1853230/jquery-ui-draggable-event-status-on-revert/3418306#3418306



export let jqui_drag_init = function () {
  let $ = window.jquery_for_react;
  $(".real-links-ajax .link:not(.not-draggable)").draggable({
    delay: 100, // 100ms delay to prevent unwanted drags.
    cursor: "move",
    handle: ".drag-me", // postavlja ui-draggable-handle samo na taj child elemenat
    // containment: [0, 0, 200, 200], // An array defining a bounding box in the form [ x1, y1, x2, y2 ].
    revert: "invalid",
    helper: "clone" //,
    // cursorAt: { top: 95} // to sam oizaziva skok elementa u odnosu na kursor
  });
};

export let jqui_drag_destroy = function (dom_el) {
  let $ = window.jquery_for_react;
  // this.$node.sortable('destroy');
  $(dom_el).draggable('destroy');
};

export let jqui_drop_init = function (dom_el) {
  /*
  jQuery( element )
  element
  Type: Element
  A DOM element to wrap in a jQuery object.
  */
  let $ = window.jquery_for_react;
  // $(".my-folders .tree .item:not(.not-droppable)").droppable({
  $(dom_el).droppable({
    accept: ".link",
    tolerance: "pointer", // http://api.jqueryui.com/droppable/#option-tolerance
    activeClass: "drop-target-ready",
    hoverClass: "drop-target-redhot",
    over: function (event, ui) {
      $('.ui-draggable-dragging').addClass('drag-nuclear2');
      $(this).addClass('drag-nuclear2');
    },
    out: function (event, ui) {
      $('.ui-draggable-dragging').removeClass('drag-nuclear2');
    },
    drop: function (event, ui) {
      /*
      if (spa_check_guestmode()) {
        return;
      }
      */
      let dd = {}; // DRAG DROP DATA
      dd.drag_id = $(ui.draggable).attr("data-id");
      dd.drag_is_recycled = $(ui.draggable).attr('data-in-recycle-bin');
      dd.drag_id_parent = $(ui.draggable).attr("data-parent-folder");
      dd.drop_id = $(this).attr("data-folder-id");
      console.log('DRAG DROP');
      console.log(dd);
      let dispatch = store.dispatch;
      if ($(".main").attr("data-spa-main") == "links_their") {
        // if THEIR LINK not move to folder, not draggable...
        /*
        moveTheirLinkToMyFolder(drag_id, drop_id, drag_is_recycled); // THEIR link to MY folder
        */
      } else {
        // draggable MY LINKS
        if (dd.drop_id != dd.drag_id_parent) {
          // if target folder is not parent folder
          /*
          moveLinkToFolder(drag_id, drop_id, drag_is_recycled, function () {
            $(ui.draggable).slideUp(300);
            swal("Excellent!", "The link has been moved to a new folder!", "success");
          });
          */
          // dispatch(actionMultimodalMoveLink(dd.drag_id, dd.drop_id, dd.drag_is_recycled));
          dispatch(actionMultimodalOpen('MODAL_MOVE_LINK', { dd }));
        } else {
          // parent folder and target folder is the same
          if (dd.drag_is_recycled == "1") {
            dispatch(actionMultimodalOpen('MODAL_MOVE_LINK', { dd })); // UNRECYCLE
          } else {
            console.log("Nothing to move. Source and target are same.");
          }
        }
      }
    }
  });
};

export let jqui_drop_destroy = function (dom_el) {
  let $ = window.jquery_for_react;
  // this.$node.sortable('destroy');
  $(dom_el).droppable('destroy');
};
