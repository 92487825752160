import React, { Component } from 'react';
import { actionSocialFollowersFetch } from '../actions/actions.js';
import SpinnerMain from './SpinnerMain';
import PeopleItem from './PeopleItem';

export default class ListFollowers extends Component {

  componentDidMount() {
    let dispatch = this.props.dispatch;
    let user_id = this.props.user_id;
    let move = this.props.appstate.route_freshness;
    dispatch(actionSocialFollowersFetch(move, user_id));
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    let raw_data = this.props.appstate.data_social_followers;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.data.items;
    }

    console.log(table_arr);

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {

      let filtered_arr = table_arr; // no filter

      jsx_arr = filtered_arr.map((item, index) => {
        zero = false;
        return (
          <PeopleItem key={index} item={item} appstate={this.props.appstate} dispatch={this.props.dispatch} />
        );
      });

      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }

    } // end of pending === false

    return (
      <>
        <h2>People following (user) <span className="user-nickname-string-here"></span></h2>

        {jsx_arr}
        {jsx_zero_items}
        {jsx_spinner}

      </>
    );
  }
}

