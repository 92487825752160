import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionRouteRefresh } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

import ModalPending from './ModalPending';

export default class ModalMoveLink extends Component {
  constructor(props) {
    super(props);
    // this.para_state = {};
    this._global = {};
    let modal_data = this.props.modal_data;
    this._global.dd = modal_data.dd;
    this._global.mode = 'MOVE';
    if (this._global.dd.drop_id == "bin") {
      this._global.mode = 'RECYCLE';
    } else {
      if (this._global.dd.drag_is_recycled == "1") {
        // UNRECYCLE
        console.log("MODE UNRECYCLE");
        this._global.mode = 'UNRECYCLE';
      } else {
        // MOVE LINK
        console.log("MODE MOVE");
        // this._global.mode = 'MOVE';
      }
    }
    this.state = {
      pending: true
    };
    this._cb_submit_move = this._cb_submit_move.bind(this);
  }

  /*
  _post_move_link = (fetch_url, data, callback) => {
    // this.setState({ pending: true });
    mini_fetch_post(fetch_url, data)
      .then(response => response.json())
      .then(result => {
        // this.setState({ data: result, pending: false });
        console.log('MINI POST SUCCESSFUL');
        console.log(result);
        // this._refresh();
        return result;
      })
      .then(result => {
        this.setState({ pending: false, success: true });
        // AFTER SUCCESS MAKE CALLBACK
        if (callback) {
          callback();
        }
      })
      .catch(e => {
        console.log('mini fetch post CATCH ERROR - e: ');
        console.log(e);
      });
  };
  */

  _cb_submit_move() {
    console.log('_cb_submit_move(e) SUBMIT ');
    let dd = this._global.dd;
    let outbox = {};
    let fetch_url;
    let callback = () => {
      let dispatch = this.props.dispatch;
      this.setState({ pending: false });
      dispatch(actionRouteRefresh());
      this.props.dispatch_close_modal();
    }
    if (this._global.mode === 'RECYCLE') {
      // MODE RECYCLE
      // {postid: id}
      outbox = {
        postid: dd.drag_id
      };
      fetch_url = api_lib.posts_recycle_update();
    } else if (this._global.mode === 'UNRECYCLE') {
      // MODE UNRECYCLE
      // {postid: id, folderid: targetfolderID}
      outbox = {
        postid: dd.drag_id,
        folderid: dd.drop_id
      };
      fetch_url = api_lib.posts_unrecycle_update();
      callback = () => {
        let dispatch = this.props.dispatch;
        this.setState({ pending: false });
        dispatch(actionRouteRefresh());
        // this.props.dispatch_close_modal();
        // TODO ???
      }
    } else {
      // MODE MOVE
      // {postid: linkID, folderid: targetfolderID}
      outbox = {
        postid: dd.drag_id,
        folderid: dd.drop_id
      };
      fetch_url = api_lib.posts_move_update();
    }
    // data for post method
    console.log('outbox');
    console.log(outbox);

    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  componentDidMount() {
    this._cb_submit_move();
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title;
    let message;
    if (this._global.mode === 'RECYCLE') {
      // MODE RECYCLE
      title = 'Recycling...'
      message = '';
      if (this.state.pending === false) {
        title = 'Recycled!'
        message = 'The link has been moved to a Recycle Bin!';
      }
    } else if (this._global.mode === 'UNRECYCLE') {
      // MODE UNRECYCLE
      title = 'Unrecycling...'
      message = '';
      if (this.state.pending === false) {
        title = 'Unrecycled!'
        message = 'The link has been moved from a Recycle Bin!'; // TODO ???
      }
    } else {
      // MODE MOVE
      title = 'Moving...'
      message = '';
      if (this.state.pending === false) {
        title = 'Excellent!'
        message = 'The link has been moved to a new folder!';
      }
    }

    let modal_args = {
      title: title,
      message: message,
      pending: this.state.pending
    };

    return (
      <>
        <ModalPending modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
