// import { routes, main_menu_options } from './routes.js';
import { arr_utils } from '../utils/frfr-array-utils.js';

let routes = [];

let testing_modals_a = [
  {
    id: '001',
    animation_before_delete: false,
    data: {}
  },
  {
    id: '004',
    animation_before_delete: false,
    data: {}
  },
  {
    id: '030',
    animation_before_delete: false,
    data: {}
  }
];

let testing_modals = [
  {
    id: '011',
    animation_before_delete: false,
    data: {}
  }
];





export const multimodal_reducer_helper = (state, raw_action) => {
  let action = { type: 'DEFAULT' };
  if (typeof raw_action.payload.pass_action !== 'undefined') {
    action = raw_action.payload.pass_action;
  }
  console.log('multimodal_reducer_helper() ');
  console.log(action);
  let app_phases;
  let move;
  let key;
  let new_modals_arr = [];
  let new_modal_item;
  let animation_before_delete;
  switch (action.type) {

    case 'MULTIMODAL_BEFORE_CLOSE_MODAL':
      new_modals_arr = state.multimodal.modals.map((item, index) => {
        animation_before_delete = item.animation_before_delete;
        if (item.id === action.payload) {
          animation_before_delete = true;
        }
        return {
          id: item.id,
          animation_before_delete: animation_before_delete,
          type: item.type,
          modal_content: item.modal_content
        };
      });
      return Object.assign({}, state, {
        multimodal: {
          modals: new_modals_arr
        }
      });
    case 'MULTIMODAL_CLOSE_MODAL':
      new_modals_arr = state.multimodal.modals.filter((item, index) => {
        if (item.id === action.payload) {
          return false; // exclude modal with target ID
        } else {
          return true;
        }
      });
      return Object.assign({}, state, {
        multimodal: {
          modals: new_modals_arr
        }
      });

    case 'MULTIMODAL_OPEN_MODAL':
      // NEW UNIVERSAL
      let single_instance_mode = false;
      let single_instance = false;
      let single_instance_target = 'NONE';
      if (action.payload.single_instance === true) {
        single_instance = true;
        single_instance_target = action.payload.program_type;
        // --- finding what is the modal TYPE for single instance
        let single_instace_exist = false;
        state.multimodal.modals.forEach((item) => {
          if (item.program_type === single_instance_target) {
            single_instace_exist = true;
            single_instance_mode = true;
          }
        });
      }
      /*
      Task manager, Task, Process, Multitasking...
      In computing, a process is the instance of a computer program that is being executed by one or many threads. It contains the program code and its activity.
      While a computer program is a passive collection of instructions, a process is the actual execution of those instructions. Several processes may be associated with the same program; for example, opening up several instances of the same program often results in more than one process being executed.
      Processes are often called "tasks" in embedded operating systems. The sense of "process" (or task) is "something that takes up time", as opposed to "memory", which is "something that takes up space"
      In computing, multitasking is the concurrent execution of multiple tasks (also known as processes) over a certain period of time. New tasks can interrupt already started ones before they finish, instead of waiting for them to end. As a result, a computer executes segments of multiple tasks in an interleaved manner,...
      */
      /*
      multimodal item type - physical type of modal
      program_type - name of the modal which may be in single or multiple instance
      single_instance - is this program_type single instance only (or allowed to be in multiple instances)
      modal_content - data fro modal content. not for the modal exterior
      */
      //
      if (single_instance_mode === false) {
        // multiple instance
        // append new modal to end of modals array
        new_modals_arr = [...state.multimodal.modals]; // pure arr clone
        new_modal_item = {
          id: action.meta.fresh_id,
          animation_before_delete: false,
          type: action.payload.modal_type,
          program_type: action.payload.program_type,
          single_instance: action.payload.single_instance,
          modal_content: {
            type: action.payload.modal_content.type,
            data: action.payload.modal_content.data
          }
        };
        new_modals_arr.push(new_modal_item);
      } else {
        // only one instance
        // move existing modal to end of modals array
        let instance_item = {};
        new_modals_arr = state.multimodal.modals.filter((item) => {
          if (item.program_type === single_instance_target) {
            // step 1) dont include exisiting insatnce in new array
            instance_item = item;
            console.log('mutated_item ++++++');
            console.log(instance_item);
            return false;
          }
          return true;
        });
        console.log(instance_item);
        // mutate existing instance with new content data
        let mutated_item = Object.assign({}, instance_item, {
          modal_content: action.payload.modal_content
        });
        console.log(mutated_item);
        new_modals_arr.push(mutated_item);
        console.log(new_modals_arr);
      }

      return Object.assign({}, state, {
        multimodal: {
          modals: new_modals_arr
        }
      });

    //

    default:
      return state;
  }
};


