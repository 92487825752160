import React, { Component } from 'react';
import SpinnerSmall from './SpinnerSmall.js';
import { api_lib } from '../utils/url-lib.js';
import { mini_ajax_post } from '../utils/ajax.js';

export default class TreeLiFolderLock extends Component {
  constructor(props) {
    super(props);
    let locked = false;
    let folder = this.props.folder;
    if (folder.private == '1') {
      locked = true;
    } else {
      locked = false;
    }
    this.state = {
      pending: false,
      locked: locked
    };
    this._lock = this._lock.bind(this);
    this._unlock = this._unlock.bind(this);
  }

  _lock(data) {
    console.log(' ***** POST LOCK *****');
    let fid = this.props.folder.id;
    let fetch_url = api_lib.folders_lock_update(fid);
    let outbox = { folderid: fid };
    let callback = (data) => {
      console.log("uspelo LOCKING callback");
      console.log(data);
      if (data.success == true) {
        this.setState({
          pending: false,
          locked: true
        });
      }
    };
    // step 1
    this.setState({ pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  _unlock(data) {
    console.log(' ***** POST UNLOCK *****');
    let fid = this.props.folder.id;
    let fetch_url = api_lib.folders_unlock_update(fid);
    let outbox = { folderid: fid };
    let callback = (data) => {
      console.log("uspelo UNLOCKING callback");
      console.log(data);
      if (data.success == true) {
        this.setState({
          pending: false,
          locked: false
        });
      }
    };
    // step 1
    this.setState({ pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  render() {
    let folder = this.props.folder;
    let fid = folder.id;
    let locked = this.state.locked;

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let cl_lock;
    if (locked === true) {
      cl_lock = 'lock lock-case-locked';
    } else {
      cl_lock = 'lock lock-case-unlocked';
    }

    // CASE UNLOCKED
    let jsx = (
      <div className={cl_lock} onClick={this._lock}></div>
    );
    if (this.state.pending === true) {
      // CASE SPINNER
      cl_lock = 'lock lock-case-spinner-NOT';
      jsx = (
        <div className={cl_lock}>
          <SpinnerSmall />
        </div>
      );
    } else {
      if (this.state.locked === true) {
        // CASE LOCKED
        jsx = (
          <div className={cl_lock} onClick={this._unlock}></div>
        );
      }
    }

    return (
      <>
        {jsx}
      </>
    );
  }
}

