import React, { Component } from 'react';
import { connect } from 'react-redux'; // NOVO ZA BINDING REACTA I REDUXA
// import '../App.css';
import Init from '../containers/Init';

class App extends Component {
  render() {
    return (
      <Init appstate={this.props.appstate} dispatch={this.props.dispatch} />
    );
  }
}

// export default App;
const mapStateToProps = state => ({
  procitaj_me: 'sve propertije iz ovog objekta ce druga komponenta da primi kao props',
  b: 'bbb',
  appstate: state,
  c: 'ccc'
});
export default connect(mapStateToProps)(App)
