import React, { Component } from 'react';
import { actionAuthLoginFormSubmit, actionAuthSimulateLogin } from '../actions/auth-actions.js';
import SpinnerNormal from './SpinnerNormal';

export default class AuthFormLoginDrugi extends Component {
  //
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log('handleSubmit(event) ');
    console.log(event);
    // alert('An essay was submitted: ' + this.state.value);
    let dispatch = this.props.dispatch;
    let username = this.refs.username.value; // ref text input
    let password = this.refs.password.value; // ref text input
    let rememberme = this.refs.rememberme.checked; // ref checkbox input
    let data = {
      email: username,
      password: password,
      rememberme: rememberme
    };
    console.log("submiting login...");
    console.log(data);
    // dispatch(actionAuthLoginFormSubmit(data));
    // dispatch(actionAuthSimulateLogin());
    dispatch(actionAuthLoginFormSubmit(data));
  }

  render() {
    let auth = this.props.appstate.auth;
    let jsx_pending = null;

    if (auth.pending) {
      jsx_pending = (
        <><SpinnerNormal /><p> Pending...</p></>
      );
    }

    return (
      <>
        <div id="wrapper" className="wrapper-register spa-delete-me-frontend-render">
          <div className="header header-not-fixed">
            <div className="header-big">
              <div className="inner">
                <div className="logo">
                  <a href={process.env.PUBLIC_URL}>
                    <img src={process.env.PUBLIC_URL + "/static/img/logo-blue.png"} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div id="scrollsection_join" className="scrollsection" data-scroll-index="2">
            <div className="inner">
              <div className="login-row-group">
                <div className="login-group">
                  <div className="avatar-space big-avatar-group">
                    <div className="avatar">
                      <img src={process.env.PUBLIC_URL + "/static/img/anonymous-inverted.png"} />
                    </div>
                  </div>

                  <form onSubmit={this.handleSubmit}>

                    <div className="field-space">
                      <div className="field">
                        <input autoFocus="" type="email" name="email" className="email-field" placeholder="e-mail" ref="username" />
                      </div>
                    </div>
                    <div className="field-space-error-msg hide">
                      <div className="error"><span id="login-form-error-text"></span></div>
                    </div>
                    <div className="field-space">
                      <div className="field">
                        <input type="password" name="password" className="password-field" placeholder="password" ref="password" />
                      </div>
                    </div>
                    <div className="field-space">
                      <div className="button">
                        <button type="submit" className="click-to-ajax-login-on-login2 submit-button button-style-rounded-blue-1">Log in</button>
                      </div>
                    </div>
                    <div className="clearfix"></div>
                    <div className="field-space-2 login-options">
                      <div className="left">
                        <label className="remember">
                          <input id="PersistentCookie" name="rememberme" type="checkbox" value="stay" defaultChecked ref="rememberme" />
                          <span>Stay logged in</span>
                        </label>
                      </div>
                      <div className="right">
                        <a id="link-forgot-passwd" className="login-need-help" href={process.env.PUBLIC_URL + "/forgot-password"}>Forgot password?</a>
                      </div>
                    </div>
                    <div className="clearfix"></div>

                    {jsx_pending}

                  </form>

                  <div className="clearfix clear"></div>

                  <div className="field-space-3">
                    <div className="register-now-btn">Don't have an account yet? <b><a href={process.env.PUBLIC_URL + "/register"}>Register</a> now!</b></div>
                  </div>

                  <div className="social-login">
                    <h4>or sign in with</h4>
                    <div className="social-login-icons">
                      <div className="social-icon facebook-icon">
                        <a href={process.env.PUBLIC_URL + "/login/facebook"} id="fbButtonClick">
                          <img src={process.env.PUBLIC_URL + "/static/img/facebook.png"} width="60" />
                        </a>
                      </div>
                      <div className="social-icon or-text">or</div>
                      <div className="social-icon google-plus-icon">
                        <a href={process.env.PUBLIC_URL + "/login/google"} id="customBtn">
                          <img src={process.env.PUBLIC_URL + "/static/img/new-google.png"} width="60" />
                        </a>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="clearfix clear spa-delete-me-frontend-render"></div>
          <div className="footer-push"></div>
        </div>
      </>
    );
  }
}
