import React, { Component } from 'react';
import MultiModalPortal from './MultiModalPortal';
import MultiModalItems from './MultiModalItems';

export default class MultiModalBrain extends React.Component {
  //
  /*
  constructor(props) {
    super(props);
  }
  */

  render() {
    // let modals = [];
    // let modals = [1,2];
    // let modals = [1,2,3,4,5];
    let modals = this.props.appstate.multimodal.modals;

    let jsx_modal_children = null; // vraca gresku Objects are not valid as a React child (found: object with keys {jsx_modal}). 
    /*let jsx_modal_children = (
      <>
      </>
    );
    */
    if (modals.length > 0) {
      // render multimodal only if there is at least one modal
      jsx_modal_children = (
        <MultiModalPortal>
          <MultiModalItems modals={modals} appstate={this.props.appstate} dispatch={this.props.dispatch} />
        </ MultiModalPortal>
      );
    }

    return (
      <>
        {jsx_modal_children}
      </>
    );
  }
}

