// modals-lib.js

export let modals_lib = {};

/*
multimodal item type - physical type of modal
program_type - name of the modal which may be in single or multiple instance
single_instance - is this program_type single instance only (or allowed to be in multiple instances)
modal_content - data fro modal content. not for the modal exterior
*/

modals_lib.modals = [
  {
    program_type: 'MODAL_COMING_SOON',
    single_instance: false,
    physical_type: 'BASIC'
  },
  {
    program_type: 'MODAL_LINK_EDITOR',
    single_instance: true,
    physical_type: 'BASIC'
  },
  {
    program_type: 'MODAL_THEATER',
    single_instance: true,
    physical_type: 'THEATER'
  }
];

modals_lib.default_modal = {
  single_instance: false,
  physical_type: 'BASIC'
};

modals_lib.get = function (program_type) {
  let result_item;
  let found = false;
  modals_lib.modals.forEach((item) => {
    if (item.program_type === program_type) {
      result_item = item;
      found = true;
    }
  });
  if (found === true) {
    return result_item;
  } else {
    return modals_lib.default_modal;
  }
};
