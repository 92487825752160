import React, { Component } from 'react';

export default class RouteNotAvailable extends Component {
  render() {
    return (
      <>
        <div className="not-available"><h2>Sorry, this content isn&apos;t available</h2>It may have been removed or the privacy settings of the content may have changed.</div>
      </>
    );
  }
}
