import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import PostsItemNestedLink from './PostsItemNestedLink';
import { url_lib } from '../utils/url-lib.js';

/*
ACTIVITIES CASES:
publish post
follow user
comment
rate
*/

export default class ActivitiesItemWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
    this._handleClick = this._handleClick.bind(this);

    // this._callbackClose = this._callbackClose.bind(this);
    // this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {

    let item = this.props.item;
    let props = {
      item: item
    };
    console.log('**** ACTIVITIES ITEM');
    console.log(item);

    let user_id = item.user1;

    let href_user = url_lib.user(user_id);

    let jsx = (
      <div>PLACEHOLDER action: {item.action}</div>
    );

    let jsx_nested_link = null;

    if (this.props.item.action == 'publish-post') {
      // CASE publish
      /*
action: "publish-post"
data: {post_id: "_e61baef", post_item: {…}}
hidden: "0"
id: "1857"
time: "1544221960"
time_formatted: "December 7th, 2018"
user1: "_18734e2"
user1_item: {confirmed: "1", first_name: "Henry", last_name: "Bessemer", confirmed_standard: "1", birthday_year: "1980", …}
user2: ""
user2_item: false
via: "mweb"
via_ver: "1.0"
      */

      let post_id = props.item.data.post_id;
      let href_post = url_lib.post_single(user_id, post_id);

      let via = props.item.via;
      let via_ver = props.item.via_ver;
      let temp_via = "";
      console.log(via);
      // if (activity.via != undefined) {
      if (typeof via !== 'undefined') {
        console.log("via");
        if (via == "app") {
          temp_via = " using app.";
        } else if (via == "extension") {
          if (via_ver.substr(via_ver.length - 6) == "chrome") {
            temp_via = " using Chrome extension.";
          } else if (via_ver.substr(via_ver.length - 7) == "firefox") {
            temp_via = " using Firefox extension.";
          }
        } else if (via == "app") {
          if (via_ver.substr(via_ver.length - 7) == "android") {
            temp_via = " via Android app.";
            console.log(" via Android app.");
          } else if (via_ver.substr(via_ver.length - 6) == "iphone") {
            temp_via = " via iPhone/iPad app.";
            console.log(" via iPhone/iPad app.");
          }
        } else if (via == "website") {
          console.log(" via website putcut.com");
          console.log(via_ver);
        }
      }
      jsx = (
        <>
          <span className="nickname user-first"><a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.item.user1_item.display_name}</a></span>
          <p className="social-activity-title">shared a <a className="spa-anchor-posts" href={href_post}>link</a>{temp_via}</p>
          <p className="social-activity-timing timing-info"> {props.item.time_formatted}</p>
        </>
      );

      jsx_nested_link = (
        <PostsItemNestedLink item={props.item.data.post_item} dispatch={this.props.dispatch} />
      );
    } else if (this.props.item.action == 'rate-link') {
      // CASE rate
      /*
action: "rate-link"
data: {post_id: "_3db44f3", url_id: "1746", rating: 6, post_item: {…}, url_item: {…}}
hidden: "0"
id: "1854"
time: "1541921641"
time_formatted: "November 11th, 2018"
user1: "_25d4357"
user1_item: {confirmed: "1", first_name: "Milan", last_name: "Kragujević", confirmed_standard: "1", birthday_year: "2000", …}
user2: ""
user2_item: false
via: ""
via_ver: ""
      */
      let post_id = props.item.data.post_id;
      let href_post = url_lib.post_single(user_id, post_id);
      let five_stars_converted = parseInt(props.item.data.rating) / 2;
      jsx = (
        <>
          <span className="nickname user-first"><a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.item.user1_item.display_name}</a></span>
          <p className="social-activity-title">rated <a className="spa-anchor-posts" href={href_post}>link</a>. They gave it {five_stars_converted}/5 stars.</p>
          <p className="social-activity-timing timing-info"> {props.item.time_formatted}</p>
        </>
      );
      jsx_nested_link = (
        <PostsItemNestedLink item={props.item.data.post_item} dispatch={this.props.dispatch} />
      );

    } else if (this.props.item.action == 'follow-user') {
      // CASE follow
      let user2_id = item.user2_item.user_id;
      let href_user2 = url_lib.user(user2_id);
      jsx = (
        <>
          <span class="nickname user-first"><a class="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.item.user1_item.display_name}</a></span>
          <p class="social-activity-title">started following</p>
          <span class="nickname user-second"><a class="spa-anchor-user" href={href_user2} onClick={this._handleClick('ROUTE', 'USER', { user_id: user2_id })}>{props.item.user2_item.display_name}</a>.</span>
          <p class="social-activity-timing timing-info"> {props.item.time_formatted}</p>
        </>
      );

    } else if (this.props.item.action == 'post-comment') {
      // CASE comment
      /*

      */
      let post_id = props.item.data.post_id;
      let href_post = url_lib.post_single(user_id, post_id);
      jsx = (
        <>
          <span className="nickname user-first"><a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.item.user1_item.display_name}</a></span>
          <p class="social-activity-title">commented on a <a class="spa-anchor-posts" href={href_post}>link</a>.</p>
          <p class="social-activity-timing timing-info"> {props.item.time_formatted}</p>
        </>
      );
      jsx_nested_link = (
        <PostsItemNestedLink item={props.item.data.post_item} dispatch={this.props.dispatch} />
      );

    }


    return (
      <>

        <div className="social-activity-item social-activity-type-share" data-id="PLACEHOLDER">
          <div className="social-activity-item-panel">
            <div className="author-group">
              <div className="avatar">
                <a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img src={props.item.user1_item.avatar_url} /></a>
              </div>
              <div className="balloon">

                {jsx}

              </div>
            </div>
          </div>

          {jsx_nested_link}

        </div>

      </>
    );
  }
}


