import React, { Component } from 'react';

/*
The Window.alert() method displays an alert dialog with the optional specified content and an OK button.
The Window.confirm() method displays a modal dialog with an optional message and two buttons: OK and Cancel.
The Window.prompt() displays a dialog with an optional message prompting the user to input some text.


https://docs.microsoft.com/en-us/windows/win32/api/winuser/nf-winuser-messagebox
*/


export default class ModalMessage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;
    console.log('********************');
    console.log(this.props);

    return (
      <>
        <div className="modal-item-content thisis-ModalMessage">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="flex-bar center gaps buttons-group-style-2">
            <div className="xi btn btn-size-1" onClick={dispatch_close_modal}>Ok</div>
          </div>
        </div>
      </>
    );
  }
}
