import React, { Component } from 'react';
import { connect } from 'react-redux'; // NOVO ZA BINDING REACTA I REDUXA

// import { browser_utils } from '../utils/frfr-browser-utils.js';
// import { on_url_change_offline } from '../utils/putcut-routing.js';
import Wrapper from '../components/Wrapper';


// INIT AFTER LOGGED IN COMPONENT

export default class InitLoggedIn extends Component {
  constructor(props) {
    super(props);
    console.log('constructor InitLoggedIn');
    console.log(this.props);
  }

  componentDidMount() {
    // INIT AFTER LOGGED IN COMPLETE

  }

  render() {
    let appstate = this.props.appstate;
    let dispatch = this.props.dispatch;
    let route_params = appstate.route_current.route_params;
    if (typeof route_params !== 'object') {
      route_params = {};
    }

    return (
      <Wrapper route_params={route_params} appstate={this.props.appstate} dispatch={this.props.dispatch} />
    );
  }
}
