import { Cookies } from 'react-cookie';

/*
https://www.npmjs.com/package/universal-cookie
Universal cookies for JavaScript

PREPORUKA ZA REACT:
https://www.npmjs.com/package/react-cookie
Universal cookies for React
https://github.com/reactivestack/cookies/tree/master/packages/react-cookie

cookies.set('myCat', 'Pacman', { path: '/' });
console.log(cookies.get('myCat')); // Pacman
*/

export let cookie_utils = {};

// const cookies = new Cookies();
// console.log(cookies);
cookie_utils.cookies = new Cookies();
console.log(cookie_utils.cookies);


cookie_utils.item_get = async (key) => {
  let value = await cookie_utils.cookies.get(key);
  cookie_utils.log();
  return value;
};

cookie_utils.cookie_get_all = async () => {
  let all = await cookie_utils.cookies.getAll();
  return all;
};

cookie_utils.item_set = async (key, value, options) => {
  await cookie_utils.cookies.set(key, value);
  cookie_utils.log();
  return true;
};

cookie_utils.item_delete = async (key) => {
  // console.log(cookie_utils);
  // let value = await cookie_utils.cookies.removeCookie(key); // NE VALJA removeCookie je poseban dependency...
  let value = await cookie_utils.cookies.remove(key);
  cookie_utils.log();
  return true;
};

cookie_utils.log = () => {
  console.log('cookie_utils.log()');
  console.log(cookie_utils.cookies.getAll());
};

