import React, { Component } from 'react';
import SpinnerNormal from '../components/SpinnerNormal';
import SpinnerSmall from '../components/SpinnerSmall';

// SPLASH SCREEN PRESENTATION

export default class AppSplashScreen extends Component {
  render() {
    console.log('render() AppSplashScreen ');
    let jsx = (
      <><SpinnerNormal /><p> Loading...</p></>
    );
    return (
      <>
        {jsx}
      </>
    );
  }
}
