import React, { Component } from 'react';
import TreeLiFolder from './TreeLiFolder';

export default class TreeItemsFolders extends Component {
  constructor(props) {
    super(props);
    console.log('constructor TreeItemsFolders');
    console.log(this.props);
    console.log(this.props.folders);
  }

  render() {
    let folders_arr = [];

    let has_children = false;
    if (typeof this.props.folders !== 'undefined' && Array.isArray(this.props.folders) && this.props.folders.length > 0) {
      has_children = true;
    }

    let jsx = null;
    let jsx_folders_arr = null;
    /*
    if (has_children) {
      folders_arr = this.props.folders;
      jsx_folders_arr = folders_arr.map((folder, index) => {
        console.log('*** folder item');
        console.log(folder);
        return (
          <TreeItemFolder key={folder.id} folder={folder} folders={folder.children} />
        );
      });
    }
    */


    if (has_children) {
      folders_arr = this.props.folders;
      console.log('*** *** folders_arr');
      console.log(folders_arr);
      jsx_folders_arr = folders_arr.map((folder, index) => {
        console.log('*** folder item');
        // console.log(folder);
        return (
          <TreeLiFolder key={folder.id} folder={folder} folders={folder.children} my={this.props.my} active_item={this.props.active_item} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />
        );
      });
      jsx = (
        <ul className="">
          {jsx_folders_arr}
        </ul>
      );
    }


    return (
      <>
        {jsx}
      </>
    );
  }
}
