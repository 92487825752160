import React, { Component } from 'react';
import { actionNewRoute, actionUserFetch } from '../actions/actions.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';
import UniversalFollowMultibuttonContainer from '../containers/UniversalFollowMultibuttonContainer';
import { url_lib } from '../utils/url-lib.js';


export default class ToolbarUserTheir extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let user_id = route_params.user_id;
    let move = this.props.appstate.route_freshness;
    let raw_data = this.props.appstate.data_user; // their
    let key = raw_data.key;
    if (key !== user_id) {
      // not that key. we need other data
      dispatch(actionUserFetch(move, user_id));
    } else {
      console.log('we already have data for that user (key). skip fetching');
    }
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let single_item = [];
    let item;
    let props;

    let route_params = this.props.appstate.route_current.route_params;
    let user_id = route_params.user_id;
    let my_user_id = this.props.appstate.my_user_id;

    let href_user = url_lib.user(user_id);

    let raw_data = this.props.appstate.data_user; // their
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      single_item = raw_data.data.item;

      item = single_item;
      props = item;
    }

    console.log('*****THEIR TOOLBAR ITEM');
    console.log(single_item);

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_item = null;
    let jsx_toolbar_content = null;
    let jsx = null;
    // if (pending === true) {


    let jsx_user_toolbar_spinner = (
      <div className="spa-user-toolbar-spinner spinner-area">
        <div className="spinner-cell">
          <div className="the-spinner"></div>
        </div>
      </div>
    );

    if (pending) {
      // SPINNER
      // jsx_spinner = <SpinnerMain />
      jsx_spinner = jsx_user_toolbar_spinner;
    }


    if (pending === false) {

      jsx_toolbar_content = (
        <>
          <div className="avatar">
            <a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img className="user-img" src={props.avatar_url} /></a>
          </div>

          <div className="username-group username-group-custom-1">
            <div className="username"><a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.display_name}</a></div>
            <UniversalFollowMultibuttonContainer user_id={user_id} my_user_id={my_user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />

          </div>


          <div className="social-numbers-group">
            <div className="clickable click-to-profile-following" data-user-id="' + props.data.user_id + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'FOLLOWING' })}><span>{props.stats.following}</span> Following</div>
            <div className="clickable click-to-profile-followers" data-user-id="' + props.data.user_id + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'FOLLOWERS' })}><span>{props.stats.followers}</span> Followers</div>
            <div className="not-clickable"><span>{props.stats.saved_links}</span> Saved links</div>
          </div>

        </>
      );

    } // end of pending false

    return (
      <>

        <div className="main-toolbar JS-MADE">
          <div className="user-profile-toolbar-panel">

            {jsx_toolbar_content}
            {jsx_spinner}

            <div className="user-toolbar-ajax">
            </div>
          </div>
        </div>

      </>
    );
  }
}
