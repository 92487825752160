import React, { Component } from 'react';
import MultiModalItem from './MultiModalItem';

export default class MultiModalItems extends React.Component {
  //

  render() {
    let modals = this.props.modals;
    // let modals = [1,2,3,4,5];

    let jsx = null;
    let jsx_modals = null;
    if (modals.length > 0) {
      jsx_modals = modals.map((item, index) => {
        let animate_out_before_delete = item.animation_before_delete;
        return <MultiModalItem key={item.id} id={item.id} item={item} animate_out_before_delete={animate_out_before_delete} dispatch={this.props.dispatch} />
      });
      jsx = (
        <div className="multimodal-overlay">
          {jsx_modals}
        </div>
      );
    }

    return (
      <>
        {jsx}
      </>
    );
  }
}

