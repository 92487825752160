import config from './putcut-react-config.js';
import autoconfig from './putcut-react-autoconfig.js';

// API LIBRARY and URL LIBRARY


// -------------------------------------
// -------------URL LIBRARY-------------

let url_lib = {};

// url_lib.website_prefix = 'https://putcut.com';
url_lib.website_prefix = function () {
  return config.website_url_prefix;
};

// STATIC PAGES URLs etc...
url_lib.backend_logout = function () {
  var backend_logout_url = process.env.PUBLIC_URL + '/logout'; // logout backend side
  return backend_logout_url;
};

//
url_lib.about = function () {
  let url = process.env.PUBLIC_URL + '/about'; // logout backend side
  return url;
};

// ROUTES URLs
url_lib.activities = function (userid) {
  var temp_history_url = process.env.PUBLIC_URL + '/' + userid + '/activities';
  return temp_history_url;
};
url_lib.feeds = function () {
  var temp_history_url = process.env.PUBLIC_URL + '/feeds';
  return temp_history_url;
};
url_lib.user = function (userid) {
  let temp_user_url = process.env.PUBLIC_URL + '/user/' + userid;
  return temp_user_url;
};
url_lib.user_profile_edit = function () {
  var temp_user_url = process.env.PUBLIC_URL + '/settings/profile';
  return temp_user_url;
};
url_lib.user_following = function (userid) {
  var temp_user_url = process.env.PUBLIC_URL + '/user/' + userid + '/following';
  return temp_user_url;
};
url_lib.user_followers = function (userid) {
  var temp_user_url = process.env.PUBLIC_URL + '/user/' + userid + '/followers';
  return temp_user_url;
};
url_lib.folder = function (userid, fid) {
  // TODO OBJASNITI ????
  // <a class="putcut-folder-anchor" href="/' + temp_user_id + '/folders/' + temp_fid + '">
  var temp_folder_href = process.env.PUBLIC_URL + '/' + userid + '/folders/' + fid;
  return temp_folder_href;
};
url_lib.folder_desktop = function (userid) {
  var temp_history_url = process.env.PUBLIC_URL + '/' + userid + '/folders';
  return temp_history_url;
};
url_lib.recyclebin = function () {
  var temp_history_url = process.env.PUBLIC_URL + '/recyclebin';
  return temp_history_url;
};
url_lib.post_single = function (userid, postid) {
  // link.sharing.url = 'https://putcut.com/' + temp_user_id + '/posts/' + temp_post_id;
  var temp_posts_url = process.env.PUBLIC_URL + '/' + userid + '/posts/' + postid;
  return temp_posts_url;
};
url_lib.search = function (section, query) {
  var temp_history_url = process.env.PUBLIC_URL + '/search?section=' + section + '&q=' + query;
  return temp_history_url;
};
url_lib.blog = function (slug) {
  var temp_history_url = process.env.PUBLIC_URL + '/blog/' + slug;
  return temp_history_url;
};
url_lib.help = function (slug) {
  let temp_history_url = process.env.PUBLIC_URL + '/help';
  if (typeof slug !== 'undefined') {
    temp_history_url = process.env.PUBLIC_URL + '/help/' + slug;
  }
  return temp_history_url;
};

// TODO: routes URLs for settings and edit profile pages...


// SHARING LINKS

url_lib.sharing_post_single_url = function (userid, postid) {
  let posts_single_url = url_lib.post_single(userid, postid);
  // let temp_prefix = 'https://putcut.com';
  let sharing_url = this.website_prefix() + posts_single_url;
  return sharing_url;
};

url_lib.sharing_twitter_url = function (url) {
  let sharing_url = 'https://twitter.com/intent/tweet?url=' + encodeURIComponent(url); // pure js
  return sharing_url;
};

url_lib.sharing_facebook_url = function (url) {
  let sharing_url = 'https://www.facebook.com/dialog/share?app_id=1030368550442592&href=' + encodeURIComponent(url); // pure js
  return sharing_url;
};

url_lib.sharing_email_url = function (url) {
  let email_body_a = '<a href="' + url + '">PUTCUT LINK TITLE</a>'; // TODO
  // let sharing_email_url = 'mailto:?body=' + encodeURIComponent(email_body_a);
  let sharing_url = 'mailto:?body=' + encodeURIComponent(url); // pure js
  return sharing_url;
};





// -------------------------------------
// -------------API LIBRARY-------------

let api_lib = {};

// api_lib.api_prefix = 'https://webservices-v4.putcut.com';
api_lib.api_prefix = function () {
  return autoconfig.api_prefix_current();
};


// api TODO


// AUTHENTICATION API
api_lib.auth_login = function () {
  let fetch_url = this.api_prefix() + '/api/v2/auth/login/smart'; // api v2 POST_METHOD
  return fetch_url;
};

// api TODO
/*
putcut.api_lib.auth_logout = function() {
	// var fetch_url = putcut.config.api_prefix() + '/api/v2/logout'; // api v2
	// PREDLOG /auth/logout/do
	// PREDLOG /auth/logout/smart
	var fetch_url = putcut.config.api_prefix() + '/auth/logout/smart'; // api v2 POST_METHOD
	return fetch_url;
};
putcut.api_lib.auth_loginstatus_get = function() {
	//
	// var check_login_api_url = putcut.config.api_prefix() + '/api/v2/loginstatus';
	// var fetch_url = putcut.config.api_prefix() + '/api/v2/loginstatus'; // api v2
	// PREDLOG /auth/status/get  TODO PROVERITI++++++
	// PREDLOG /auth/loginstatus/get  TODO PROVERITI++++++
	var fetch_url = putcut.config.api_prefix() + '/api/v2/auth/loginstatus/get'; // api v2 GET_METHOD
	return fetch_url;
};
*/


// users APIs
api_lib.user_my_profile_get = function () {
  // CHECK IS LOGGED IN
  let fetch_url = this.api_prefix() + '/api/v2/users/myprofile/get'; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.user_my_profile_update = function () {
  let fetch_url = this.api_prefix() + '/api/v2/users/myprofile/update'; // api v2 POST_METHOD TODO ***PROVERITI ??? // MK izbacen + userid
  return fetch_url;
};
api_lib.user_my_profile_avatar_update = function () {
  // TODO ???
  // <form method="post" enctype="multipart/form-data" id="image_form1" action="/profile/edit/submit-picture">
  let fetch_url = this.api_prefix() + '/api/v2/users/myprofile/avatar/update'; // api v2 POST_METHOD TODO ???
  // additional
  // is_real_submit: 1 // govori serveu da izvri redirekciju na /settings/profile
  return fetch_url;
};
api_lib.user_profile_get = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/users/profile/get?userid=' + userid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.social_following_get = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/social/followinglist/get?userid=' + userid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.social_followers_get = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/social/followerslist/get?userid=' + userid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.social_is_following_get = function (userid1, userid2) {
  let fetch_url = this.api_prefix() + '/api/v2/social/checkisfollowing/get?userid=' + userid1 + '&userid2=' + userid2;  // api v2 POST_METHOD // MK
  return fetch_url;
};
api_lib.social_follow_upsert = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/social/follow/upsert';// api v2 POST_METHOD // MK
  return fetch_url;
};
api_lib.social_unfollow_upsert = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/social/unfollow/upsert';// api v2 POST_METHOD // MK
  return fetch_url;
};


// folders APIs
api_lib.folders_my_get = function () {
  let fetch_url = this.api_prefix() + '/api/v2/folders/my/get'; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.folders_public_get = function (userid) {
  let fetch_url = this.api_prefix() + '/api/v2/folders/public/get?userid=' + userid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.folders_lock_update = function (fid) {
  let fetch_url = this.api_prefix() + '/api/v2/folders/lock/update';  // api v2 POST_METHOD
  return fetch_url;
};
api_lib.folders_unlock_update = function (fid) {
  let fetch_url = this.api_prefix() + '/api/v2/folders/unlock/update';  // api v2 POST_METHOD
  return fetch_url;
};
api_lib.folders_create = function () {
  let fetch_url = this.api_prefix() + '/api/v2/folders/create';  // api v2 POST_METHOD // MK
  return fetch_url;
};
api_lib.folders_rename_update = function () {
  let fetch_url = this.api_prefix() + '/api/v2/folders/rename/update';  // api v2 POST_METHOD // MK
  return fetch_url;
};
api_lib.folders_delete = function () {
  let fetch_url = this.api_prefix() + '/api/v2/folders/delete';  // api v2 POST_METHOD // MK
  return fetch_url;
};


// posts and links APIs
api_lib.posts_my_get = function (fid) {
  // MY FOLDERS POSTS
  let fetch_url = this.api_prefix() + '/api/v2/posts/my/get?folder=' + fid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.posts_public_get = function (fid, userid) {
  let fetch_url = this.api_prefix() + '/api/v2/posts/public/get?userid=' + userid + '&folder=' + fid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.posts_desktop_get = function () {
  var fid = "0"; // zero
  var fetch_url = this.posts_my_get(fid); // VARIANT OF api_lib.posts_my_get()
  return fetch_url;
};
api_lib.posts_recyclebin_get = function () {
  let fetch_url = this.api_prefix() + '/api/v2/posts/recyclebin/get'; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.posts_single_get = function (pid) {
  let fetch_url = this.api_prefix() + '/api/v2/posts/single/get?postid=' + pid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.posts_link_create = function () {
  // ADD/WRITE NEW LINK APi...
  let fetch_url = this.api_prefix() + '/api/v2/posts/link/create'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_link_update = function () {
  // WRITE EDIT UPDATE LINK API
  let fetch_url = this.api_prefix() + '/api/v2/posts/link/update'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.urlrating_upsert = function () {
  // api_lib.urlrating_upsert = function(urlid, postid, rating) {
  let fetch_url = this.api_prefix() + '/api/v2/posts/urlrating/upsert'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_move_update = function () {
  let fetch_url = this.api_prefix() + '/api/v2/posts/post/move/update'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_copy_create = function () {
  let fetch_url = this.api_prefix() + '/api/v2/posts/post/copy/create'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_recycle_update = function () {
  let fetch_url = this.api_prefix() + '/api/v2/posts/post/recycle/update'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_unrecycle_update = function () {
  let fetch_url = this.api_prefix() + '/api/v2/posts/post/unrecycle/update'; // api v2 POST_METHOD
  return fetch_url;
};
api_lib.posts_post_delete = function () {
  // ???
  let fetch_url = this.api_prefix() + '/api/v2/posts/post/delete'; // api v2 POST_METHOD
  return fetch_url;
};


// search APIs
api_lib.search_universal_get = function (section, query, page, per_page) {
  /*
  [
    1 => 'allposts',
    4 => 'myposts',
    2 => 'myfriendsposts',
    3 => 'people'
  ];
  */
  // https://webservices.putcut.com/api/v2/search?section=[section]&q=[query]&page=[page]&perPage=[perPage]
  let fetch_url;
  if (typeof page === 'undefined' || typeof per_page === 'undefined') {
    // CASe missing parameters
    // var fetch_url = putcut.config.api_prefix() + '/api/v2/search?section=' + section + '&q=' + encodeURIComponent(query); // api v2
    fetch_url = this.api_prefix() + '/api/v2/search/universal/get?section=' + section + '&q=' + encodeURIComponent(query); // api v2 GET_METHOD
  } else {
    // var fetch_url = putcut.config.api_prefix() + '/api/v2/search?section=' + section + '&q=' + encodeURIComponent(query) +'&page=' + page + '&perPage=' + per_page; // api v2
    fetch_url = this.api_prefix() + '/api/v2/search/universal/get?section=' + section + '&q=' + encodeURIComponent(query) + '&page=' + page + '&perPage=' + per_page; // api v2 GET_METHOD
  }
  return fetch_url;
};

// api TODO search_tag


// activity APIs
api_lib.activities_get = function (after) {
  let howmany = 20;
  let temp_load_more_ajax_url = this.api_prefix() + '/api/v2/activities/get?howmany=' + howmany; // api v2 GET_METHOD
  if (typeof after !== 'undefined') {
    temp_load_more_ajax_url = this.api_prefix() + '/api/v2/activities/get?after=' + after + '&howmany=' + howmany; // api v2 GET_METHOD
  }
  return temp_load_more_ajax_url;
};


//
// -----rss feeds APIs
api_lib.feeds_items_get = function () {
  let page = 0; // api v2 TEMPORARY
  let fetch_url = this.api_prefix() + '/api/v2/feeds/aggregateditems/get?page=' + page; // api v2 GET_METHOD
  return fetch_url;
};
//
//  api tODO ?????
//
api_lib.feeds_subscriptions_get = function () {
  let fetch_url = this.api_prefix() + '/api/v2/feeds/subscribedchannels/get'; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.feeds_detect = function (url) {
  ///
  let fetch_url = this.api_prefix() + '/api/v2/feeds/detectfeeds'; // api v2 POST_METHOD ***
  return fetch_url;
};
api_lib.feeds_detect_manual = function (url) {
  ///
  let fetch_url = this.api_prefix() + '/api/v2/feeds/manualdetectfeed'; // api v2 POST_METHOD ***
  // JEDNINA 1 FEED
  return fetch_url;
};
api_lib.feeds_subscriptions_update = function () {
  // TODO API V2
  // PREDLOG /feeds/subscribedchannels/update


  // 1)
  // var fetch_url = this.api_prefix() + '/api/v2/feeds/subscribedchannels/update'; // api v2 POST_METHOD
  // TAJ IMA PROBLEM JER JE IZMENJEN. MK. DA SVAKL IPUT SALJE KOMPLETNU LISTU ZA SUBSCRIBE A SVE STO NIEJ NAVEDENO BRISE:

  // 2)
  let fetch_url = this.api_prefix() + '/api/v2/feeds/subscribedchannels/manage/smart'; // api v2 POST_METHOD
  // https://webservices-v4.putcut.com/api/v2/feeds/subscriptions/update
  // /api/v2/feeds/subscribedchannels/manage/smart
  //

  return fetch_url;
};


// comments API
api_lib.comments_get = function (pid) {
  let fetch_url = this.api_prefix() + '/api/v2/comments/get?postid=' + pid; // api v2 GET_METHOD
  return fetch_url;
};
api_lib.comments_create = function (pid, text) {
  let fetch_url = this.api_prefix() + '/api/v2/comments/create'; // api v2 POST METHOD
  return fetch_url;
};
api_lib.comments_delete = function () {
  // NOVO
  // PREDLOG TODO******
  var fetch_url = this.api_prefix() + '/api/v2/comments/delete'; // api v2 POST METHOD
  return fetch_url;
};
api_lib.comments_report = function (comment_id, report_csv) {
  let fetch_url = this.api_prefix() + '/api/v2/comments/reportcomment/upsert'; // api v2 POST METHOD
  return fetch_url;
};


// -----SPECIALS

// OPEN URL API...
api_lib.openurl_url = function (url) {
  // var temp_link_href = process.env.PUBLIC_URL + '/openurl?url=' + encodeURIComponent(url); // old api
  // var fetch_url = process.env.PUBLIC_URL + '/openurl?url=' + encodeURIComponent(url); // old api
  // url_open: "https://webservices-v4.putcut.com/api/v2/openurl?url=https%3A%2F%2Fwww.airdroid.com%2Fen%2FregisterComplete.html" // api v2 example
  let fetch_url = this.api_prefix() + '/api/v2/openurl?url=' + encodeURIComponent(url); // api v2
  return fetch_url;
};
// TODO


// SCRAPE URL API...
api_lib.scrape_url = function () {
  let fetch_url = this.api_prefix() + '/api/v2/scrape'; // api v2 POST_METHOD
  // PREDLOG /scrapeurl/do // TODO
  return fetch_url;
};





//-----------------NIJE PUTCUT ISPOD-------------------------------------------------------


api_lib.test_unexisting_url = function () {
  // 
  let fetch_url = this.api_prefix() + '/test_unexisting_url/test_unexisting_url/test_unexisting_url' // TESTING
  return fetch_url;
};



export { url_lib, api_lib };
