/* 
FrFr FRIENDLY FRAMEWORK
freshness counter , id_utils...
Version 0a 2019-06-19
by 
// Please describe here if you customized this file
*/

export let id_utils = {};

export let idCreator = {};
id_utils.counter_state = 100;
id_utils.get_fresh_number = function() {
  // this function is not pure
  // console.log(this.counter);
  this.counter_state++;
  // console.log(this.counter);
  return this.counter_state;
};

/*
id_utils.getFreshID = function() {
    // this function is not pure
    console.log(this.counter_state);
    this.counter_state++;
    console.log(this.counter_state);
    return this.counter_state;
};
*/
