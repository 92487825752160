import React, { Component } from 'react';
import SpinnerNormal from './SpinnerNormal';

// spinner  when main section on page is pending

export default class SpinnerMain extends Component {
  render() {
    return (
      <div className="spa-main-spinner spinner-area">
        <div className="spinner-cell">
          <SpinnerNormal />
        </div>
      </div>
    );
  }
}
