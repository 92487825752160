import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';



export default class ModalConfirm extends Component {
  constructor(props) {
    super(props);
    console.log('consttructor ModalInput');
    console.log(this.props);
    let initial_value = '';
    if (this.props.modal_args.value) {
      initial_value = this.props.modal_args.value;
    }
    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true,
      scraped_data: {},
      title: 'i'
    };
    // binfing this
    this.handleChange = this.handleChange.bind(this);
    this._handleClick = this._handleClick.bind(this);
    // CLONED***
    // this._cb_refresh = this._cb_refresh.bind(this);
    // this._cb_follow = this._cb_follow.bind(this);
    // this._cb_unfollow = this._cb_unfollow.bind(this); // bez bindovanja bude Cannot read property 'props' of undefined
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleClick(what, second, third) {
    // let dispatch = this.props.dispatch;
    if (what === 'SCRAPE_AGAIN') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        this._scrape();
      }
    }
    if (what === 'SUBMIT') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        // this._submit();

        let input_value = '';
        if (this.state.modalinput) {
          input_value = this.state.modalinput;
        }
        input_value = input_value.trim();

        let modal_args = this.props.modal_args;
        let callback_validate = modal_args.callback_validate;
        let is_valid = true;
        if (callback_validate) {
          let validation = callback_validate(input_value); // do validation
          if (validation === true) {
            is_valid = true;
          } else {
            // form validation fail
            is_valid = false;
            this.setState({
              error: true,
              error_message: validation
            });
          }
          console.log('validation: ', validation);
        }

        if (is_valid === true) {
          console.log('TEST 1');
          let callback_submit = modal_args.callback_submit;
          if (callback_submit) {
            callback_submit(input_value);
          }
        }
      }
    }
  }








  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    /*
    if (this.state.putyourcutmodal !== prevState.putyourcutmodal) {
      // RESCRAPE
      this._scrape();
    } else {
      console.log('...SKIP SCRAPING');
    }
    */
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    /*
    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let user_id = route_params.user_id;
    */


    let my = true;

    // console.log(table_arr);


    if (this.state.pending === false) {
      pending = false;
    }

    let modal_data = this.props.modal_data;
    let initial_scraping_url = modal_data.url;
    console.log(modal_data);


    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    //--------------------------------***********************

    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;

    let confirm_btn_title = 'Ok';
    if (modal_args.confirm_btn_title) {
      confirm_btn_title = modal_args.confirm_btn_title;
    }

    let cancel_btn_title = 'Cancel';
    if (modal_args.cancel_btn_title) {
      cancel_btn_title = modal_args.cancel_btn_title;
    }

    let callback_cancel = dispatch_close_modal;
    if (modal_args.callback_cancel) {
      callback_cancel = modal_args.callback_cancel;
    }






    return (
      <>
        <div className="modal-item-content thisis-ModalConfirm">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="flex-bar center gaps buttons-group-style-2">
            <div className="xi btn btn-size-1" onClick={dispatch_close_modal}>{cancel_btn_title}</div>
            <div className="xi btn btn-size-1 btn-style-blue-1" onClick={this._handleClick('SUBMIT')}>{confirm_btn_title}</div>
          </div>
        </div>
      </>
    );
  }
}
