import { id_utils } from '../utils/frfr-freshness-counter.js';
import { modals_lib } from '../utils/modals-lib.js';

//

export let actionMultimodalClose = function (modal_id) {
  // thunk
  return function thunk(dispatch) {
    // ANIMATED CLOSE MODAL
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_BEFORE_CLOSE_MODAL',
          payload: modal_id,
        }
      }
    });
  };
};

export let actionMultimodalDelete = function (modal_id) {
  // thunk
  return function thunk(dispatch) {
    // (ON ANIMATION END) DELETE MODAL PHISICALY
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_CLOSE_MODAL',
          payload: modal_id,
        }
      }
    });
  };
};

/*
dispatch({
  type: 'MULTIMODAL_BEFORE_CLOSE_MODAL',
  payload: payload
});
}

dispatch({
  type: 'MULTIMODAL_CLOSE_MODAL',
  payload: payload
});
*/


export let actionMultimodalOpen = function (content_type, content_data) {
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    /*
    let modal_type = 'BASIC';
    if (content_type === 'MODAL_THEATER') {
      modal_type = 'THEATER';
    }
    */
    let mmm_item_params = modals_lib.get(content_type);
    //
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_OPEN_MODAL',
          payload: {
            program_type: content_type,
            single_instance: mmm_item_params.single_instance,
            modal_type: mmm_item_params.physical_type,
            modal_content: {
              type: content_type,
              data: content_data
            }
          },
          meta: {
            fresh_id: freshness
          }
        }
      }
    });

  };
};




export let actionMultimodalOpen_frfr_erf = function (frfr_error_response) {
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_OPEN_MODAL',
          payload: {
            modal_type: 'BASIC',
            modal_content: {
              type: 'MODAL_FRFR_ERF',
              data: {
                frfr_error_response: frfr_error_response
              }
            }
          },
          meta: {
            fresh_id: freshness
          }
        }
      }
    });


  };
};


export let actionMultimodalOpen_catch = function (response, where) {
  // MODAL WHEN ERROR CATCH
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    //
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_OPEN_MODAL',
          payload: {
            modal_type: 'BASIC',
            modal_content: {
              type: 'MODAL_CATCH',
              data: {
                response: response,
                where: where
              }
            }
          },
          meta: {
            fresh_id: freshness
          }
        }
      }
    });
  };
};


export let actionMultimodalComingSoon = function () {
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_OPEN_MODAL',
          payload: {
            modal_type: 'BASIC',
            modal_content: {
              type: 'MODAL_COMING_SOON',
              data: ''
            }
          },
          meta: {
            fresh_id: freshness
          }
        }
      }
    });

  };
};


export let actionMultimodalOpen_success = function (response) {
  // NOT USED YET
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    if (response.frfr_success === true) {
      // frfr success message
      /*
      dispatch({
        type: 'MULTIMODAL_OPEN_MODAL_FRFR_SUCCESS',
        payload: {
          frfr_success_response: response
        },
        meta: {
          fresh_id: freshness
        }
      });
      */
      dispatch({
        type: 'MULTIMODAL_ACTION',
        payload: {
          pass_action: {
            type: 'MULTIMODAL_OPEN_MODAL',
            payload: {
              modal_type: 'BASIC',
              modal_content: {
                type: 'MODAL_FRFR_SUCCESS',
                data: {
                  frfr_success_response: response
                }
              }
            },
            meta: {
              fresh_id: freshness
            }
          }
        }
      });
    } else {
      // old success message
      // ???
      dispatch({
        type: 'MULTIMODAL_ACTION',
        payload: {
          pass_action: {
            type: 'MULTIMODAL_OPEN_MODAL',
            payload: {
              modal_type: 'BASIC',
              modal_content: {
                type: 'MODAL_SUCCESS',
                data: {
                  response: response
                }
              }
            },
            meta: {
              fresh_id: freshness
            }
          }
        }
      });
    }
  };
};


export let actionMultimodalOpen_frfr_success = function (frfr_success_response) {
  // NOT USED YET
  // thunk
  return function thunk(dispatch) {
    // step 1
    let freshness = id_utils.get_fresh_number();
    dispatch({
      type: 'MULTIMODAL_ACTION',
      payload: {
        pass_action: {
          type: 'MULTIMODAL_OPEN_MODAL',
          payload: {
            modal_type: 'BASIC',
            modal_content: {
              type: 'MODAL_FRFR_SUCCESS',
              data: {
                frfr_success_response: frfr_success_response
              }
            }
          },
          meta: {
            fresh_id: freshness
          }
        }
      }
    });
  };
};





/*
export let actionMultimodalAvatarUpload = function () {
  // thunk
  return function thunk(dispatch) {
  };
};
*/
