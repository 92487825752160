import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import { actionAuthLogout } from '../actions/auth-actions.js';
import PopupMenuBrain from '../containers/PopupMenuBrain';
import { url_lib } from '../utils/url-lib.js';
// import { browser_utils } from '../utils/frfr-browser-utils.js';

export default class NavMyAccountGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this._handleClick = this._handleClick.bind(this);

    this._callbackClose = this._callbackClose.bind(this);
    this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
  }

  _callbackClose() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened: false
    });
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE');
        if (this.state.opened === true) {
          this.setState({
            opened: false
          });
        } else {
          this.setState({
            opened: true
          });
        }
      }
    }
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'LOGOUT') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionAuthLogout());
        console.log('test');
      }
    }
    if (what === 'OPEN_URL') {
      return (e) => {
        /*
        e.preventDefault();
        console.log('CLICKED');
        // BROWSER OPEN URL
        let rel_url = second;
        browser_utils.browser_go_url_relative(rel_url);
        */
      }
    }

    if (what === 'SECOND_OPTION_BLA_BLA') {
      return (e) => {
        // dispatch(actionNewRoute());
      }
    }
  }


  render() {
    console.log('render NavMyAccountGroup');
    console.log(this.props.appstate.data_my_user_data.data);

    let data = this.props.appstate.data_my_user_data.data;
    let avatar_src = data.avatar_url;
    let display_name = data.display_name;
    let my_user_id = data.user_id;
    let user_id = my_user_id;
    let href_user = url_lib.user(user_id);

    let jsx_case_notlogged = (
      <div className="nav-my-account-group">
        <a className="guest-login-button" data-user-id="" href={process.env.PUBLIC_URL + "/login"}>Log in</a>
      </div>
    );


    let jsx_menu = '';
    if (this.state.opened === true) {
      jsx_menu = (
        <PopupMenuBrain callback={this._callbackClose} parent_ref={this.parentRef}>
          <div className="nav-my-user-menu-panel dropdown dropdown-top-right" style={{ display: 'block' }}>
            <div className="dropdown-body">
              <div className="dropdown-arrow arrow-top"></div>
              <a className="dropdown-option click-to-edit-user" href="/settings/profile" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'PROFILE_EDIT' })}>Edit profile</a>
              <div className="dropdown-option-separator"></div>
              <a className="dropdown-option click-to-settings-account" href="/settings/account">Account settings</a>
              <a className="dropdown-option click-to-logout" href="/logout" onClick={this._handleClick('LOGOUT')}>Logout</a>
            </div>
          </div>
        </PopupMenuBrain>
      );
    }

    return (
      <>

        <div className="nav-my-account-group">
          <div className="avatar">
            <a className="spa-anchor-user" data-user-id="" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })} >
              <img src={avatar_src} />
            </a>
          </div>
          <div className="balloon">
            <a className="nickname spa-anchor-user" data-user-id="" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{display_name}</a>
            <div className="expand-user-dropdown click-to-nav-my-user-menu"><img src={process.env.PUBLIC_URL + "/static/img/expand.png"} onClick={this._handleClick('TOGGLE')} ref={this.parentRef} />
              {jsx_menu}
            </div>
          </div>

        </div>

      </>
    );
  }
}

// putcut.template.nav_my_account_group_case_notlogged = function() {
