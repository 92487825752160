import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

// import ModalPrompt from './ModalPrompt';
import ModalCustomJSX from './ModalCustomJSX';



export default class ModalCommentOptions extends Component {
  constructor(props) {
    super(props);
    /*
    let initial_value = '';
    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true
    };
    */
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'REPORT') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        let comment_id = second;
        console.log('clicked REPORT ', comment_id);
      }
    }
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let comment_id = modal_data.id;

    let dispatch_close_modal = this.props.dispatch_close_modal;

    let jsx_custom = (
      <>
        <div className="modal-comment-options-real">
          <h2>Comment options</h2>
          <div className="comment-options-container" data-comment-id="' + props.comment_id + '">
            <h3>Report comment</h3>
            <p>Help us understand what is the problem with this comment. Check the reason(s) below you think this comment shouldn&apos;t be on PutCut.</p>
            <input type="checkbox" className="check-to-report-comment" value="duplicate" />Comment is duplicate<br />
            <input type="checkbox" className="check-to-report-comment" value="unreadable" />Comment is unintelligible<br />
            <input type="checkbox" className="check-to-report-comment" value="rulebreaker" />Comment content breaks terms and conditions<br />
            <input type="checkbox" className="check-to-report-comment" value="spam" />Comment is spam<br />
            <input type="checkbox" className="check-to-report-comment" value="adult" />Comment contains adult content<br />
            <input type="checkbox" className="check-to-report-comment" value="trolling" />Comment is engaging in trolling<br />
            <input type="checkbox" className="check-to-report-comment" value="other" />Other reason<br />
          </div>
          <div className="comment-options-group buttons-group-style-2">
            <div className="report-comment-options-button button btn-size-1 button-style-blue-1 text-style-blue-1 right" style={{ width: 110 }} onClick={this._handleClick('REPORT', comment_id)}>Report</div>
            <div className="cancel-comment-options-button button btn-size-1 button-style-light-1 text-style-blue-1 right" style={{ width: 110 }} onClick={dispatch_close_modal}>Cancel</div>
          </div>
        </div>
      </>
    );

    let modal_args = {
      comment_id: comment_id,
      custom_content_jsx: jsx_custom
    };

    return (
      <>
        <ModalCustomJSX modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}

