import React, { Component } from 'react';
import { framework_vs_app } from '../utils/framework-vs-app.js';
import RouterApp from './RouterApp';

// INIT CONTAINER COMPONENT
// first step after App.js ... ...and proceed to decision making components

export default class Init extends Component {
  constructor(props) {
    super(props);
    console.log('constructor Init');
    console.log(this.props);
  }

  componentDidMount() {
    let dispatch = this.props.dispatch;
    // INIT BEGIN
    dispatch({ type: 'WE_ARE_LIVE' });
    dispatch({ type: 'INFO_INIT_PHASE_STARTED' });
    // INIT APP AND SCRIPTS
    framework_vs_app.init(); // main init function for this app
    // INIT COMPLETED
    dispatch({ type: 'INIT_COMPLETED' });
  }

  render() {
    // let routing component decide about presentation... what will be rendered initialy
    return (
      <>
        <RouterApp appstate={this.props.appstate} dispatch={this.props.dispatch} />
      </>
    );
  }
}
