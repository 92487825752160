import React, { Component } from 'react';
import { actionNewRoute, actionUserFetch, actionUserFetchMy } from '../actions/actions.js';
import { actionMultimodalOpen, actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { mini_ajax_post, mini_ajax_get } from '../utils/ajax.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import ToolbarUserMy from './ToolbarUserMy';
import ToolbarUserTheir from './ToolbarUserTheir';
import ListFollowing from './ListFollowing';
import ListFollowers from './ListFollowers';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

export default class UserProfileEdit extends Component {

  constructor(props) {
    super(props);
    console.log('constructor UserProfile');
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
    this._submit = this._submit.bind(this);
    // Create the ref
    this.ref_first_name = React.createRef();
    this.ref_last_name = React.createRef();
    this.ref_birthday_year = React.createRef();
    this.ref_country = React.createRef();
    this.ref_occupations = React.createRef();
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let user_id = route_params.user_id;
    let my_user_id = this.props.appstate.my_user_id;
    let my = false;
    if (user_id === my_user_id) {
      my = true;
    }
    if (my) {
      dispatch(actionUserFetchMy());
    } else {
      let move = this.props.appstate.route_freshness;
      dispatch(actionUserFetch(move, user_id));
    }
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'AVATAR') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // dispatch(actionMultimodalComingSoon());
        // dispatch(actionMultimodalAvatarUpload());
        dispatch(actionMultimodalOpen('MODAL_AVATAR_UPLOAD'));
        // ???
      }
    }
    if (what === 'SUBMIT') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        this._submit();
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _submit() {
    console.log('_submit() ');
    // let dispatch = this.props.dispatch;
    let fetch_url = api_lib.user_my_profile_update();
    /*
    {first_name: data.first_name, last_name: data.last_name, birthday_year: data.birthday_year, country: data.country, occupations: data.occupations}
    */
    /*
    let outbox = {
      first_name: '_first name_',
      last_name: '_last name_',
      birthday_year: '1987',
      country: '_country_',
      occupations: '_ocu_'
    }; // data for post method
    */
    let outbox = {
      first_name: this.ref_first_name.current.value,
      last_name: this.ref_last_name.current.value,
      birthday_year: this.ref_birthday_year.current.value,
      country: this.ref_country.current.value,
      occupations: this.ref_occupations.current.value
    }; // data for post method
    let callback = () => {
      let dispatch = this.props.dispatch;
      console.warn('***** CALLBACK FROM MINI AJAX ???');
      let raw_data = this.props.appstate.data_user_my; // my
      let user_id = raw_data.data.my_user_data.user_id;
      // dispatch(actionNewRoute('USER', { user_id: user_id }));
      let temp_my_profile_url = url_lib.user(user_id);
      browser_utils.browser_go_url(temp_my_profile_url);
    }
    mini_ajax_post(fetch_url, outbox, callback, null, true, 'neki opis');
  }

  componentDidMount() {
    console.log('did MOUNT');
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let single_item = [];
    let item;
    let props;

    // let route_params = this.props.appstate.route_current.route_params;
    // let user_id = route_params.user_id;
    let my = true;
    let raw_data = this.props.appstate.data_user_my; // my
    let user_id = raw_data.user_id;

    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;

      if (my) {
        single_item = raw_data.data.my_user_data; // my
      } else {
        single_item = raw_data.data.item; // their
      }
      item = single_item;
      props = item;
    }

    console.log('*****USER PROFILE single item');
    console.log(single_item);
    console.log(props);

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_item = null;
    let jsx_toolbar = null;
    let jsx_toolbar_my = null;
    let jsx = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {
      zero = false;

      // CASE PROFILE_EDIT ***** ?????

      let textarea_value = props.occupations;

      jsx_item = (
        <>
          <h2>Edit my profile info:</h2>
          <div className="user-profile-toolbar-panel user-profile-toolbar-panel-edit">
            <div className="avatar">
              <a className="spa-anchor-user" href="/user/_5f4f8d3" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img className="user-img" src={props.avatar_url} /></a>
            </div>
            <div className="username-group username-group-custom-3">
              <div className="upload-avatar-button button-style-hover-1" onClick={this._handleClick('AVATAR')}>
                <span className="button-text upload-text click-to-upload-avatar">Upload profile picture</span>
              </div>
            </div>
            <div className="social-numbers-group">
            </div>
          </div>
          <div className="user-profile-panel">
            <div className="clearfix"></div>
            <div className="user-profile-data-group user-profile-editor-group row-2">
              <form action="{{www}}profile/edit/submit" method="post">
                <p className="label">First Name:</p>
                <input
                  type="text"
                  defaultValue={props.first_name}
                  name="first_name"
                  ref={this.ref_first_name}
                />
                <p className="label">Last Name:</p>
                <input
                  type="text"
                  defaultValue={props.last_name}
                  name="last_name"
                  ref={this.ref_last_name}
                />
                <p className="label">Year of birth:</p>
                <input
                  type="number"
                  defaultValue={props.birthday_year}
                  name="birthday_year"
                  ref={this.ref_birthday_year}
                  maxLength="4"
                  min="1900"
                  max="2017"
                  step="1"
                />
                <p className="label">Country:</p>
                <input
                  type="text"
                  defaultValue={props.country}
                  name="country"
                  ref={this.ref_country}
                />
                <p className="label">Occupations:</p>
                <textarea
                  name="occupations"
                  defaultValue={textarea_value}
                  ref={this.ref_occupations}
                />
                <div className="user-edit-buttons-group buttons-group-style-2">
                  <div className="cancel-user-edited-button button button-size-1 button-style-light-1 text-style-blue-1 left" style={{ width: 110 }}>Cancel</div>
                  <div className="save-user-edited-button button button-size-1 button-style-blue-1 text-style-blue-1 left" style={{ width: 110 }} onClick={this._handleClick('SUBMIT')}>Save changes</div>
                </div>
              </form>
            </div>
          </div>

        </>
      );


      jsx = (
        <>
          {jsx_item}
        </>
      );



      /*
      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }
      */

    } // end of pending === false

    return (
      <>

        <div className="user-profile-ajax ajax-container user-profile-container">

          {jsx_toolbar_my}
          {jsx_item}
          {jsx_zero_items}
          {jsx_spinner}

        </div>
      </>
    );
  }
}
