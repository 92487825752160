import React, { Component } from 'react';
import { actionNewRoute, actionRouteRefresh } from '../actions/actions.js';

import { url_lib, api_lib } from '../utils/url-lib.js';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';

import { mini_ajax_post } from '../utils/ajax.js';


export default class StarRatingPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rate_pending: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._rate_link = this._rate_link.bind(this);
  }

  _handleClick(what, second, third) {
    if (what === 'RATE') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('***** CLICKED RATE STAR: ', second);
        this._rate_link(second);
      }
    }
  }

  /*
  _post_rate_link = (fetch_url, data, callback) => {
    this.setState({ rate_pending: true });
    mini_fetch_post(fetch_url, data)
      .then(response => response.json())
      .then(result => {
        console.log('MINI POST SUCCESSFUL');
        console.log(result);
        // this._refresh();
        return result;
      })
      .then(result => {
        
        // AFTER SUCCESS MAKE CALLBACK
        if (callback) {
          callback();
        }
      })
      .catch(e => {
        console.log('mini fetch post CATCH ERROR - e: ');
        console.log(e);
      });
  };
  */

  _rate_link(rating) {
    console.log('_rate_link(rating) SUBMIT ');
    let fetch_url = api_lib.urlrating_upsert();
    let post_id = this.props.post_id;
    let url_id = this.props.url_id;
    // {postid: postid, rating: rating, urlid: urlid}
    let outbox = {
      postid: post_id,
      urlid: url_id,
      rating: rating
    }; // data for post method
    console.log(outbox);
    let callback = () => {
      this.setState({ rate_pending: false });
      let dispatch = this.props.dispatch;
      dispatch(actionRouteRefresh());
    }
    // step 1
    this.setState({ rate_pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  render() {
    let stars = this.props.stars;
    let my_stars = this.props.my_stars;

    let cl_star = 'star-rate ';
    let cl_star_0 = "star-rating-stars-parent average-rating";
    let cl_star_2 = cl_star;
    let cl_star_4 = cl_star;
    let cl_star_6 = cl_star;
    let cl_star_8 = cl_star;
    let cl_star_10 = cl_star;
    // "star-rating-stars-parent rate-here ' + cl_my_rated_0 + '"
    // INFO: rate-now was class addrd by jquery in jquery version of app.
    let cl_my_star_0 = "star-rating-stars-parent rate-here rate-now";
    let cl_my_star_2 = cl_star;
    let cl_my_star_4 = cl_star;
    let cl_my_star_6 = cl_star;
    let cl_my_star_8 = cl_star;
    let cl_my_star_10 = cl_star;
    if (stars == null) {
      // cl_star_0 = cl_star + 'rated';
      cl_star_0 = "star-rating-stars-parent average-rating rated";
    } else if (stars == "") {
      // cl_star_0 = cl_star + 'rated';
      cl_star_0 = "star-rating-stars-parent average-rating rated";
    } else if (stars == 0) {
      cl_star_0 = cl_star + 'rated';
    } else if (stars == 1) {
      cl_star_2 = cl_star + 'rated';
    } else if (stars == 2) {
      cl_star_2 = cl_star + 'rated';
    } else if (stars == 3) {
      cl_star_4 = cl_star + 'rated';
    } else if (stars == 4) {
      cl_star_4 = cl_star + 'rated';
    } else if (stars == 5) {
      cl_star_6 = cl_star + 'rated';
    } else if (stars == 6) {
      cl_star_6 = cl_star + 'rated';
    } else if (stars == 7) {
      cl_star_8 = cl_star + 'rated';
    } else if (stars == 8) {
      cl_star_8 = cl_star + 'rated';
    } else if (stars == 9) {
      cl_star_10 = cl_star + 'rated';
    } else if (stars == 10) {
      cl_star_10 = cl_star + 'rated';
    }
    if (my_stars == null) {
      // cl_my_rated_0 = 'rated';
      cl_my_star_0 = "star-rating-stars-parent rate-here rate-now rated";
    } else if (my_stars == "") {
      // cl_my_rated_0 = 'rated';
      cl_my_star_0 = "star-rating-stars-parent rate-here rate-now rated";
    } else if (my_stars == 0) {
      // cl_my_rated_0 = 'rated';
      cl_my_star_0 = "star-rating-stars-parent rate-here rate-now rated";
    } else if (my_stars == 1) {
      // cl_my_rated_2 = 'rated';
      cl_my_star_2 = cl_star + 'star-rate-2 rated';
    } else if (my_stars == 2) {
      cl_my_star_2 = cl_star + 'star-rate-2 rated';
    } else if (my_stars == 3) {
      cl_my_star_4 = cl_star + 'star-rate-4 rated';
    } else if (my_stars == 4) {
      cl_my_star_4 = cl_star + 'star-rate-4 rated';
    } else if (my_stars == 5) {
      cl_my_star_6 = cl_star + 'star-rate-6 rated';
    } else if (my_stars == 6) {
      cl_my_star_6 = cl_star + 'star-rate-6 rated';
    } else if (my_stars == 7) {
      cl_my_star_8 = cl_star + 'star-rate-8 rated';
    } else if (my_stars == 8) {
      cl_my_star_8 = cl_star + 'star-rate-8 rated';
    } else if (my_stars == 9) {
      cl_my_star_10 = cl_star + 'star-rate-10 rated';
    } else if (my_stars == 10) {
      cl_my_star_10 = cl_star + 'star-rate-10 rated';
    }
    let cl_stars_parent = cl_star_0;
    let cl_my_stars_parent = cl_my_star_0;
    var template_stars = (
      <div data-star-value="2" className={cl_star_2}>
        <div data-star-value="4" className={cl_star_4}>
          <div data-star-value="6" className={cl_star_6}>
            <div data-star-value="8" className={cl_star_8}>
              <div data-star-value="10" className={cl_star_10}>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
    var template_stars_my = (
      <div data-star-value="2" className={cl_my_star_2} onClick={this._handleClick('RATE', 2)}>
        <div data-star-value="4" className={cl_my_star_4} onClick={this._handleClick('RATE', 4)}>
          <div data-star-value="6" className={cl_my_star_6} onClick={this._handleClick('RATE', 6)}>
            <div data-star-value="8" className={cl_my_star_8} onClick={this._handleClick('RATE', 8)}>
              <div data-star-value="10" className={cl_my_star_10} onClick={this._handleClick('RATE', 10)}>
              </div>
            </div>
          </div>
        </div>
      </div>
    );

    if (this.state.rate_pending === true) {
      template_stars_my = (
        <div style={{ paddingLeft: 20 }}>
          <SpinnerSmall />
        </div >
      );
    }

    let jsx_stars_panel = (
      <div className="star-rating-panel" data-post-id="' + post_id + '" data-url-id="' + url_id + '">
        {/*<!--<span>Rate</span>-->*/}
        <div className="stars-row-space-global">
          <div className="rate-here-msg">Rating:</div>
          <div className={cl_stars_parent}>
            {template_stars}
          </div>
        </div>
        <div className="stars-row-space-my">
          <div className="rate-here-msg">Your Rating:</div>
          <div className={cl_my_stars_parent}>
            {template_stars_my}
          </div>
        </div>
      </div>
    );

    return (
      <>
        {jsx_stars_panel}
      </>
    );
  }
}


