
/* 
FrFr FRIENDLY FRAMEWORK
browser_utila
Version 0a 2019-07-06
by 
// Please describe here if you customized this file
*/


export let browser_utils = {};


browser_utils.browser_title_set = function (title) {
  if (document.title !== title) {
    // update if different
    document.title = title;
  }
};


browser_utils.browser_url_set = function (relative_url) {
  // update browser url and history
  console.log("browser_utils.browser_url_set(relative_url) " + relative_url);
  // https://developer.mozilla.org/en-US/docs/Web/API/History_API
  //
  // window . history . pushState(data, title [, url ] )
  // window.history.pushState({url: relative}, "spa", relative);
  // title is the second parameter which can be a string, but at the time of writing, every browser simply ignores it.
  // All browsers currently disregard the title attribute passed to the pushState() and replaceState() methods. Any updates to the page title need to be done manually.
  // https://blog.twitter.com/engineering/en_us/a/2012/implementing-pushstate-for-twittercom.html

  console.log("     _________ READ STATE window.history");
  console.log(window.history);
  if (window.history.state !== null) {
    if (window.history.state.url !== relative_url) {
      // update if different
      window.history.pushState({ url: relative_url }, null, relative_url);
    } else {
      // skip if no difference
      console.log("new URL is the same. Skip pushState");
    }
  } else {
    window.history.pushState({ url: relative_url }, null, relative_url);
  }
  // why "relative_url" https://www.w3.org/TR/WD-html40-970917/htmlweb.html#h-5.1.2
};


browser_utils.browser_refresh_forced = function () {
  // Reload the current page from the server, ignoring the browser cache.
  window.location.reload(true);
  // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
};


browser_utils.browser_go_url = function (url) {
  // go relative or absolute url
  console.log('browser_utils.browser_go_url() ' + url);
  // window.location.href='/logout';
  window.location.href = url;
};


browser_utils.browser_go_url_relative = function (rel_url) {
  // just in case you are not sure if you can use relative url
  // go relative or absolute url
  console.log('browser_utils.browser_go_url_relative() ' + rel_url);
  // window.location.href='/logout';
  window.location.href = rel_url;
};


browser_utils.browser_get_url_path = function () {
  //
  let url_path = window.location.pathname;
  return url_path;

  // https://tecadmin.net/get-current-url-web-browser-using-javascript/
  // https://www.w3docs.com/snippets/javascript/how-to-get-current-url-in-javascript.html
  // window.location
	/*
	
    protocol - the name of protocol used to access the resource on the Internet. (HTTP, HTTPS, FTP, etc)
    hostname - the host that owns the resource.
    port - a port number used to recognize a specific process to which an Internet or other network message is to be forwarded when it arrives at a server (most HTTP URLs omit the port number).
    pathname - the specific resource in the host that the web client wants to access.
    query - provides a string of information that the resource can utilize for some purpose (for example, as parameters for a search or as data to be processed).
    hash - the anchor portion of a URL, includes the hash sign (#).

Use the following Location object properties to access the entire URL or its components.

    window.location.href - to get the entire URL
    window.location.host - to get the hostname and port of the URL
    window.location.hostname - to get the hostname of the URL
    window.location.protocol - to get the protocol of the URL in address bar
    window.location.pathname- to get the path and filename of the current page
    window.location.search - to get the query portion of the URL
    window.location.hash- to get the anchor portion of the URL

	*/
};

