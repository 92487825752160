import React, { Component } from 'react';
import { actionMultimodalOpen } from '../actions/frfr-multimodal-actions.js';
import SpinnerSmall from './SpinnerSmall.js';
import { api_lib } from '../utils/url-lib.js';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';

export default class ContextMenu extends Component {
  constructor(props) {
    super(props);
    // binding this
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.cmenuRef = React.createRef(); // ref radi detekcije klikova izvan menija
    this._handleClick = this._handleClick.bind(this);
  }



  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'CREATE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED CREATE');
        // dispatch(actionMultimodalCreateFolder(second));
        dispatch(actionMultimodalOpen('MODAL_FOLDER_CREATE', second));
      }
    }
    if (what === 'RENAME') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED RENAME');
        // dispatch(actionMultimodalRenameFolder(second));
        dispatch(actionMultimodalOpen('MODAL_FOLDER_RENAME', second));
      }
    }
    if (what === 'DELETE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED DELETE');
        // dispatch(actionMultimodalDeleteFolder(second));
        dispatch(actionMultimodalOpen('MODAL_FOLDER_DELETE', second));
      }
    }
  }

  /*
  _post = (fetch_url, data, callback) => {
    // this.setState({ ...this.state, pending: true });
    this.setState({ pending: true });
    mini_fetch_post(fetch_url, data)
      .then(response => response.json())
      .then(result => {
        // this.setState({ data: result, pending: false });
        console.log('MINI POST SUCCESSFUL');
        console.log(result);
        if (callback) {
          callback(result);
        }
      })
      .catch(e => {
        console.log('mini fetch post CATCH ERROR - e: ');
        console.log(e);
      });
  };
  */

  /*
_fetch = (fetch_url) => {
  this.setState({ ...this.state, pending: true });
  mini_fetch_get(fetch_url)
    .then(response => response.json())
    .then(result => {
      this.setState({ data: result, pending: false });
    })
    .catch(e => {
      console.log('mini fetch CATCH ERROR - e: ');
      console.log(e);
    });
};
*/

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(e) {
    console.log('* * * CLICKED while context menu opened * * *');
    let dom_el = this.cmenuRef.current; // DOM ELEMENT
    console.log(dom_el);
    if (dom_el && !dom_el.contains(e.target)) {
      console.log('CLICKED OUTSIDE - reference DOM element not contain target');
      let dispatch = this.props.dispatch;
      dispatch({
        type: 'GUI_FOLDER_CONTEXT_MENU_CLOSE'
      });
    }
  }



  render() {
    console.log('render() ContextMenu');
    console.log(this.props);
    let context_menu = this.props.appstate.context_menu;
    let fid = context_menu.fid;
    // let folder_level = context_menu.folder_level;
    let folder_name = context_menu.folder_name;
    let postop = context_menu.postop; // Y
    let posleft = context_menu.posleft; // X
    let css_style = {
      top: postop,
      left: posleft,
      display: 'block'
    };

    // actionMultimodalOpenInput

    return (
      <>
        <div className="putcut-folder-context-menu" style={css_style} ref={this.cmenuRef}>
          <div className="cmoption" data-context-action="new" title="Create new folder" onClick={this._handleClick('CREATE', { fid: fid })}>New folder</div>
          <hr />
          <div className="cmoption" data-context-action="rename" title="Rename the folder" onClick={this._handleClick('RENAME', { fid: fid, folder_name: folder_name })}>Rename</div>
          <div className="cmoption" data-context-action="delete" title="Delete the folder" onClick={this._handleClick('DELETE', { fid: fid })}>Delete {fid}</div>
        </div>
      </>
    );
  }
}

// putcut.template.nav_my_account_group_case_notlogged = function() {
