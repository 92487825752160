import React, { Component } from 'react';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';

// route components
import RoutePutcutCheats from './RoutePutcutCheats';
import RouteTest from './RouteTest';
import RouteTestPosts from './RouteTestPosts'; // ???

import RouteNotAvailable from './RouteNotAvailable';
import RouteInitialRerouteContainer from './RouteInitialRerouteContainer';
import RouteActivities from './RouteActivities';
import RouteRssFeeds from './RouteRssFeeds';
import RouteUser from './RouteUser';
import RouteSearch from './RouteSearch';
import RouteFolders from './RouteFolders';
import RouteBin from './RouteBin';
import RoutePostsSingle from './RoutePostsSingle';


/*
import RouteWelcome from './RouteWelcome';
import RouteHome from './RouteHome';
import RouteHelp from './RouteHelp';
*/

export default class RouterMainContent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let appstate = this.props.appstate;
    let dispatch = this.props.dispatch;
    let route_name = appstate.route_current.route_name;
    let route_params = appstate.route_current.route_params;
    if (typeof route_params !== 'object') {
      route_params = {};
    }
    let my_user_id = appstate.my_user_id;


    // let route_name = appstate.route_simple; // SIMPLE


    let browser_url;
    let main_content = 'PLACEHOLDER content of main section of page';
    if (route_name === 'HOME') {
      // default home is ACTIVITIES
      main_content = <RouteActivities appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'TEST') {
      main_content = <RouteTest appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'CHEATS_ROUTE') {
      main_content = <RoutePutcutCheats appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'ROUTEPLACEHOLDER') {
      // ROUTE PLACEHOLDER ROUTEPLACEHOLDER
      main_content = <RouteNotAvailable dispatch={dispatch} />
    } else if (route_name === 'INITIAL') {
      main_content = <RouteInitialRerouteContainer appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'HELP') {
    } else if (route_name === 'WELCOME') {
    } else if (route_name === 'ACTIVITIES') {
      /*
      browser_url = url_lib.activities(my_user_id);
      browser_utils.browser_url_set(browser_url);
      */
      main_content = <RouteActivities appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'FEEDS') {
      /*
      browser_url = url_lib.feeds();
      browser_utils.browser_url_set(browser_url);
      */
      main_content = <RouteRssFeeds appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'USER') {
      main_content = <RouteUser appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'SEARCH') {
      main_content = <RouteSearch appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'FOLDERS') {
      main_content = <RouteFolders appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'BIN') {
      main_content = <RouteBin appstate={appstate} dispatch={dispatch} />
    } else if (route_name === 'POSTS') {
      main_content = <RouteTestPosts appstate={appstate} dispatch={dispatch} />
    } else if (route_name === '++POSTS_**') {
      //
    } else if (route_name === 'POSTS_SINGLE') {
      main_content = <RoutePostsSingle appstate={appstate} dispatch={dispatch} />
    } else {
      // main_content = <RouteTest appstate={appstate} dispatch={dispatch} />
      main_content = <RouteNotAvailable dispatch={dispatch} />
    }

    return (
      <>
        {main_content}
      </>
    );
  }
}
