import React, { Component } from 'react';
import PopupMenuBrain from '../containers/PopupMenuBrain';
import { actionNewRoute } from '../actions/actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';

export default class NavMainMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._callbackClose = this._callbackClose.bind(this);

    this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
  }

  _callbackClose() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened: false
    });
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE') {
      return (e) => {
        e.stopPropagation();
        console.log('TOGGLE');
        if (this.state.opened === true) {
          this.setState({
            opened: false
          });
        } else {
          this.setState({
            opened: true
          });
        }
      }
    }

    if (what === 'REFRESH') {
      return (e) => {
        e.preventDefault();
        console.log('REFRESH');
        browser_utils.browser_refresh_forced();
      }
    }
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second));
      }
    }
    if (what === 'OPEN_URL') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // BROWSER OPEN URL
        let rel_url = second;
        browser_utils.browser_go_url_relative(rel_url);
      }
    }
  }

  render() {
    let href_about = url_lib.about();
    let href_help = url_lib.help();

    let jsx_case_notlogged = (
      <div className="nav-my-account-group">
        <a className="guest-login-button" data-user-id="" href={process.env.PUBLIC_URL + "/login"}>Log in</a>
      </div>
    );

    /*
                  <a className="dropdown-option click-to-help" href={href_help} onClick={this._handleClick('ROUTE', 'ROUTEPLACEHOLDER')}>Help</a>
    */

    let jsx_menu = '';
    if (this.state.opened === true) {
      jsx_menu = (
        <PopupMenuBrain callback={this._callbackClose} parent_ref={this.parentRef}>
          <div className="nav-main-menu-panel dropdown dropdown-top-right" style={{ display: 'block' }}>
            <div className="dropdown-body">
              <div className="dropdown-arrow arrow-top"></div>
              <a className="dropdown-option click-to-help" href={href_help} onClick={this._handleClick('OPEN_URL', href_help)}>Help</a>
              <a className="dropdown-option click-to-refresh" href="#" onClick={this._handleClick('REFRESH')}>Reload</a>
              <div className="dropdown-option-separator"></div>
              <a className="dropdown-option click-to-about" href={href_about} onClick={this._handleClick('OPEN_URL', href_about)}>About...</a>
            </div>
          </div>
        </ PopupMenuBrain>
      );
    }


    return (
      <div className="click-to-nav-main-menu-panel" title="Main menu" ref={this.parentRef}>
        <div className="icon" onClick={this._handleClick('TOGGLE')}></div>
        {jsx_menu}
      </div>
    );
  }
}


