import { universal_action_getmethod_pending_fetched } from './actions-helper';
import { test_a, resolve_after_ms, ajax_simulator, universal_fetch_get, universal_fetch_post } from '../utils/ajax.js';
import { api_lib } from '../utils/url-lib.js';
import { id_utils } from '../utils/frfr-freshness-counter.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';

// REDUX ACTIONS, ACTION CREATORS, THUNKS...

export let actionNewRoute = function (target, params) {
  // thunk
  return function thunk(dispatch) {
    console.log('dispatching new route... ', target, params);
    let freshness = id_utils.get_fresh_number();
    dispatch({
      type: 'ROUTE_NEW',
      payload: {
        target,
        freshness,
        route_params: params
      }
    });

  };
};


export let actionRouteRefresh = function () {
  // thunk
  return function thunk(dispatch) {
    console.log('dispatching route REFRESH... ');
    let freshness = id_utils.get_fresh_number();
    dispatch({
      type: 'ROUTE_REFRESH',
      payload: {
        freshness
      }
    });
  };
};


export let actionCheatsEnable = function () {
  // thunk
  return function thunk(dispatch) {
    dispatch({
      type: 'CHEATS_ENABLE'
    });
  };
};


export let actionBrowserReloadForced = function () {
  // thunk
  return function thunk(dispatch) {
    dispatch({
      type: 'INFO_BROWSER_WILL_REFRESH_FORCED'
    });
    browser_utils.browser_refresh_forced();
  };
};



export let actionFoldersMyFetch = function () {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'FOLDERS_MY_FETCHING'
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.folders_my_get();
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'FOLDERS_MY_FETCHED',
      payload: response
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionFoldersTheirFetch = function (move, user_id) {
  let key = user_id;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'FOLDERS_PUBLIC_FETCHING',
      meta: {
        move: move,
        key: key
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.folders_public_get(user_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'FOLDERS_PUBLIC_FETCHED',
      payload: response,
      meta: {
        move: move,
        key: key
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionActivitiesFetch = function () {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'ACTIVITIES_FETCHING'
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.activities_get();
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'ACTIVITIES_FETCHED',
      payload: response
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionActivitiesFetchMore = function (after) {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'ACTIVITIES_MORE_FETCHING'
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.activities_get(after);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'ACTIVITIES_MORE_FETCHED',
      payload: response
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionFeedsItemsFetch = function () {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'FEEDS_FETCHING'
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.feeds_items_get();
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'FEEDS_FETCHED',
      payload: response
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionUserFetch = function (move, user_id) {
  let key = user_id;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'USER_FETCHING',
      meta: {
        move: move,
        key: key
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.user_profile_get(user_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'USER_FETCHED',
      payload: response,
      meta: {
        move: move,
        key: key
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionUserFetchMy = function (move, user_id) {
  let key = user_id;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'USER_MY_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.user_my_profile_get();
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'USER_MY_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionPostsMyFolderFetch = function (move, fid) {
  let key = fid;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'POSTS_MY_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.posts_my_get(fid);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'POSTS_MY_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionPostsRecycleBinFetch = function (move) {
  let key = 'bin';
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'BIN_MY_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.posts_recyclebin_get();
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'BIN_MY_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionPostsTheirFolderFetch = function (move, fid, user_id) {
  let key = fid;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'POSTS_PUBLIC_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.posts_public_get(fid, user_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'POSTS_PUBLIC_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionPostsSingleFetch = function (move, post_id) {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'POSTS_SINGLE_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.posts_single_get(post_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'POSTS_SINGLE_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionSocialFollowingFetch = function (move, user_id) {
  let key = user_id;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'SOCIAL_FOLLOWING_FETCHING',
      meta: {
        move: move,
        key: key
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.social_following_get(user_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'SOCIAL_FOLLOWING_FETCHED',
      payload: response,
      meta: {
        move: move,
        key, key
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionSocialFollowersFetch = function (move, user_id) {
  let key = user_id;
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'SOCIAL_FOLLOWERS_FETCHING',
      meta: {
        move: move,
        key: key
      }
    };
  };
  // step 2 - fetch
  let fetch_url = api_lib.social_followers_get(user_id);
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'SOCIAL_FOLLOWERS_FETCHED',
      payload: response,
      meta: {
        move: move,
        key, key
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};



export let actionSearch_proba = function (move, section, query) {
  // step 1
  let f_pending = () => {
    // ACTION CREATOR Action creators are exactly that—functions that create actions. It's easy to conflate the terms “action” and “action creator”
    return {
      type: 'SEARCH_FETCHING',
      meta: {
        move: move
      }
    };
  };
  // step 2 - fetch
  let page;
  let per_page;
  let fetch_url = api_lib.search_universal_get(section, query, page, per_page)
  // step 3 will be when step 2 finished and make callback
  let f_fetched = (response) => {
    // ACTION CREATOR
    return {
      type: 'SEARCH_FETCHED',
      payload: response,
      meta: {
        move: move
      }
    };
  };
  // must return thunk like any other
  return universal_action_getmethod_pending_fetched(f_pending, fetch_url, f_fetched);
};




