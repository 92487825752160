import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import UniversalFollowMultibuttonContainer from '../containers/UniversalFollowMultibuttonContainer';


export default class PeopleItem extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {

    let item = this.props.item;
    let props = item;
    console.log('**** SEARCH ITEM');
    console.log(item);
    /*
    avatar_url: null
    birthday_year: "1979"
    confirmed: "1"
    confirmed_standard: "1"
    country: ""
    display_name: "Dragana Poznan"
    first_name: "Dragana"
    last_name: "Poznan"
    occupations: ""
    stats: {following: 1, followers: 2, saved_links: 35}
    time_registered_2: "March 19th, 2017. 15:51"
    user_id: "_761a713"
    */

    let user_id = item.user_id;
    let my_user_id = this.props.appstate.my_user_id;
    let jsx_follow_multibutton = (
      <UniversalFollowMultibuttonContainer user_id={user_id} my_user_id={my_user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />
    );
    if (user_id === my_user_id) {
      jsx_follow_multibutton = null;
    }

    return (
      <>

        <div className="search-item">
          <div className="people-results-item">
            <div className="people-results-item-panel">
              <div className="author-group">
                <div className="avatar">
                  <a className="spa-anchor-user" data-user-id="' + props.user.user_id + '" href="' + props.user.temp_url_user + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img src={props.avatar_url} /></a>
                </div>
                <div className="balloon">

                  <div className="username-group-mini clearfix">
                    <div className="username"><h3 className="nickname"><a className="spa-anchor-user" data-user-id="' + props.user.user_id + '" href="' + props.user.temp_url_user + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.display_name}</a></h3></div>

                    {jsx_follow_multibutton}

                  </div>

                  <div className="timing-info date-user-register">Member from <span>{props.time_registered_2}</span></div>
                  <div className="search-social-numbers"><span>{props.stats.following}</span> Following / <span>{props.stats.followers}</span> followers / <span>{props.stats.saved_links}</span> Saved links</div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </>
    );
  }
}


