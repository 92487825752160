import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch, actionMultimodalComingSoon } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';
import { youtube_utils } from '../utils/youtube-utils.js';

import ModalCustomJSX from './ModalCustomJSX';
// import { cc } from '../utils/jqui-custom-youtube2.js';

// TEMP VERSION WITH CLASSIC SUBMIT FORM

export default class ModalTestLarge extends Component {
  constructor(props) {
    super(props);
    this._global = {};
    this._handleSubmit = this._handleSubmit.bind(this);
    this._initial_play = this._initial_play.bind(this);
    this._cb_bookmark_time = this._cb_bookmark_time.bind(this);
    this._cb_bookmark_cut = this._cb_bookmark_cut.bind(this);
    //
    // Create the refs
    this.youtubeidRef = React.createRef();
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'PREVENT') {
      return (e) => {
        e.preventDefault();
      }
    }
    if (what === 'LOAD') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        //
        let youtubeid = this.youtubeidRef.current.value;
        console.log(youtubeid);
        // let $ = window.jquery_for_react;
        let cc = window.cc
        console.log("SUMBMIT...+++");
        // cc.state.yt_video_id = youtubeid; // store in state
        // console.log(cc.state);
        // cc.do_embed_player_manual(youtubeid);
        // cc.load_player_by_api(); // NOVO
        cc.external_play_when_ready(youtubeid); // NOVO EXTERNAL CALL
        // console.log(cc.state);
      }
    }
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'COMING_SOON') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalComingSoon());
      }
    }
  }

  _handleSubmit(e) {
    e.preventDefault();
    //
  }

  _cb_bookmark_time(data) {
    //
    let dispatch = this.props.dispatch;
    console.log('_cb_bookmark_time() ');
    console.log(data);
    dispatch(actionMultimodalComingSoon());
  }
  _cb_bookmark_cut(data) {
    //
    let dispatch = this.props.dispatch;
    console.log('_cb_bookmark_cut() ');
    console.log(data);
    dispatch(actionMultimodalComingSoon());
  }

  _initial_play() {
    console.log('>>>>>>>>>> INITIAL PLAY *****');
    let cc = window.cc;
    if (typeof cc.yt_player !== 'undefined' && typeof cc.yt_player.loadVideoById === 'function') {
      // we need timer stopped
      window.clearInterval(this._global.timer_id);
    } else {
      // we need timer running
    }
    // cc.external_play_when_ready(this._global.youtubeid); // NOVO EXTERNAL CALL
    cc.external_init_and_play(this._global.youtubeid, this._cb_bookmark_time, this._cb_bookmark_cut); //
    cc.init_jquery_btn();
  }

  componentDidMount() {
    console.log('componentDidMount() LARGE IS MOUNTED');
    //
    let youtubeid = '';
    let modal_data = this.props.modal_data;
    if (modal_data && typeof modal_data.url === 'string') {
      let url = modal_data.url;
      this._global.youtubeid = youtube_utils.extract_ytid_from_url(url);
      console.log('YouTube ID EXTRACTED: ', youtubeid);
    } else {
      console.log('ERROR YouTube ID EXTRACTING NOT SUCCESSFUL: ', youtubeid);
    }

    let cc = window.cc;
    console.log(cc);
    cc.init_jquery_btn();


    // this._initial_play();
    // cc.state.yt_video_id = youtubeid; // store in state
    // cc.load_player_by_api(); // NOVO
    // cc.external_play_when_ready(); // NOVO EXTERNAL CALL
    // this._global.timer_id = window.setInterval(this._initial_play, 900);
    this._global.timer_id = window.setTimeout(this._initial_play, 200);

  }

  render() {

    /*
      tiny giants trailer https://www.youtube.com/watch?v=4Sb2DdG5A5o
      jurassic park fallen https://www.youtube.com/watch?v=vn9mMeWcgoM
    */
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let youtubeid = '';
    if (modal_data && typeof modal_data.url === 'string') {
      let url = modal_data.url;
      youtubeid = youtube_utils.extract_ytid_from_url(url);
      console.log('YouTube ID EXTRACTED: ', youtubeid);
    } else {
      console.log('ERROR YouTube ID EXTRACTING NOT SUCCESSFUL: ', youtubeid);
    }

    let dispatch_close_modal = this.props.dispatch_close_modal;

    // let callback_submit = this._cb_submit_upload;




    let modal_args_temp = {
      title: 'TEST MODAL THEATER',
      message: 'test test test:'
    };

    let jsx_error = null;

    let jsx_custom = (
      <>
        <>

          <div className="test-top-bar">
            <div className="modal-close" onClick={dispatch_close_modal}>×</div>
            <h3>{modal_args_temp.title}</h3>
          </div>

          <div className="test-fixed-scroll-msg">
            <div className="">Scroll down to see buttons</div>
          </div>

          <div className="player-theater-row player-theater-container">
            <div className="test-player-placeholder">
              <div id="player_parent" className="parent-za-embed-player transformer clearfix">
                {/*<!--player will render here-->*/}
                <div id="youtube_player_overwrite"></div>
              </div>
            </div>
          </div>


          <div className="test-form-limiter">

            <div className="flex-bar gaps">

              <form id="ytform" onSubmit={this._handleSubmit}>
                <label className="option" htmlFor="youtubeid">new YT ID:</label>
                <input
                  type="text"
                  name="youtubeid"
                  className="polje1"
                  defaultValue="4Sb2DdG5A5o"
                  ref={this.youtubeidRef}
                />
              </form>

              <div onClick={this._handleClick('LOAD')} className="xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-ar suf-169-43" href="">TEST LOAD ID</div>

              <form id="timeform">
                <div className="flex-bar gaps">
                  <label className="xi option" htmlFor="starttime">A:</label>
                  <input
                    type="text"
                    name="starttime"
                    className="xi polje2 view-ab-time numbers polje-readonly"
                    maxlength="9"
                    defaultValue=""
                    readOnly
                  />
                  <label className="xi option" htmlFor="endtime">B:</label>
                  <input
                    type="text"
                    name="endtime"
                    className="xi polje2 view-ab-time numbers polje-readonly"
                    maxlength="9"
                    defaultValue=""
                    readOnly
                  />
                </div>
              </form>

            </div>


            <div className="clearfix"></div>

            <div className="flex-bar bar-style-1 left gaps">

              <div className=" xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-ar suf-169-43" href="">16:9 u 4:3</div>
              <div className=" xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-ar suf-43-169" href="">4:3 u 16:9</div>
              <div className=" xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-ar suf-to-original" href="">vrati original AR</div>
              {/*<!--<a className="btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-reload_manualy" href="">reload iframe old way</a>-->*/}
              <div className=" xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-reload_by_api" href="">load/reload player using api</div>
              <div className=" xi btn button-style-light-1 text-style-blue-1 btn-size-1 click-to-get-info" href="">info</div>

              <div className="xi loop-checkbox-group btn-checkbox btnch">
                <input name="config_loop_enabled" id="loop_checkbox_enabled" className="form-checkbox" type="checkbox" />
                <label className="option" htmlFor="config_loop_enabled">Loop <span className="mark-a">A</span>-<span className="mark-b">B</span></label>
              </div>

            </div>


            <div className="flex-bar bar-style-1 center gaps">
              <p className=" xi">Current time: </p>
              <input
                type="text"
                name="realtime"
                id="real_time"
                className=" xi numbers polje-readonly text-right"
                value="0"
                readOnly
              />
              <div className=" xi btn btn-size-1 click-to-take-a">preuzmi u <span className="mark-a">A</span></div>
              <div className=" xi btn btn-size-1 click-to-take-b">preuzmi u <span className="mark-b">B</span></div>
            </div>


            <div className="clearfix"></div>

            <div className="fix-slider-range-canvas">
              <div id="slider-range"></div>
            </div>

            <div className="clearfix"></div>

            <div className="flex-bar center gaps">
              <div className=" xi btn btn-size-1 click-to-bookmark-time" href="" onClick={this._handleClick('PREVENT')}>BOOKMARK VIDEO TIME</div>
              <div className=" xi btn btn-size-1 click-to-bookmark-cut" href="" onClick={this._handleClick('PREVENt')}>BOOKMARK VIDEO CUT (A-B)</div>
            </div>

          </div>





          {jsx_error}
        </>
      </>
    );

    let modal_args = {
      custom_content_jsx: jsx_custom
    };

    return (
      <>
        <ModalCustomJSX modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
