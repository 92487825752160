import React, { Component } from 'react';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

export default class RouteTest extends Component {

  render() {
    return (
      <>
        <div className="main">
          <h1>RouteTest</h1>
          <SpinnerSmall />
          <SpinnerNormal />
          <SpinnerLarge />
        </div>
      </>
    );
  }
}
