
import { browser_utils } from '../utils/frfr-browser-utils.js';
import { store } from '../index.js' // NOVO ZA DISPATCH IZVAN KOMPONENTI
import { actionNewRoute } from '../actions/actions.js';


export let on_url_change_offline = function (state_url) {
  console.log('on_url_change_offline(state_url) ', state_url);
  // putcut_routing.by_url(state_url);
  putcut_routing.reroute_this_window_url();
}


export let putcut_routing = {};


let is_this_userid_my = function (userid) {
  return true;
}



/*
function spa_get_url_path_from_browser() {
  console.log("spa_get_url_path_from_browser() ");
  // ALIAS ZA NOVU
  
  // window.location.pathname
  let rel_url = window.location.pathname;
  console.log(window.location);
  console.log(window.location.pathname);
  return rel_url;
}
*/


putcut_routing.reroute_this_window_url = function () {
  console.log("putcut_routing.reroute_this_window_url() ");
  let rel_url_path_only = browser_utils.browser_get_url_path();
  putcut_routing.by_url(rel_url_path_only);
};



putcut_routing.by_url = function (state_url) {
  console.log("putcut_routing.BY_URL(state_url) " + state_url);
  
  // MK 
  if(state_url.substring(0, 8) == '/putcut/') {
	  state_url = state_url.replace('/putcut/', '/')
  }
  // END MK 
  
  var temp_parsed_arr = state_url.split("/");
  console.log(temp_parsed_arr);
  putcut_routing.by_url_from_arr(temp_parsed_arr);
};





// TODO: dodati i search route...

putcut_routing.by_url_from_arr = function (arr) {
  console.log("putcut_routing.BY_URL_from_arr(arr) arr= " + arr);
  // function spa_on_url_change_offline(arr) { // SPA OFFLINE PARSER FOR URL CHANGE
  var error = false;
  var error_message = '';
  var route = '';
  let target = '';
  let route_params = {};

  console.log('TEST 1');

  // var temp_my_user_id = putcut.store.my_user_id_get(); // changed with putcut.store.is_this_userid_my(userid)
  if (arr[0] == "") {
    arr = arr.slice(1);
    if (arr[0] == "") {
      // return;
      console.log('TEST 2');
    }
  }

  console.log('TEST 2');
  console.log(arr);
  console.log(arr.length);

  // CASES
  if (arr[0] == "") {
    // CASE arr 0
    if (arr.length === 1) {
      route = {
        target: 'HOME'
      };
      target = 'HOME';
    } else {
      error = true;
      error_message = 'ERROR: first item is ""';
    }
  } else if (arr[0] == "login") {
    // CASE arr 0
    route = {
      target: 'HOME'
    };
    target = 'HOME';
  } else if (arr[0] == "feeds") {
    // CASE arr 0
    // spa_open_feeds();
    route = {
      target: 'feeds'
    };
    target = 'FEEDS';
  } else if (arr[0] == "recyclebin") {
    // CASE arr 0
    route = {
      target: 'recyclebin',
      fid: 'bin'
    };
    // target = 'RECYCLEBIN'; // TODO
    target = 'BIN'; // TODO
    route_params = {
      fid: 'bin'
    };
  } else if (arr[0] == "user") {
    // CASE arr 0
    var userid = arr[1];
    if (arr[2] == "following") {
      route = {
        target: 'userprofile_following',
        userid: userid
      };
      target = 'USER';
      route_params = {
        user_id: userid,
        section: 'FOLLOWING'
      };
    } else if (arr[2] == "followers") {
      route = {
        target: 'userprofile_followers',
        userid: userid
      };
      target = 'USER';
      route_params = {
        user_id: userid,
        section: 'FOLLOWERS'
      };
    } else {
      route = {
        target: 'userprofile',
        userid: userid
      };
      target = 'USER';
      route_params = {
        user_id: userid
      };
    }
  } else if (arr[0] == "settings") {
    // CASE arr 0
    if (arr[1] == "profile") {
      route = {
        target: 'userprofile_edit'
      };
      target = 'USER'; // TODO
      route_params = {
        user_id: userid,
        section: 'PROFILE_EDIT'
      };
    }
  } else if (arr[0] == "blog") {
    // CASE arr 0
    var slug = arr[1];
    if (typeof slug == "undefined") {
      // if undefined
    } else {
			/*
			route = {route: 'blog'};
			this.general('blog');
			*/
      // spa_open_blogpost(slug);
      route = {
        target: 'blog',
        slug: slug
      };
      target = 'BLOG';
      route_params = {
        slug: slug
      };
    }
  } else if (arr[0] == "help") {
    // CASE arr 0
    var slug = arr[1];
    if (typeof slug == "undefined") {
      // if undefined
      var slug = "home"; // default slug for help root
    }
    // spa_open_helppost(slug);
    route = {
      target: 'help',
      slug: slug
    };
    target = 'HELP';
    route_params = {
      slug: slug
    };
  } else if (arr[1] == "activities") {
    // CASE arr 0
    var userid = arr[0];
    // treba proveriti da li je to postojeci user id pre "/activities" ???
    // spa_open_activities();
    // putcut.func.open_activities();
    route = {
      target: 'activities',
      userid: userid
    };
    target = 'ACTIVITIES';
    route_params = {
      user_id: userid
    };
  } else if (arr[1] == "posts") {
    // CASE arr 0
    var userid = arr[0];
    var pid = arr[2];
    route = {
      target: 'posts',
      userid: userid,
      pid: pid
    };
    target = 'POSTS_SINGLE';
    route_params = {
      user_id: userid,
      post_id: pid
    };
  } else if (arr[1] == "folders") {
    // CASE arr 0
    var userid = arr[0];
    var fid = arr[2];
    console.log(fid);
    console.log("fid TYPEOF: ");
    console.log(typeof fid);
    if (typeof fid == "undefined") {
      // if undefined
      fid = "0"; // IMPORTANT! not number 0 but string "0", IMPORTANT!
      if (is_this_userid_my(userid)) {
        // MY
        // spa_user_set(userid); // my
        route = {
          target: 'folders',
          userid: userid,
          fid: fid
        };
        target = 'FOLDERS';
        route_params = {
          user_id: userid,
          fid: fid
        };
      }
    } else {
      if (is_this_userid_my(userid)) {
        // MY
        // spa_user_set(userid); // my
        route = {
          target: 'folders',
          userid: userid,
          fid: fid
        };
        target = 'FOLDERS';
        route_params = {
          user_id: userid,
          fid: fid
        };
      } else {
        // THEIR
        // spa_user_set(userid); // ++OLD
        route = {
          target: 'folders',
          userid: userid,
          fid: fid
        };
        target = 'FOLDERS';
        route_params = {
          user_id: userid,
          fid: fid
        };
      }
    }
  } else {
    // CASE arr 0 OTHER...
    // NO CASE MATCHED
    console.log("WARNING!!! NO CASE MATCHED");

    // CHECK FOR SEARCH...
    console.log("...SEARCH case match checking...");
    let search_url_detected = false;
    let maybe_search_url = arr[0];
    console.log(maybe_search_url);
    if (maybe_search_url.substr(0, 6) == "search") {
      console.log("TEST s 1");
      // ima sanse
      // let url_part_path = browser_utils.browser_get_url_path();
      let url_part_path = window.location.pathname;
      if (url_part_path.substr(1) == "search") {
        console.log("TEST s 2");
        // jeste search
        let url_part_search = new URLSearchParams(window.location.search);
        let q = url_part_search.get("q");
        if (q !== null && q !== "") {
          console.log("TEST s 3 A");
          search_url_detected = true;
          target = 'SEARCH';
          route_params = {
            search_query: q
          };
        } else {
          console.log("TEST s 3 B");
          search_url_detected = true;
          target = 'SEARCH';
          route_params = {
            search_query: ''
          };
        }
        // TODO smisliti kako da se i search section prenese... ???
      }
    }
    console.log("TEST s 4");

    if (search_url_detected === true) {

    } else {
      console.log("WARNING!!! NO SERACH CASE MATCHED");
      console.log("we wil doo notavailable route");
      route = {
        target: 'notavailable'
      };
      target = 'NOTAVAILABLE'; // TODO
    }
  }

  if (error === true) {
    console.log(error_message);
  }
  // this.general(route); // pass to routing general
  console.log('**** ROUTING BY URL DONE');
  console.log(route);
  putcut_routing.dispatch_route(target, route_params)
};



putcut_routing.dispatch_route = function (target, route_params) {
  store.dispatch(actionNewRoute(target, route_params));
};



// EXCUTE ROUTE
/*
putcut_routing.execute_route = function(route) {

	// STEP set target
	var fresh_move = putcut.func.counter.get_unique_id();
	var temp_my_user_id = putcut.store.my_user_id_get(); // ******

	//  exceptions and adaptations
	if (route.target == 'activities') {
		route.userid = temp_my_user_id;
	} else if (route.target == 'search' || route.target == 'searchmyfolders') {
		route.userid = temp_my_user_id;
		var modified_route = this.adapt_search_route(route);
		route = modified_route;
	} else if (route.target == 'folders' || route.target == 'recyclebin') {
		var modified_route = this.adapt_folders_route(route);
		route = modified_route;
	} else if ( route.target == "userprofile" || route.target == "userprofile_edit" || route.target == "userprofile_following" || route.target == "userprofile_followers"  ) {
		var modified_route = this.adapt_user_route(route);
		route = modified_route;
	} else if ( route.target == "notavailable" ) {
		var modified_route = this.adapt_notavailable_route(route);
		route = modified_route;
	}


	var temp_user_id = temp_my_user_id;
	if ('userid' in route) {
		console.log('property userid is in route object');
		temp_user_id = route.userid;
	}

	var target = {
		move: fresh_move,
		route_move: fresh_move,
		route: route,
		user_id : temp_user_id,
		readme: ''
	};
	console.log('EXECUTE ROUTE FINISH - target: ');
	console.log(target);
	putcut.store.target_set(fresh_move, target); // STEP update store data

	// EMIT EVENT NEED RENDER
	putcut.dispatcher.dispatch({
		type: "NEW_TARGET_SET",
		meta: {
			move: fresh_move
		},
		payload: {
			route_move: fresh_move
		}
	});
	// NEXT STEPS...
	// fetching_activities_more(fresh_move); // fetch promised data

};
*/








