import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import TreeItemsFolders from './TreeItemsFolders';
import TreeLiFolderLock from './TreeLiFolderLock';
import { runInThisContext } from 'vm';
import { url_lib } from '../utils/url-lib.js';

import { jqui_drop_init, jqui_drop_destroy } from '../utils/jqui-dragdrop.js'; // DRAG DROP

export default class TreeLiFolder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
    this._handleClick = this._handleClick.bind(this);
    this._handleContextMenu = this._handleContextMenu.bind(this);
    this._move_link = this._move_link.bind(this); // DRAG DROP

    // this._callbackClose = this._callbackClose.bind(this);

    this.folderRef = React.createRef(); // ref radi detekcije klikova za right click context menu
    this.dropRef = React.createRef(); // DRAG DROP
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE') {
      return (e) => {
        e.stopPropagation();
        console.log('TOGGLE');
        if (this.state.expanded === true) {
          this.setState({
            expanded: false
          });
        } else {
          this.setState({
            expanded: true
          });
        }
      }
    }

    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  _handleContextMenu = (e) => {
    e.preventDefault();
    console.log('CONTEXT MENU *****', e.clientX, e.clientY);
    console.log(e);
    let postop = e.pageY;
    let posleft = e.pageX;
    let folder = this.props.folder;
    let fid = folder.id;
    let folder_level = folder.level;
    let folder_name = folder.title;
    let dispatch = this.props.dispatch;
    dispatch({
      type: 'GUI_FOLDER_CONTEXT_MENU_OPEN',
      payload: {
        type: 'FOLDER',
        posleft: posleft,
        postop: postop,
        fid: fid,
        folder_level: folder_level,
        folder_name: folder_name
      }
    });

    /*
    <div class="putcut-folder-context-menu" style="top: 370px; left: 489px; display: block;">		<div class="cmoption" data-context-action="new" title="Create new folder">New folder</div>		<hr>		<div class="cmoption" data-context-action="rename" title="Rename the folder">Rename</div>		<div class="cmoption" data-context-action="delete" title="Delete the folder">Delete</div>	</div>
*/


    /*
    this.setState({ visible: true });
    
    const clickX = event.clientX;
    const clickY = event.clientY;
    const screenW = window.innerWidth;
    const screenH = window.innerHeight;
    const rootW = this.root.offsetWidth;
    const rootH = this.root.offsetHeight;
    
    const right = (screenW - clickX) > rootW;
    const left = !right;
    const top = (screenH - clickY) > rootH;
    const bottom = !top;
    
    if (right) {
        this.root.style.left = `${clickX + 5}px`;
    }
    
    if (left) {
        this.root.style.left = `${clickX - rootW - 5}px`;
    }
    
    if (top) {
        this.root.style.top = `${clickY + 5}px`;
    }
    
    if (bottom) {
        this.root.style.top = `${clickY - rootH - 5}px`;
    }
    */
  };


  _move_link() {
    /*
    let dispatch = this.props.dispatch;
    dispatch({
      type: 'GUI_FOLDER_CONTEXT_MENU_OPEN',
      payload: {
        type: 'FOLDER',
        posleft: posleft,
        postop: postop,
        fid: fid,
        folder_level: folder_level,
        folder_name: folder_name
      }
    });

    dispatch(actionMultimodalMoveLinkToFolder(second));
    let callback = () => {
      // let dispatch = this.props.dispatch;
      // dispatch(actionFoldersMyFetch());
      this.props.dispatch_close_modal();
    }
    this._post_delete_folder(fetch_url, outbox, callback);
    */
  }

  componentDidMount() {
    let my = this.props.my;
    if (my === true) {
      let dom_el = this.folderRef.current;
      if (dom_el) {
        let fid = this.props.folder.id;
        if (fid !== "bin") {
          // NOT FOR RECYCLE BIN
          dom_el.addEventListener('contextmenu', this._handleContextMenu);
        }
        // INIT DRAG DROP
        let cb_move = this._move_link;
        jqui_drop_init(dom_el, cb_move); // DRAG DROP
      }
    }
  };

  componentWillUnmount() {
    // REMOVE DRAG DROP LISTENERS
    let my = this.props.my;
    if (my === true) {
      let dom_el = this.folderRef.current;
      if (dom_el) {
        jqui_drop_destroy(dom_el); // DRAG DROP
      }
    }
  };

  /*
  tiny giants trailer https://www.youtube.com/watch?v=4Sb2DdG5A5o
  jurassic park fallen https://www.youtube.com/watch?v=vn9mMeWcgoM
  */

  render() {
    let active_item = this.props.active_item;
    let active_item_fid = this.props.active_item_fid;

    let has_children = false;
    if (typeof this.props.folders !== 'undefined' && Array.isArray(this.props.folders) && this.props.folders.length > 0) {
      has_children = true;
    }

    let props = this.props.folder;
    let folder = this.props.folder;
    let fid = folder.id;
    let user_id = folder.user_id;


    let href_folder = url_lib.folder(user_id, fid);
    let href_desktop = url_lib.folder_desktop(user_id);
    let href_bin = url_lib.recyclebin();

    let locked = false;
    if (folder.private == '1') {
      locked = true;
    } else {
      locked = false;
    }

    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    let cl_lock;
    if (locked === true) {
      var is_private = 'true';
      var is_private_class = 'lock-case-locked';
      var item_is_private_class = 'case-is-private';
      cl_lock = 'lock lock-case-locked';
    } else {
      var is_private = 'false';
      var is_private_class = 'lock-case-unlocked';
      var item_is_private_class = 'case-is-public';
      cl_lock = 'lock lock-case-unlocked';
    }

    let cl_expanded = ' ';
    let cl_ul_children = 'placeholder-class-children-parent';
    if (this.state.expanded) {
      cl_expanded = ' is-expanded';
    } else {
      cl_expanded = ' is-collapsed';
      cl_ul_children = 'collapsed';
    }
    let cl_ul_children_desktop = 'folders-actually-go-here ' + cl_ul_children;

    let cl_active = ' ';
    if (active_item === "tree-item" && active_item_fid == fid) {
      cl_active = ' active';
    }

    let jsx_children = null;
    let cl_has_children = ' ';
    if (has_children) {
      jsx_children = (
        <TreeItemsFolders folders={this.props.folders} my={this.props.my} active_item={this.props.active_item} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />
      );
      cl_has_children = ' has-children';
    }

    let cl_wrap = null;
    if (has_children) {
      // cl_wrap = 'parent';
      cl_wrap = 'parent' + cl_expanded; // NOVIJE
    }
    let cl_wrap_desktop = 'not-spinner-li ' + cl_wrap;

    let cl_item = 'item truefolder tree-item ' + item_is_private_class + cl_has_children + cl_expanded + cl_active;
    let cl_item_desktop = 'item desktop-item systemfolder ' + cl_has_children + cl_expanded + cl_active;
    // item desktop-item systemfolder  is-expanded ui-droppable
    let cl_item_bin = 'item recyclebin systemfolder tree-item ' + cl_active;

    // let jsx = null;
    let jsx = (
      <li className={cl_wrap}>
        <div
          className={cl_item}
          data-folder-id={fid}
          data-user-id={user_id}
          data-folder-title="' + props.folder.title + '"
          data-is-folder="true"
          data-level="' + level + '"
          data-private="' + props.folder.temp_is_private + '"
          data-view-stlye=""
          ref={this.folderRef}
        ><a className="putcut-folder-anchor" href={href_folder} onClick={this._handleClick('ROUTE', 'FOLDERS', { fid: fid, user_id: user_id })}><div className="icon icon-folder"></div>{props.title}</a><div className="expand" onClick={this._handleClick('TOGGLE')}><img src={process.env.PUBLIC_URL + "/static/img/expand.png"} /></div><TreeLiFolderLock folder={this.props.folder} /></div>
        {jsx_children}
      </li>
    );
    // className="tplpar tparfid-' + props.folder.id + '"
    /*
        <ul data-folder-id="' + props.folder.id + '" className={cl_ul_children}>
          {jsx_children}
        </ul>
    */
    /*
    let jsx_desktop_original = (
      <li className="not-spinner-li">
        <div className="item desktop-item systemfolder" data-folder-id="0" data-user-id="' + props.temp_desktop_user_id + '" data-is-folder="false" data-level="0" data-view-stlye="icons"><a className="folderlink" href="' + props.temp_desktop_url + '"><div className="icon"><img src="/static/img/desktop.png" /></div><span>Desktop</span></a><div className="expand"><img src="/static/img/expand.png" /></div></div>
        <ul className="folders-actually-go-here">
          {jsx_children}
        </ul>
      </li>
    );
    */
    let type = this.props.type;
    if (type === 'DESKTOP') {
      console.log('***** DESKTOP ITEM');
      console.log(this.props);
      jsx = (
        <li className={cl_wrap_desktop}>
          <div
            className={cl_item_desktop}
            data-folder-id={fid}
            data-user-id={user_id}
            data-folder-title="' + props.folder.title + '"
            data-is-folder="false"
            data-level="0"
            data-view-stlye="icons"
            ref={this.folderRef}
          ><a className="folderlink" href={href_desktop} onClick={this._handleClick('ROUTE', 'FOLDERS', { fid: fid, user_id: user_id })}><div className="icon img"><img src={process.env.PUBLIC_URL + "/static/img/desktop.png"} /></div><span>Desktop</span></a><div className="expand" onClick={this._handleClick('TOGGLE')}><img src={process.env.PUBLIC_URL + "/static/img/expand.png"} /></div></div>

          <div className="folders-actually-go-here">
            {jsx_children}
          </div>
        </li>
      );
    } else if (fid == 'bin') {
      console.log('***** RECYCLE BIN ITEM');
      console.log(this.props);
      jsx = (
        <li className={cl_wrap_desktop}>
          <div
            className={cl_item_bin}
            data-folder-id="bin"
            data-user-id="' + props.temp_desktop_user_id + '"
            data-is-folder="false"
            data-level="0"
            data-view-stlye="icons"
            ref={this.folderRef}
          ><a className="folderlink" href={href_bin} onClick={this._handleClick('ROUTE', 'BIN', { fid: 'bin', user_id: user_id })}><div className="icon icon-recycle-bin"></div><span>Recycle Bin</span></a></div>
        </li>
      );
    }


    /*
          <ul className={cl_ul_children_desktop}>
            {jsx_children}
          </ul>
    */


    return (
      <>

        {jsx}

      </>
    );
  }
}

// putcut.template.nav_my_account_group_case_notlogged = function() {
