import React, { Component } from 'react';
import { actionAuthAutoLogin } from '../actions/auth-actions.js';
import AppSplashScreen from '../components/AppSplashScreen';

// START AUTOLOGIN PROCEDURE AND MOUNT PRESENTATIONAL INTRO SPLASH SCRREN OR SPINNERS...

export default class InitAutoLogin extends Component {
  constructor(props) {
    super(props);
    console.log('constructor InitAutoLogin');
    console.log(this.props);
  }

  componentDidMount() {
    // START INITIAL AUTOLOGIN PROCEDURE
    //
    let dispatch = this.props.dispatch;
    /*
    let auth = this.props.appstate.auth;
    if (auth.need_initial_autologin === true) {
      dispatch(actionAuthAutoLogin());
    }
    */
    dispatch(actionAuthAutoLogin());
  }

  render() {
    // RENDER SPLASH SCREEN PRESENTATION DURING INITIAL AUTOLOGIN PROCEDURE
    return (
      <>
        <AppSplashScreen />
      </>
    );
  }
}
