import { universal_fetch_get, universal_fetch_post } from '../utils/ajax.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from './frfr-multimodal-actions.js';



export let universal_action_getmethod_pending_fetched = function (f_pending, fetch_url, f_fetched, callback) {
  // EKSPERIMENTALNO
  return function thunk(dispatch) {
    // step 1
    dispatch(f_pending());

    // step 2
    universal_fetch_get(fetch_url, 'fetching...')
      .then(response => {
        // log original response (before extract json)
        console.log('+++ then prvo USPELO FETCH');
        console.log(response);
        return response;
      })
      .then(response => response.json())
      .then(response => {
        console.log(response);
        return response;
      })
      .then(response => {
        console.log('last then');
        // step 3 will be when step 2 finished and make callback
        if (response.success) {
          dispatch(f_fetched(response));
          if (callback) {
            callback();
          }
        } else {
          dispatch({ type: '++NOT_SUCCESS_THEN_RESPONSE', payload: response });
          if (response.error === true && typeof response.frfr_erf !== 'undefined') {
            let frfr_error_response = response;
            dispatch(actionMultimodalOpen_frfr_erf(frfr_error_response));
          }
        }
        //
      })
      .catch(function (error) {
        console.log('error od ()');
        console.log(error);
        dispatch(actionMultimodalOpen_catch(error, 'universal_fetch_get'));
        /*
        Possible Unhandled Promise Rejection (id: 0):
        TypeError: Cannot read property 'json' of undefined
        */
      });
    // step 3 will be when step 2 finished and make callback

  };
};


