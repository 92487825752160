import React, { Component } from 'react';
import { mini_fetch_get, mini_fetch_post } from '../utils/ajax.js';
import { actionNewRoute, actionPostsMyFolderFetch, actionPostsTheirFolderFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
// import { url_lib, api_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';



export default class ModalCustomJSX extends Component {
  constructor(props) {
    super(props);
    console.log('consttructor ModalCustomJSX');
    console.log(this.props);
    // let initial_value = '';
    if (this.props.modal_args.value) {
      // initial_value = this.props.modal_args.value;
    }
    this.state = {
      error: false,
      error_message: '',
      pending: true,
      scraped_data: {},
      title: 'i'
    };
    // binfing this
    this.handleChange = this.handleChange.bind(this);
    this._handleClick = this._handleClick.bind(this);
    // CLONED***
    // this._cb_refresh = this._cb_refresh.bind(this);
    // this._cb_follow = this._cb_follow.bind(this);
    // this._cb_unfollow = this._cb_unfollow.bind(this); // bez bindovanja bude Cannot read property 'props' of undefined
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  _handleClick(what, second, third) {
    // let dispatch = this.props.dispatch;
    if (what === 'SCRAPE_AGAIN') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        //
      }
    }
  }

  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
  };

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    /*
    if (this.state.putyourcutmodal !== prevState.putyourcutmodal) {
      // RESCRAPE
      this._scrape();
    } else {
      console.log('...SKIP SCRAPING');
    }
    */
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    /*
    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let user_id = route_params.user_id;
    */


    let my = true;

    // console.log(table_arr);


    if (this.state.pending === false) {
      pending = false;
    }

    let modal_data = this.props.modal_data;
    console.log(modal_data);


    // DATA IS READY
    // ----------------
    // PRESENTATION JSX

    //--------------------------------***********************

    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    /*
    let title = modal_args.title;
    let message = modal_args.message;

    let confirm_btn_title = 'Ok';
    if (modal_args.confirm_btn_title) {
      confirm_btn_title = modal_args.confirm_btn_title;
    }

    let cancel_btn_title = 'Cancel';
    if (modal_args.cancel_btn_title) {
      cancel_btn_title = modal_args.cancel_btn_title;
    }

    let callback_cancel = dispatch_close_modal;
    if (modal_args.callback_cancel) {
      callback_cancel = modal_args.callback_cancel;
    }
    */

    let custom_content_jsx = modal_args.custom_content_jsx;

    return (
      <>
        <div className="modal-item-content readme-custom-jsx-inside">
          {custom_content_jsx}
        </div>
      </>
    );
  }
}
