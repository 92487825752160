import React, { Component } from 'react';
// import React from 'react';
import ReactDOM from 'react-dom';

// These two containers are siblings in the DOM
// const appRoot = document.getElementById('app-root');
const modalRoot = document.getElementById('modal-root');


// https://reactjs.org/docs/portals.html
export default class MultiModalPortal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement('div');
    //
    // this.el = <div className="test3"></div> // ako staviumo ovo dobijemo gresku: Target container is not a DOM element zato sto se DOM metodama klasa ne dodaje u ovoj komandi niti na taj nacin.
  }

  componentDidMount() {
    // The portal element is inserted in the DOM tree after
    // the Modal's children are mounted, meaning that children
    // will be mounted on a detached DOM node. If a child
    // component requires to be attached to the DOM tree
    // immediately when mounted, for example to measure a
    // DOM node, or uses 'autoFocus' in a descendant, add
    // state to Modal and only render the children when Modal
    // is inserted in the DOM tree.
    
    modalRoot.appendChild(this.el); // moze i bez ovoga direktno da se renderuje u modalRoot koji je uvek mountovan

    // NOVO
    // document.documentElement // HTML tag DOM
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/documentElement
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/classList

    document.documentElement.classList.add('multimodal-opened', 'modal-opened');
    document.body.classList.add('multimodal-opened');


  }

  componentWillUnmount() {
    modalRoot.removeChild(this.el);

    // NOVO
    document.documentElement.classList.remove('multimodal-opened', 'modal-opened');
    document.body.classList.remove('multimodal-opened');
  }

  render() {
    let children = this.props.children;
    // let content = null; // moguce je i da komponenta ne iskoristi this.props.children nigde

    return ReactDOM.createPortal(
      children,
      this.el,
    );
  }
}

/*
  render() {
    return ReactDOM.createPortal(
      this.props.children,
      this.el,
    );
  }

  render() {
    return ReactDOM.createPortal(
      this.props.children,
      modalRoot,
    );
  }
*/
