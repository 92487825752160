import React, { Component } from 'react';
import { actionActivitiesFetch, actionActivitiesFetchMore } from '../actions/actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import SpinnerMain from './SpinnerMain';
import ActivitiesItemWrapper from './ActivitiesItemWrapper';

export default class RouteActivities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _callbackClose() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened: false
    });
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'LOAD_MORE') {
      return (e) => {
        e.stopPropagation();
        console.log('LOAD MORE ' + second);
        dispatch(actionActivitiesFetchMore(second)); //
      }
    }

    if (what === 'ROUTE') {
      return () => {
        console.log('CLICKED');
        // dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    dispatch(actionActivitiesFetch());
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let pending_more = false;
    let zero = true;
    let table_arr = [];

    let last_id = 0;

    let my_user_id = this.props.appstate.my_user_id;

    let raw_data = this.props.appstate.data_activities;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.activities_items;
    }
    if (raw_data.pending_more === true) {
      pending_more = true;
    } else {
      pending_more = false;
    }

    console.log(table_arr);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let browser_url = url_lib.activities(my_user_id);
    browser_utils.browser_url_set(browser_url);
    let page_title = 'PutCut - My Activity Log';
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_load_more_btn = null;
    let jsx_arr = null; //
    // if (pending === true) {
    if (pending || pending_more) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    if (pending === false) {

      // ---------
      // JSX
      /*
      action: "publish-post"
      data: {post_id: "_e61baef", post_item: {…}}
      hidden: "0"
      id: "1857"
      time: "1544221960"
      time_formatted: "December 7th, 2018"
      user1: "_18734e2"
      user1_item: {confirmed: "1", first_name: "Henry", last_name: "Bessemer", confirmed_standard: "1", birthday_year: "1980", …}
      user2: ""
      user2_item: false
      via: "mweb"
      via_ver: "1.0"
      */

      let fltered_arr = table_arr.filter((item, index) => {
        if (item.action === "unfollow-user") {
          return false;
        }
        return true;
      });

      jsx_arr = fltered_arr.map((item, index) => {
        zero = false;
        last_id = item.id;
        // <div>{item.action}</div>
        return (
          <ActivitiesItemWrapper key={item.id} item={item} dispatch={this.props.dispatch} />
        );
      });

      jsx_load_more_btn = (
        <div class="load-more button-style-3" data-putcut-move="UNKNOWN HERE" data-after="1821" onClick={this._handleClick('LOAD_MORE', last_id)}>Load more</div>
      );

      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }

    } // ens of pending === false

    /*
    jsx_spinner = (
      <SpinnerMain />
    );
    */

    return (
      <>
        <div class="social-activities-container JS-MADE">
          <div class="ajax-container actual-activities-ajax activities-list social-activity-list" data-rendered-last-id="1821">

            {jsx_arr}
            {jsx_zero_items}
            {jsx_load_more_btn}
            {jsx_spinner}

          </div>
        </div>
      </>
    );
  }
}

//




