import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';

import PopupMenuBrain from '../containers/PopupMenuBrain';
import { url_lib, api_lib } from '../utils/url-lib.js';
import StarRatingPanel from './StarRatingPanel';

export default class PostsItemNestedLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened_sharing_panel: false,
      opened_stars_panel: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._callbackCloseSharing = this._callbackCloseSharing.bind(this);
    this._callbackCloseStars = this._callbackCloseStars.bind(this);
  }

  _callbackCloseSharing() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened_sharing_panel: false
    });
  }

  _callbackCloseStars() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened_stars_panel: false
    });
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'TOGGLE_SHARING') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE_SHARING');
        if (this.state.opened_sharing_panel === true) {
          this.setState({
            opened_sharing_panel: false
          });
        } else {
          this.setState({
            opened_sharing_panel: true
          });
        }
      }
    }
    if (what === 'TOGGLE_STARS') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE_STARS');
        if (this.state.opened_sharing_panel === true) {
          this.setState({
            opened_stars_panel: false
          });
        } else {
          this.setState({
            opened_stars_panel: true
          });
        }
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {

    let item = this.props.item;
    let props = {
      link: this.props.item
    }
    console.log('**** POSTS ITEM');
    console.log(props.link);
    /*
    click_count: 0
    comment_count: 0
    content: ""
    date: "March 18th, 2017 21:57:39"
    deleted: "0"
    description: "Find and save recipes, parenting hacks, style inspiration and other ideas to try."
    description_he: "Find and save recipes, parenting hacks, style inspiration and other ideas to try."
    domain: "pinterest.com"
    favicon: "gfs://pinterest.com"
    favicon_resource: "https://webservices-v4.putcut.com/api/v2/cache/favicon/get?service=gfs&domain=pinterest.com"
    folder_id: "0"
    has_rss_subscription: false
    in_dropzone: "0"
    in_recycle_bin: "0"
    is_private: false
    my_stars: 0
    pictures: ["https://s-media-cache-ak0.pinimg.com/custom_covers/200x150/444941706874670035_1439510773.jpg"]
    post_id: "_1fe78af"
    post_type: "link"
    saved_count: 0
    selected_picture_bestresource: "https://webservices-v4.putcut.com/api/v2/image?url=https%3A%2F%2Fs-media-cache-ak0.pinimg.com%2Fcustom_covers%2F200x150%2F444941706874670035_1439510773.jpg&best=1"
    selected_picture_id: "0"
    selected_picture_resource: "https://webservices-v4.putcut.com/api/v2/image?url=https%3A%2F%2Fs-media-cache-ak0.pinimg.com%2Fcustom_covers%2F200x150%2F444941706874670035_1439510773.jpg&size=160"
    selected_picture_url: "https://s-media-cache-ak0.pinimg.com/custom_covers/200x150/444941706874670035_1439510773.jpg"
    share_url: "https://putcut.com/_5f4f8d3/posts/_1fe78af"
    share_url_uue: "https%3A%2F%2Fputcut.com%2F_5f4f8d3%2Fposts%2F_1fe78af"
    stars: 0
    tags: ""
    time: "1489870659"
    time_recycled: "0"
    title: "1000+ images about Soylent Green on Pinterest | Press photo, Soylent green movie and Nostalgia"
    title_he: "1000+ images about Soylent Green on Pinterest | Press photo, Soylent green movie and Nostalgia"
    url: "https://www.pinterest.com/amahoro1014/soylent-green/"
    url_id: "585"
    url_instance: {id: "585", url: "https://www.pinterest.com/amahoro1014/soylent-green/", favicon_url: "gfs://pinterest.com"}
    url_open: "https://webservices-v4.putcut.com/api/v2/openurl?url=https%3A%2F%2Fwww.pinterest.com%2Famahoro1014%2Fsoylent-green%2F"
    user: {confirmed: "1", first_name: "linkz", last_name: "", confirmed_standard: "1", birthday_year: "", …}
    user_id: "_5f4f8d3"
    */

    let user_id = item.user_id;
    let post_id = item.post_id;
    let url_id = item.url_id;
    let stars = item.stars;
    let my_stars = item.my_stars;

    let the_link_href = api_lib.openurl_url(item.url);
    let user_href = url_lib.user(user_id);

    let comment_count = props.link.comment_count;


    // SHARING BUTTON
    // let sharing_url = 'https://putcut.com/' + user_id + '/posts/' + post_id; // TODO
    let sharing_url = url_lib.sharing_post_single_url(user_id, post_id);
    let sharing_twitter_url = url_lib.sharing_twitter_url(sharing_url);
    let sharing_facebook_url = url_lib.sharing_facebook_url(sharing_url);
    let sharing_email_url = url_lib.sharing_email_url(sharing_url);
    let jsx_sharing_panel = '';
    if (this.state.opened_sharing_panel === true) {
      jsx_sharing_panel = (
        <PopupMenuBrain callback={this._callbackCloseSharing}>
          <div className="share-panel">
            <h4>Share</h4>
            <input className="share-post-url" type="text" title="Share link" value={sharing_url} onClick={(e) => { e.target.focus(); e.target.select() }} readOnly />
            <div className="clearfix"></div>
            <div className="share-icons">
              <div className="share-icon share-facebook">
                <a target="_blank" href={sharing_facebook_url}><img src={process.env.PUBLIC_URL + "/static/img/social-facebook.png"} /></a>
              </div>
              <div className="share-icon share-twitter">
                <a target="_blank" href={sharing_twitter_url}><img src={process.env.PUBLIC_URL + "/static/img/social-twitter.png"} /></a>
              </div>
              <div className="share-icon share-email">
                <a href={sharing_email_url}><img src={process.env.PUBLIC_URL + "/static/img/social-email.png"} /></a>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </PopupMenuBrain>
      );
    }

    let sharing_html = (
      <div className="share-2"><a className="spa-anchor-disabled" href={sharing_url}><img className="click-to-sharing" src={process.env.PUBLIC_URL + "/static/img/share.png"} onClick={this._handleClick('TOGGLE_SHARING')} /></a>
        {jsx_sharing_panel}
      </div>
    );


    // RATING BUTTON
    let jsx_stars_panel = '';
    if (this.state.opened_stars_panel === true) {
      jsx_stars_panel = (
        <PopupMenuBrain callback={this._callbackCloseStars}>
          <StarRatingPanel stars={stars} my_stars={my_stars} post_id={post_id} uel_id={url_id} dispatch={this.props.dispatch} />
        </PopupMenuBrain>
      );
    }
    let rating_html = (
      <div className="star">
        <img className="click-to-rating star-indicator-icon" src={process.env.PUBLIC_URL + "/static/img/star.png"} onClick={this._handleClick('TOGGLE_STARS')} />
        {jsx_stars_panel}
      </div>
    );



    let jsx_temp = '';
    let jsx_side_buttons = (
      <div className="side-buttons-group">
        {sharing_html}
        {rating_html}
        {/*<!-- CASE IS NOT EDITABLE. NO MORE SIDE BUTTONS-->*/}
      </div>
    ); // NOT EDITABLE


    return (
      <>

        <div data-url="' + props.link.url + '" className="link nested-link">
          <div className="nested-link-panel">

            {jsx_side_buttons}

            <div className="link-content">
              <div className="thumb-group" data-selected-picture-id="' + props.link.selected_picture_id + '">
                <img className="thumbnail" src={props.link.selected_picture_bestresource} />
              </div>
              <div className="link-info">
                <h2 className="title"><a rel="nofollow" target="_blank" title="' + props.link.url + '" href={the_link_href}>{props.link.title}</a></h2>
                <div className="domain"><img className="favicon" src="' + props.link.temp_favicon_url + '" /><a rel="nofollow" target="_blank" title="' + props.link.domain + '" href="http://' + props.link.domain + '/" >{props.link.domain}</a></div>
                <div className="description">{props.link.description}</div>
              </div>
            </div>
            <div className="link-stats-group">
              <div className="">{props.link.click_count} views</div>
              <div className="not-show-comments">Comments (<span>{comment_count}</span>)</div>
              <div className="">{props.link.saved_count} saved</div>
            </div>
          </div>
        </div>

      </>
    );
  }
}


