import React, { Component } from 'react';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

import { actionNewRoute } from '../actions/actions.js';
import { actionMultimodalOpen, actionMultimodalComingSoon, actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch, actionMultimodalOpen_frfr_success, actionMultimodalOpen_success } from '../actions/frfr-multimodal-actions.js';
import { mini_ajax_post } from '../utils/ajax.js';

import { frfr_examples } from '../utils/frfr-examples.js';

export default class RoutePutcutCheats extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this._handleClick = this._handleClick.bind(this);
    this._error_simulator = this._error_simulator.bind(this);
    /*
    this._callbackCloseSharing = this._callbackCloseSharing.bind(this);
    this._callbackCloseStars = this._callbackCloseStars.bind(this);
    this._comments_get = this._comments_get.bind(this);
    this._handle_submit_comment = this._handle_submit_comment.bind(this);
    // this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
    // Create the refs
    this.commentRef = React.createRef();
    this.dragRef = React.createRef();
    */
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'REFRESH') {
      return () => {
        console.log('CLICKED REFRESH');
        // dispatch();
      }
    }
    if (what === 'COMING_SOON') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalComingSoon());
      }
    }
    if (what === 'CATCH') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        this._error_simulator();
      }
    }
    if (what === 'FRFR_ERF') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        //
        //frfr_error_response.error_message.title;
        /*
         title = content_data.frfr_error_response.error_message.title;
        | ^  105 | message = content_data.frfr_error_response.error_message.message;
        */
        /*
         let args = {
           title: 'abc',
           message: 'def',
           response: {},
           frfr_error_response: {
             error_message: {
               title: 'ghi',
               message: 'jkl'
             }
           }
         };
         */
        let ff_er = frfr_examples.frfr_erf_example;
        console.log('fr_er');
        dispatch(actionMultimodalOpen_frfr_erf(ff_er));
      }
    }
    if (what === 'FRFR_SUCCESS') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        let ff_su = frfr_examples.frfr_success_example;
        dispatch(actionMultimodalOpen_frfr_success(ff_su));
      }
    }
    if (what === 'SUCCESS') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // dispatch(actionMultimodalOpen_success(args));

      }
    }
    if (what === 'THEATER') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalOpen('MODAL_THEATER'));
      }
    }
  }

  _error_simulator() {
    // mini_ajax_post(fetch_url, outbox, callback, null, true, '');
    let fetch_url = 'notfound';
    let cb_error = (e) => {
      console.log('cb_error callback');
      console.error(e);
      let dispatch = this.props.dispatch;
      dispatch(actionMultimodalOpen_catch(e, 'cheats ajax error simualtor'));
    };
    mini_ajax_post(fetch_url, null, null, cb_error, true, '');
  }

  render() {
    return (
      <>
        <div className="main">
          <h1>cheats</h1>
          <SpinnerSmall />
          <SpinnerNormal />
          <SpinnerLarge />
          <h3>test modals:</h3>
          <div className="testme" onClick={this._handleClick('COMING_SOON')}>coming soon</div>
          <div className="testme" onClick={this._handleClick('COMING_SOON')}>coming soon</div>
          <div className="testme" onClick={this._handleClick('CATCH')}>ajax post error catch</div>
          <div className="testme" onClick={this._handleClick('FRFR_ERF')}>frfr_erf example</div>
          <div className="testme" onClick={this._handleClick('FRFR_SUCCESS')}>frfr_success example</div>
          <div className="testme" onClick={this._handleClick('SUCCESS')}>success</div>
          <div className="testme" onClick={this._handleClick('THEATER')}>LARGE THEATER FULLSCREEN</div>
        </div>
      </>
    );
  }
}
