import React, { Component } from 'react';
import { actionNewRoute, actionRouteRefresh } from '../actions/actions.js';
import { actionMultimodalOpen } from '../actions/frfr-multimodal-actions.js';

import PopupMenuBrain from '../containers/PopupMenuBrain';
import { url_lib, api_lib } from '../utils/url-lib.js';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import StarRatingPanel from './StarRatingPanel';
import CommentsItem from './CommentsItem';

import { mini_ajax_get, mini_ajax_post } from '../utils/ajax.js';

import { jqui_drag_init, jqui_drag_destroy } from '../utils/jqui-dragdrop.js'; // DRAG DROP

// Post LINK

export default class PostsItemLinkUniversal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened_sharing_panel: false,
      opened_stars_panel: false,
      opened_comments: false,
      comments_pending: true,
      comments_data: {}
    };
    this._handleClick = this._handleClick.bind(this);
    this._callbackCloseSharing = this._callbackCloseSharing.bind(this);
    this._callbackCloseStars = this._callbackCloseStars.bind(this);
    this._comments_get = this._comments_get.bind(this);
    this._handle_submit_comment = this._handle_submit_comment.bind(this);
    // this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
    // Create the refs
    this.commentRef = React.createRef();
    this.dragRef = React.createRef();
  }

  _callbackCloseSharing() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened_sharing_panel: false
    });
  }

  _callbackCloseStars() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened_stars_panel: false
    });
  }


  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE_SHARING') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE_SHARING');
        if (this.state.opened_sharing_panel === true) {
          this.setState({
            opened_sharing_panel: false
          });
        } else {
          this.setState({
            opened_sharing_panel: true
          });
        }
      }
    }
    if (what === 'TOGGLE_STARS') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE_STARS');
        if (this.state.opened_sharing_panel === true) {
          this.setState({
            opened_stars_panel: false
          });
        } else {
          this.setState({
            opened_stars_panel: true
          });
        }
      }
    }
    if (what === 'TOGGLE_COMMENTS') {
      return (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log('TOGGLE_COMMENTS');
        if (this.state.opened_comments === true) {
          this.setState({
            opened_comments: false
          });
        } else {
          this.setState({
            opened_comments: true
          });
        }
        this._comments_get();
      }
    }
    if (what === 'EDIT') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // OPEN LINK EDITOR
        let dispatch = this.props.dispatch;
        let editor_data = this.props.item;
        let user_id = editor_data.user_id;
        let post_id = editor_data.post_id;
        let fid = editor_data.folder_id;
        let url = editor_data.url;
        // dispatch(actionMultimodalEditLink(url, fid, user_id, post_id, editor_data));
        let modal_content_data = {
          mode: 'EDIT',
          url,
          fid,
          user_id,
          post_id,
          editor_data
        };
        dispatch(actionMultimodalOpen('MODAL_LINK_EDITOR', modal_content_data));
      }
    }
    if (what === 'RECYCLE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // RECYCLE LINK - MOVE TO RECYCLE BIN
        let editor_data = this.props.item;
        let post_id = editor_data.post_id;
        let fid = editor_data.folder_id;
        let temp_is_recycled = editor_data.in_recycle_bin;
        let dd = {
          drag_id: post_id,
          drag_id_parent: fid,
          drag_is_recycled: temp_is_recycled,
          drop_id: "bin"
        }
        dispatch(actionMultimodalOpen('MODAL_MOVE_LINK', { dd }));
      }
    }
    if (what === 'DELETE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED REAL DELETE');
        // REAL DELETE LINK
        let post_id = this.props.item.post_id;
        dispatch(actionMultimodalOpen('MODAL_POST_DELETE', { post_id: post_id }));
      }
    }
    if (what === 'RSS') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        // OPEN LINK EDITOR
        let url = second;
        // dispatch(actionMultimodalRssSubsAdd(url));
        dispatch(actionMultimodalOpen('MODAL_RSS_SUBS_ADD', { url: url }));
      }
    }
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'THEATER') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionMultimodalOpen('MODAL_THEATER', second));
      }
    }

  }

  _handle_submit_comment(e) {
    e.preventDefault();
    console.log('_handle_submit_comment(e) SUBMIT ');
    // let dispatch = this.props.dispatch;
    //
    let fetch_url = api_lib.comments_create();
    let input_value = '';
    if (this.commentRef) {
      console.log('*** truthy');
      let post_id = this.props.item.post_id; // post_id
      input_value = this.commentRef.current.value;
      input_value = input_value.trim();
      if (typeof input_value === 'string' && input_value !== '') {
        // {post: pid, comment: text}
        let outbox = {
          post: post_id,
          comment: input_value
        }; // data for post method
        console.log(outbox);
        let callback = () => {
          this._comments_get(); // REFRESH AFTER AJAX SUCCESS
          // CLEAR FIELD
          this.commentRef.current.value = '';
        }
        mini_ajax_post(fetch_url, outbox, callback, null, false, '');
      }
    } else {
      console.log('*** not truthy');
    }
  }

  _comments_get() {
    this.setState({
      comments_pending: true,
      comments_data: {}
    });
    let pid = this.props.item.post_id;
    let fetch_url = api_lib.comments_get(pid);
    let callback = (result) => {
      let comment_count = result.items_count;
      this.setState({
        comments_data: result,
        comment_count: comment_count,
        comments_pending: false
      });
    }
    mini_ajax_get(fetch_url, callback, null, true, '');
  };


  componentDidMount() {
    // INIT DRAG DROP
    let draggable = false;
    if (this.props.draggable === true) {
      draggable = true;
    }
    if (draggable === true) {
      let dom_el = this.dragRef.current;
      if (dom_el) {
        jqui_drag_init(dom_el); // DRAG DROP
      }
    }
  };

  componentWillUnmount() {
    // REMOVE DRAG DROP LISTENERS
    let draggable = false;
    if (this.props.draggable === true) {
      draggable = true;
    }
    if (draggable === true) {
      let dom_el = this.dragRef.current;
      if (dom_el) {
        jqui_drag_destroy(dom_el); // DRAG DROP
      }
    }
  };


  render() {
    let draggable = false;
    if (this.props.draggable === true) {
      draggable = true;
    }

    let editable = false;
    if (this.props.editable === true) {
      editable = true;
    }

    let recycled = false;
    if (this.props.recycled === true) {
      recycled = true;
    }

    let is_nested_link = false;
    if (this.props.type === 'NESTED') {
      is_nested_link = true;
    }

    let item = this.props.item;
    let props = {
      link: item
    };
    console.log('**** LINK ITEM');
    console.log(item);
    /*
    click_count: 0
    comment_count: 0
    content: ""
    date: "March 18th, 2017 21:57:39"
    deleted: "0"
    description: "Find and save recipes, parenting hacks, style inspiration and other ideas to try."
    description_he: "Find and save recipes, parenting hacks, style inspiration and other ideas to try."
    domain: "pinterest.com"
    favicon: "gfs://pinterest.com"
    favicon_resource: "https://webservices-v4.putcut.com/api/v2/cache/favicon/get?service=gfs&domain=pinterest.com"
    folder_id: "0"
    has_rss_subscription: false
    in_dropzone: "0"
    in_recycle_bin: "0"
    is_private: false
    my_stars: 0
    pictures: ["https://s-media-cache-ak0.pinimg.com/custom_covers/200x150/444941706874670035_1439510773.jpg"]
    post_id: "_1fe78af"
    post_type: "link"
    saved_count: 0
    selected_picture_bestresource: "https://webservices-v4.putcut.com/api/v2/image?url=https%3A%2F%2Fs-media-cache-ak0.pinimg.com%2Fcustom_covers%2F200x150%2F444941706874670035_1439510773.jpg&best=1"
    selected_picture_id: "0"
    selected_picture_resource: "https://webservices-v4.putcut.com/api/v2/image?url=https%3A%2F%2Fs-media-cache-ak0.pinimg.com%2Fcustom_covers%2F200x150%2F444941706874670035_1439510773.jpg&size=160"
    selected_picture_url: "https://s-media-cache-ak0.pinimg.com/custom_covers/200x150/444941706874670035_1439510773.jpg"
    share_url: "https://putcut.com/_5f4f8d3/posts/_1fe78af"
    share_url_uue: "https%3A%2F%2Fputcut.com%2F_5f4f8d3%2Fposts%2F_1fe78af"
    stars: 0
    tags: ""
    time: "1489870659"
    time_recycled: "0"
    title: "1000+ images about Soylent Green on Pinterest | Press photo, Soylent green movie and Nostalgia"
    title_he: "1000+ images about Soylent Green on Pinterest | Press photo, Soylent green movie and Nostalgia"
    url: "https://www.pinterest.com/amahoro1014/soylent-green/"
    url_id: "585"
    url_instance: {id: "585", url: "https://www.pinterest.com/amahoro1014/soylent-green/", favicon_url: "gfs://pinterest.com"}
    url_open: "https://webservices-v4.putcut.com/api/v2/openurl?url=https%3A%2F%2Fwww.pinterest.com%2Famahoro1014%2Fsoylent-green%2F"
    user: {confirmed: "1", first_name: "linkz", last_name: "", confirmed_standard: "1", birthday_year: "", …}
    user_id: "_5f4f8d3"
    */

    let is_youtube_video = false;
    if (typeof item.special_metadata !== 'undefined') {
      console.log('HAS SPECIAL METADATA');
      if (typeof item.special_metadata.youtube !== 'undefined') {
        console.log('IS YOUTUBE VIDEO');
        is_youtube_video = true;
      }
    }

    let user_id = item.user_id;
    let post_id = item.post_id;
    let url_id = item.url_id;
    let url = item.url;
    let stars = item.stars;
    let my_stars = item.my_stars;

    let fid = item.folder_id; // parent folder
    /*
    let in_recycle_bin = false;
    if (item.in_recycle_bin == "1") {
      // ???
      in_recycle_bin = true; 
    }
    */
    let in_recycle_bin = item.in_recycle_bin; // ???

    let href_the_link = api_lib.openurl_url(item.url);
    let href_domain_link = '//' + item.domain;
    let href_user = url_lib.user(user_id);

    let comment_count = item.comment_count;
    if (this.state.comment_count) {
      comment_count = this.state.comment_count;
    }


    let temp_rss_case_class = "multibutton-status-not";
    if (props.link.has_rss_subscription) {
      temp_rss_case_class = "multibutton-status-watching";
    }
    let rss_btn_cl = 'watch universal-watch-multibutton ' + temp_rss_case_class;
    /*
    <div className="watch universal-watch-multibutton {temp_rss_case_class}">
      <div className={rss_btn_cl}>
    </div>
    */


    // SHARING BUTTON
    // let sharing_url = 'https://putcut.com/' + user_id + '/posts/' + post_id; // TODO
    let sharing_url = url_lib.sharing_post_single_url(user_id, post_id);
    let sharing_twitter_url = url_lib.sharing_twitter_url(sharing_url);
    let sharing_facebook_url = url_lib.sharing_facebook_url(sharing_url);
    let sharing_email_url = url_lib.sharing_email_url(sharing_url);
    let jsx_sharing_panel = '';
    if (this.state.opened_sharing_panel === true) {
      jsx_sharing_panel = (
        <PopupMenuBrain callback={this._callbackCloseSharing}>
          <div className="share-panel">
            <h4>Share</h4>
            <input className="share-post-url" type="text" title="Share link" value={sharing_url} onClick={(e) => { e.target.focus(); e.target.select() }} readOnly />
            <div className="clearfix"></div>
            <div className="share-icons">
              <div className="share-icon share-facebook">
                <a target="_blank" href={sharing_facebook_url}><img src={process.env.PUBLIC_URL + "/static/img/social-facebook.png"} /></a>
              </div>
              <div className="share-icon share-twitter">
                <a target="_blank" href={sharing_twitter_url}><img src={process.env.PUBLIC_URL + "/static/img/social-twitter.png"} /></a>
              </div>
              <div className="share-icon share-email">
                <a href={sharing_email_url}><img src={process.env.PUBLIC_URL + "/static/img/social-email.png"} /></a>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </PopupMenuBrain>
      );
    }

    let sharing_html = (
      <div className="share-2"><a className="spa-anchor-disabled" href={sharing_url}><img className="click-to-sharing" src={process.env.PUBLIC_URL + "/static/img/share.png"} onClick={this._handleClick('TOGGLE_SHARING')} /></a>
        {jsx_sharing_panel}
      </div>
    );


    // RATING BUTTON--------------------------------
    let jsx_stars_panel = '';
    if (this.state.opened_stars_panel === true) {
      jsx_stars_panel = (
        <PopupMenuBrain callback={this._callbackCloseStars}>
          <StarRatingPanel stars={stars} my_stars={my_stars} post_id={post_id} uel_id={url_id} dispatch={this.props.dispatch} />
        </PopupMenuBrain>
      );
    }
    let rating_html = (
      <div className="star">
        <img className="click-to-rating star-indicator-icon" src={process.env.PUBLIC_URL + "/static/img/star.png"} onClick={this._handleClick('TOGGLE_STARS')} />
        {jsx_stars_panel}
      </div>
    );


    // DELETE BUTTON


    // COMMENTS ***************

    let jsx_input_comment_group = null;
    if (is_nested_link === false) {
      jsx_input_comment_group = (
        <div className="input-comment-group">
          <div className={rss_btn_cl} onClick={this._handleClick('RSS', url)}>
            <div className="inside-btn click-to-subscribe"><div className="bg-img"></div></div>
            <div className="inside-btn click-to-unsubscribe"><div className="bg-img"></div></div>
            <div className="inside-btn dummy-spinner"><div className="the-spinner"></div></div>
          </div>
          <div className="tagging-group"><img className="click-to-tagging" src={process.env.PUBLIC_URL + "/static/img/tag-add.png"} />
            <div className="tags-popup not-touch EXPERIMENT" style={{ display: 'none' }}>
              <form>
              </form>
            </div>
          </div>
          <form onSubmit={this._handle_submit_comment}>
            <input
              name="newcomment"
              type="text"
              className="new-comment"
              placeholder="Write a comment..."
              ref={this.commentRef}
            />
          </form>
        </div >
      );
    }

    let jsx_comments_pending = null;
    let jsx_comments_section = null;
    if (this.state.comments_pending === true && this.state.opened_comments === true) {
      jsx_comments_pending = (
        <SpinnerNormal />
      );
      jsx_comments_section = (
        <>
          <div className="comments-container">
            <div className="actual-comments-ajax view-link-comments" data-link-id="' + props.link.post_id + '">
            </div>
            {jsx_comments_pending}
            <div className="clickable click-to-close-comments" onClick={this._handleClick('TOGGLE_COMMENTS')}><a>Close comments</a></div>
          </div>
        </>
      );
    }
    if (this.state.comments_pending === false && this.state.opened_comments === true) {
      // renedring comments...
      let comments_arr = this.state.comments_data.items;
      let jsx_comments_arr = comments_arr.map((item, index) => {
        return (
          <CommentsItem key={item.id} item={item} dispatch={this.props.dispatch} />
        );
      });
      jsx_comments_section = (
        <>
          <div className="comments-container">
            <div className="actual-comments-ajax view-link-comments" data-link-id="' + props.link.post_id + '">
              {jsx_comments_arr}
            </div>
            <div className="clickable click-to-close-comments" onClick={this._handleClick('TOGGLE_COMMENTS')}><a>Close comments</a></div>
          </div>
        </>
      );
    }
    /*
    if (this.state.opened_comments === true) {
      // TODO
    }
    */


    let jsx_drag_group = null;
    if (draggable === true) {
      jsx_drag_group = (
        <div className="drag-group">
          <div className="drag-me"></div>
          <div className="cover"></div>
        </div>
      );
    }

    let jsx_temp = '';
    let jsx_side_buttons;
    if (editable === true && recycled === false) {
      jsx_side_buttons = (
        <div className="side-buttons-group">
          {sharing_html}
          {rating_html}

          <div className="pen"><img className="click-to-edit" src={process.env.PUBLIC_URL + "/static/img/edit.png"} onClick={this._handleClick('EDIT')} /></div>

          <div className="trash" ><img className="click-to-trash" src={process.env.PUBLIC_URL + "/static/img/trash.png"} onClick={this._handleClick('RECYCLE')} /></div>

        </div>
      );
    } else if (recycled === true) {
      // CASE RECYCLED (POST IN RECYCLE BIN)
      jsx_side_buttons = (
        <div className="side-buttons-group">
          {sharing_html}
          {rating_html}
          <div className="trash" ><img className="click-to-trash" src={process.env.PUBLIC_URL + "/static/img/trash.png"} onClick={this._handleClick('DELETE')} /></div>
        </div>
      );
    } else {
      // CASE IS NOT EDITABLE. NO MORE SIDE BUTTONS
      jsx_side_buttons = (
        <div className="side-buttons-group">
          {sharing_html}
          {rating_html}
        </div>
      );
    }


    let jsx_stats_comments = (
      <div className="not-show-comments">Comments (<span>{comment_count}</span>)</div>
    );
    if (is_nested_link === false) {
      jsx_stats_comments = (
        <div className="not-show-comments clickable" onClick={this._handleClick('TOGGLE_COMMENTS')}>Comments (<span>{comment_count}</span>)</div>
      );
    }

    /*
        <div className="link ui-draggable ' + props.link.temp_css_class_link_owner + '" data-id="' + props.link.post_id + '" data-user-id="' + props.link.user_id + '" data-postid="PLACEHOLDER" data-parent-folder="' + props.link.folder_id + '" data-in-recycle-bin="' + props.link.in_recycle_bin + '" data-rating="' + props.link.stars + '" data-rating-my="' + props.link.my_stars + '" data-time="' + props.link.time + '" data-url-id="' + props.link.url_id + '" data-url="' + props.link.url + '" data-has-youtube="' + props.link.temp_data_has_youtube + '">
     
    */
    /*
          var drag_id = $(ui.draggable).attr("data-id");
          var drag_is_recycled = $(ui.draggable).attr('data-in-recycle-bin');
          var drop_id = $(this).attr("data-folder-id");
          var drag_id_parent = $(ui.draggable).attr("data-parent-folder");
    */

    let jsx_youtube = null;

    let cl_link = "link"; // "link ui-draggable"
    if (is_youtube_video === true) {
      cl_link = "link is-youtube-video";
      jsx_youtube = (
        <div className="btn btn-size-1" onClick={this._handleClick('THEATER', { url: url })}>PLAY VIDEO</div>
      );
    }

    return (
      <>
        <div
          ref={this.dragRef}
          className={cl_link}
          data-id={post_id}
          data-user-id={user_id}
          data-postid="PLACEHOLDER"
          data-parent-folder={fid}
          data-in-recycle-bin={in_recycle_bin}
          data-has-youtube="' + props.link.temp_data_has_youtube + '"
        >

          {jsx_drag_group}

          {jsx_side_buttons}

          <div className="link-panel panel">

            <div className="list-style-nested-panel-group">
              <div className="favicon">
                <a className="thelink" rel="nofollow" target="_blank" href={href_the_link}><img src={props.link.favicon_resource} /></a>
              </div>
              <a className="favicon-title thelink" rel="nofollow" target="_blank" href={href_the_link}>{props.link.title}</a>
            </div>

            <div className="icons-style-nested-panel-group">
              <div className="icon">
                <a className="thelink" rel="nofollow" target="_blank" href={href_the_link}><img src={props.link.selected_picture_bestresource} /></a>
              </div>
              <a className="icon-title thelink" rel="nofollow" target="_blank" href={href_the_link}>{props.link.title}</a>
            </div>

            <div className="author-group">
              <div className="avatar">
                <a className="spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img src={item.user.avatar_url} /></a>
              </div>
              <div className="balloon">
                <a className="nickname spa-anchor-user" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.link.user.display_name}</a>
                <div className="timing-info post-date"><a title="this post URL" href={sharing_url} onClick={this._handleClick('ROUTE', 'POSTS_SINGLE', { post_id: post_id, user_id: user_id })}>Saved on <span>{props.link.date}</span></a></div>
              </div>

            </div>

            <div className="link-content">
              <div className="thumb-group" data-selected-picture-id="' + props.link.selected_picture_id + '">
                <img className="thumbnail" src={props.link.selected_picture_bestresource} />
              </div>

              <div className="link-info">
                <h2 className="title"><a className="thelink" rel="nofollow" target="_blank" title={props.link.url} href={href_the_link}>{props.link.title}</a></h2>
                <div className="domain"><img className="favicon" src="' + props.link.temp_favicon_url + '" /><a rel="nofollow" target="_blank" title={props.link.domain} href={href_domain_link}>{props.link.domain}</a></div>
                <div className="description">{props.link.description}</div>
              </div>

            </div>

            {jsx_youtube}

            {jsx_input_comment_group}

            <div className="link-stats-group">
              <div className="">{props.link.click_count} views</div>
              {jsx_stats_comments}
              <div className="">{props.link.saved_count} saved</div>
            </div>

            {jsx_comments_section}

          </div>
        </div>

      </>
    );
  }
}


