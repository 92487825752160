import React, { Component } from 'react';
import { actionNewRoute, actionUserFetch, actionUserFetchMy } from '../actions/actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import ToolbarUserMy from './ToolbarUserMy';
import ToolbarUserTheir from './ToolbarUserTheir';
import UserProfile from './UserProfile';
import UserProfileEdit from './UserProfileEdit';
import ListFollowing from './ListFollowing';
import ListFollowers from './ListFollowers';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';

export default class RouteUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let user_id = route_params.user_id;
    let my_user_id = this.props.appstate.my_user_id;
    let my = false;
    if (user_id === my_user_id) {
      my = true;
    }
    if (my) {
      dispatch(actionUserFetchMy());
    } else {
      let move = this.props.appstate.route_freshness;
      dispatch(actionUserFetch(move, user_id));
    }
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }

  componentDidMount() {
    // this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      // this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let single_item = [];
    let item;
    let props;

    let section = 'PROFILE';
    let route_params = this.props.appstate.route_current.route_params;
    if (route_params.section === 'FOLLOWING') {
      section = 'FOLLOWING';
    } else if (route_params.section === 'FOLLOWERS') {
      section = 'FOLLOWERS';
    } else if (route_params.section === 'PROFILE_EDIT') {
      section = 'PROFILE_EDIT';
    }
    let user_id = route_params.user_id;
    let my = false;
    let my_user_id = this.props.appstate.my_user_id;
    if (user_id === my_user_id) {
      my = true;
    }

    console.log('MY ' + my);

    let raw_data;
    if (my) {
      raw_data = this.props.appstate.data_user_my;
    } else {
      raw_data = this.props.appstate.data_user; // their
    }

    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;

      if (my) {
        single_item = raw_data.data.my_user_data;
      } else {
        single_item = raw_data.data.item;
      }
      item = single_item;
      props = item;
    }

    console.log('****************');
    console.log(single_item);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let browser_url;
    let page_title;
    if (section === 'FOLLOWING') {
      // section = 'FOLLOWING';
      browser_url = url_lib.user_following(user_id);
      if (my) {
        page_title = "People followed by me - PutCut";
      } else {
        page_title = "People followed by user - PutCut";
      }
    } else if (section === 'FOLLOWERS') {
      // section = 'FOLLOWERS';
      browser_url = url_lib.user_followers(user_id);
      if (my) {
        page_title = "People following me - PutCut";
      } else {
        page_title = "People following user - PutCut";
      }
    } else if (section === 'PROFILE_EDIT') {
      browser_url = url_lib.user_profile_edit(user_id);
      page_title = "Edit profile - PutCut";
    } else {
      browser_url = url_lib.user(user_id);
      if (my) {
        page_title = "My profile - PutCut";
      } else {
        page_title = "User profile - PutCut";
      }
    }
    browser_utils.browser_url_set(browser_url);
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_section = null;
    let jsx_toolbar = null;
    let jsx = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    // if (true) {
    // if (pending === false) {
    zero = false;

    let jsx_toolbar_my = (
      <div className="user-toolbar JS-MADE" data-rendered-here="user_toolbar_my">
        <ToolbarUserMy appstate={this.props.appstate} dispatch={this.props.dispatch} />
      </div>
    );
    let jsx_toolbar_their = (
      <div className="user-toolbar JS-MADE" data-rendered-here="user_toolbar_their">
        <ToolbarUserTheir appstate={this.props.appstate} dispatch={this.props.dispatch} />
      </div>
    );

    // CASES SECTION PROFILE, FOLLOWING; FOLLOWERS
    if (section === 'PROFILE') {
      // CASE PROFILE
      /*
      if (my) {
        jsx_item = (
          <div className="user-profile-panel">
            <div className="clearfix"></div>
            <div className="user-profile-data-group row-2">
              <h2>About me</h2>
              <p>First Name: <b>{props.first_name}</b></p>
              <p>Last Name: <b>{props.last_name}</b></p>
              <p>Year of birth: <b>{props.birthday_year}</b></p>
              <p>Country: <b>{props.country}</b></p>
              <p>Occupations: <b>{props.occupations}</b></p>
            </div>
          </div>
        );
      } else {
        jsx_item = (
          <div className="user-profile-panel">
            <div className="clearfix"></div>
            <div className="user-profile-data-group row-2">
              <h2>About {props.display_name}</h2>
              <p>First Name: <b>{props.first_name}</b></p>
              <p>Last Name: <b>{props.last_name}</b></p>
              <p>Year of birth: <b>{props.birthday_year}</b></p>
              <p>Country: <b>{props.country}</b></p>
              <p>Occupations: <b>{props.occupations}</b></p>
            </div>
          </div>
        );
      }
      */
      if (my) {
        jsx_toolbar = jsx_toolbar_my;
      } else {
        // their toolbar
        jsx_toolbar = jsx_toolbar_their;
      }
      jsx_section = (
        <UserProfile user_id={user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
      jsx = (
        <>
          {jsx_section}
        </>
      );
    } else if (section === 'PROFILE_EDIT') {
      // CASE PROFILE_EDIT
      jsx_section = (
        <UserProfileEdit user_id={user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
      jsx = (
        <>
          {jsx_section}
        </>
      );
    } else if (section === 'FOLLOWING') {
      // CASE FOLLOWING
      if (my) {
        jsx_toolbar = jsx_toolbar_my;
      } else {
        // their toolbar
        jsx_toolbar = jsx_toolbar_their;
      }
      jsx_section = (
        <ListFollowing user_id={user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
      jsx = (
        <>
          {jsx_section}
        </>
      );
    } else if (section === 'FOLLOWERS') {
      // CASE FOLLOWERS
      if (my) {
        jsx_toolbar = jsx_toolbar_my;
      } else {
        // their toolbar
        jsx_toolbar = jsx_toolbar_their;
      }
      jsx_section = (
        <ListFollowers user_id={user_id} appstate={this.props.appstate} dispatch={this.props.dispatch} />
      );
      jsx = (
        <>
          {jsx_section}
        </>
      );
    }

    /*
    if (zero === true) {
      jsx_zero_items = 'Nema rezultata';
    }
    */

    // } // end of pending === false

    return (
      <>
        {jsx_toolbar}
        {jsx_section}
      </>
    );
  }
}
