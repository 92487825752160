/* 
FrFr FRIENDLY FRAMEWORK
web storage utils
Version 0a 2019-07-05
by 
// Please describe here if you customized this file
*/

export let storage_utils = {};
export let session_storage_utils = {};


// local storage

storage_utils.item_get = async (key) => {
  let value = await window.localStorage.getItem(key); // read from local storage
  console.log('WE HAVE DATA FOR ' + key + ': ' + value);
  // return { key, value }
  return value;
};

storage_utils.item_set = async (key, value) => {
  await window.localStorage.setItem(key, value); // write to local storage
  // await AsyncStorage.setItem(key, value.toString());
};

storage_utils.item_delete = async (key) => {
  await window.localStorage.removeItem(key); // delete from localstorage
};


// session storage

session_storage_utils.item_get = async (key) => {
  let value = await window.sessionStorage.getItem(key);
  console.log('WE HAVE DATA FOR ' + key + ': ' + value);
  // return { key, value }
  return value;
};

session_storage_utils.item_set = async (key, value) => {
  await window.sessionStorage.setItem(key, value);
  // await AsyncStorage.setItem(key, value.toString());
};

session_storage_utils.item_delete = async (key) => {
  await window.sessionStorage.removeItem(key);
};

