export let frfr_examples = {};


frfr_examples.frfr_erf_example = {
  error: true,
  frfr_erf: true,
  frfr_erf_version: 1,
  error_type: "",
  error_code: "12345",
  status: "403",
  meta: {
    readme: "",
    thisis: "",
    timestamp: ""
  },
  error_message: {
    title: "Example FRFR_ERF title",
    detail: "Example FRFR_ERF detail",
    description: "Example FRFR_ERF description", // UMESTO DETAIL ???
    message: "Example FRFR_ERF message", // UMESTO DETAIL ???
    more_link_text: "",
    more_link_url: "",
  },
  internal_message: "",
  error_data: {},
  errors: [],
  extra_data: {},
};

frfr_examples.frfr_success_example = {
  success_message: {
    title: 'Example success title',
    message: 'Example success message'
  }
};


