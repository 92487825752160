import React, { Component } from 'react';
import SidebarTheir from '../components/SidebarTheir';

export default class RouterSidebarTheir extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let dispatch = this.props.dispatch;
    let appstate = this.props.appstate;
    let route_params = this.props.appstate.route_current.route_params;

    let my = true;
    let user_id;
    let my_user_id = this.props.appstate.my_user_id;

    if (route_params === null || typeof route_params === 'undefined') {
      my = true;
    } else {
      my = true;
      if ('user_id' in route_params) {
        user_id = route_params.user_id;
        if (typeof user_id === 'undefined' || user_id === my_user_id) {
          my = true;
        } else {
          my = false;
        }
      }
    }
    console.log('my: ', my);

    let jsx = null;

    if (my) {

    } else {
      jsx = (
        <SidebarTheir user_id={user_id} appstate={this.props.appstate} active_item={this.props.active_item} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />
      );
    }

    console.log('******************** SIDEBAR MY (or not): ' + my);

    return (
      <>
        {jsx}
      </>
    );
  }
}

// <div className="their-sidebar-group hide">


