import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
// import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
// import rootReducer from './reducers/index.js'; // moze i ovako
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import App from './components/App';

const app_version = 'id2020-05-18'; // UNIQUE ID FOR LAST CHANGES TO THIS APP - FOR EASIER TRACKING OF COMMITS

console.log('Zdravo');
// check React version
console.log(React.version);
console.log(app_version);


// let nesto = process.env.NODE_ENV;
// console.log(nesto);
// https://create-react-app.dev/docs/adding-custom-environment-variables/
if (process.env.NODE_ENV === 'development') {
  console.log("ENVIRONMENT INFO: process.env.NODE_ENV is 'development' - (it is always equal to 'development', when you run npm start)");
}
if (process.env.NODE_ENV === 'test') {
  console.log("ENVIRONMENT INFO: process.env.NODE_ENV is 'test' - (it is always equal to 'test', when you run npm test)");
}
if (process.env.NODE_ENV === 'production') {
  console.log("ENVIRONMENT INFO: process.env.NODE_ENV is 'production' - (when you run npm run build to make a production bundle, it is always equal to 'production')");
}
console.log('ENVIRONMENT INFO: process.env.PUBLIC_URL=' + process.env.PUBLIC_URL);

// kreiramo Redux store
// export const store = createStore(rootReducer); // najprostiji store bez debuggera
// export const store = createStore(rootReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()); // NOVO store koji omgucava koriscenje browser ekstenzije Redux DevTools Extension
// If you setup your store with middleware and enhancers, change:
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(thunk)
)); // NOVO store koji podrzava i thunk i redux-dev-tools i React Native Debugger

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
