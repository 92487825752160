import React, { Component } from 'react';
import { actionNewRoute, actionCheatsEnable, actionBrowserReloadForced } from '../actions/actions.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';

import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';

export default class CheatsToolbar extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'REFRESH') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED FORCED REFRESH');
        // browser_utils.browser_refresh_forced();
        dispatch(actionBrowserReloadForced());
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {
    // console.log(this.props);

    return (
      <>
        <div className="cheats-toolbar">CHEATS:
        <>
            <a className="dropdown-option click-to-help" href="#" onClick={this._handleClick('REFRESH')}>Forced reload</a>
            <a className="dropdown-option click-to-help" href="#" onClick={this._handleClick('ROUTE', 'CHEATS_ROUTE')}>Cheats page</a>
          </>
        </div>

      </>
    );
  }
}


    // dispatch(actionMultimodalOpen('MODAL_THEATER')); // TEST