import React, { Component } from 'react';
import { actionNewRoute, actionFoldersTheirFetch } from '../actions/actions.js';
import SpinnerSmall from './SpinnerSmall';
import TreeItemsFolders from './TreeItemsFolders';
import TreeLiFolder from './TreeLiFolder';

export default class FoldersTheir extends Component {
  constructor(props) {
    super(props);
    console.log('constructor FoldersTheir');
    console.log(this.props);
    this.state = {
      opened: false
    };
    this._handleClick = this._handleClick.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _callbackClose() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened: false
    });
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE') {
      return (e) => {
        e.stopPropagation();
        console.log('TOGGLE');
        if (this.state.opened === true) {
          this.setState({
            opened: false
          });
        } else {
          this.setState({
            opened: true
          });
        }
      }
    }

    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  _refresh() {
    let dispatch = this.props.dispatch;
    let user_id = this.props.user_id;
    let move = this.props.appstate.route_freshness;
    dispatch(actionFoldersTheirFetch(move, user_id));
  }

  componentDidMount() {
    console.log('did MOUNT');
    console.log(this.props);
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    console.log(this.props);
    let need_refresh = false;
    if (this.props.user_id !== prevProps.user_id) {
      console.log(' *** REFRESH FODLERS ' + this.props.user_id + ' ' + prevProps.user_id);
      need_refresh = true;
    }
    if (need_refresh) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = false;
    let appstate = this.props.appstate;
    let folders = [];
    let their_folders = [];
    let raw_data = appstate.data_folders_public;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      folders = raw_data.data.items;
      their_folders = folders;
    }

    let cl_tree = 'tree root-ul';
    if (pending) {
      cl_tree = 'tree root-ul tree-case-is-spinner';
    }

    let root_folder_item = {
      id: 'THEIR DESKTOP HAS NO ID',
      level: 0,
      parent_fid: "DESKTOP_HAS_NO_PARENT",
      title: 'DesktopRR',
      private: "0",
      user_id: null,
      state: null,
      children: folders
    };
    /*
    children: []
id: "171"
level: "1"
parent_fid: "0"
private: "0"
state: "0"
title: "Biznis"
user_id: "_5f4f8d3"
__proto__: Object

    */

    return (
      <>
        <div className="clearfix"></div>
        <div className="folders their-folders" data-user-id="">
          <ul className={cl_tree}>
            <li className="spinner-li">
              <div className="item spinner-item systemfolder" data-folder-id="spinner" data-is-folder="false" data-level="0"><span></span></div>
              </li>
            <li className="not-spinner-li"><div className="item their-desktop-item systemfolder" data-folder-id="theirdesktop" data-is-folder="false" data-level="0" data-view-stlye=""><a className="folderlink" href="#"><div className="icon"><img src={process.env.PUBLIC_URL + "/static/img/desktop.png"} /></div><span>User shared folders</span></a><div className="expand"><img src={process.env.PUBLIC_URL + "/static/img/expand.png"} /></div><div className="lock lock-case-unlocked"></div>
            </div>

              <ul className="folders-actually-go-here">

                <TreeItemsFolders folders={their_folders} my={false} active_item={this.props.active_item} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />

              </ul>
            </li>
          </ul>
        </div>
      </>
    );
  }
}

/*
    <li className="not-spinner-li">
      <div className="item desktop-item systemfolder" data-folder-id="0" data-user-id="' + props.temp_desktop_user_id + '" data-is-folder="false" data-level="0" data-view-stlye="icons"><a className="folderlink" href="' + props.temp_desktop_url + '"><div className="icon"><img src="/static/img/desktop.png" /></div><span>Desktop</span></a><div className="expand"><img src="/static/img/expand.png" /></div></div>
      <ul className="folders-actually-go-here">
        <TreeItemsFolders folders={folders} />
      </ul>
    </li>

    */
