import React, { Component } from 'react';
import FoldersTheir from './FoldersTheir';

export default class SidebarTheir extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <div className="their-sidebar-group">
          <div className="their-folders-container">
            {/* <!--their folders here--> */}
            <FoldersTheir user_id={this.props.user_id} appstate={this.props.appstate} active_item={this.props.active_item} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />
          </div>
        </div>
      </>
    );
  }
}
