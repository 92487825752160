import React, { Component } from 'react';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import { on_url_change_offline, putcut_routing } from '../utils/putcut-routing.js';

// do reroute from URL after logged in

export default class RouteInitialRerouteContainer extends Component {

  constructor(props) {
    super(props);
    console.log('constructor RouteInitialRerouteContainer');
    console.log(this.props);
  }

  componentDidMount() {
    // DO INITIAL REROUTE BY URL
    // let url_path = browser_utils.browser_get_url_path();
    // on_url_change_offline(url_path);
    putcut_routing.reroute_this_window_url(); // DO REROUTING BY URL
  }
  
  render() {
    return (
      <>
      </>
    );
  }
}
