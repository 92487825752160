/* 
FrFr FRIENDLY FRAMEWORK
responsive utils
Version 0a 2019-06-21
by 
// Please describe here if you customized this file
*/

export let frfr_responsive = {};

frfr_responsive.on_event_scroll = function (e) {
  // console.log("frfr-reponsive.js - event_scroll(e) ");
  // console.log(e);
  let window_scrolled_px = document.documentElement.scrollTop;
  // console.log(window_scrolled_px);
  if (window_scrolled_px >= 2) {
    document.documentElement.classList.add('scrolled'); // html element
    document.body.classList.add('scrolled'); // body
  } else {
    document.documentElement.classList.remove('scrolled'); // html element
    document.body.classList.remove('scrolled'); // body
  }
}

frfr_responsive.init = function () {
  console.log("frfr-reponsive.js - init() ");
  window.onscroll = frfr_responsive.on_event_scroll;
}


/*
// JQUERY
function initResponsive() {
  console.log("reponsive.js - initResponsive() ");
  $(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 2) {
      $('body').removeClass('scrolled').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }
  });
  // $(window).resize(); // ??? https://api.jquery.com/resize/
  $(window).scroll(); // ??? https://api.jquery.com/scroll/
}
*/
