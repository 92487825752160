import React, { Component } from 'react';

export default class SpinnerLarge extends Component {
  render() {
     return (
      <div className="spinner-large">
      </div>
    );
  }
}
