import React, { Component } from 'react';

export default class ModalSuccess extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'SCRAPE_AGAIN') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
        this._scrape();
      }
    }
  }

  render() {

    let modal_args = this.props.modal_args;
    let dispatch_close_modal = this.props.dispatch_close_modal;

    let title = modal_args.title;
    let message = modal_args.message;

    return (
      <>
        <div className="modal-item-content thisis-ModalSuccess">
          <h2>{title}</h2>
          <p>{message}</p>
          <div className="flex-bar center gaps">
          <button type="button" className="xi btn btn-block btn-default" onClick={dispatch_close_modal}>Ok</button>
          </div>
        </div>
      </>
    );
  }
}
