import React, { Component } from 'react';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { mini_ajax_post, mini_ajax_get } from '../utils/ajax.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

// import ModalPrompt from './ModalPrompt';
import ModalCustomJSX from './ModalCustomJSX';


// TODO: rss fedds subscribe API v2 ...
// TODO: AJAX...


export default class ModalRssSubsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pending: true,
      data: {
        items: []
      },
      multicheckbox: []
    };
    /*
          {id: 1, value: "banana", isChecked: false},
          {id: 2, value: "apple", isChecked: false},
          {id: 3, value: "mango", isChecked: false},
          {id: 4, value: "grap", isChecked: false}
    */
    // binding this
    this._handleClick = this._handleClick.bind(this);
    this._subscriptions_get = this._subscriptions_get.bind(this);
    this._handleMulticheckboxChange = this._handleMulticheckboxChange.bind(this);
    this._submit = this._submit.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'SUBSCRIBE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        this._submit();
      }
    }
  }

  _handleMulticheckboxChange = (event) => {
    let multicheckbox = this.state.multicheckbox;
    console.warn('//// CHANGED');
    console.log(event.target.value);
    console.log(event.target.checked);
    console.log(multicheckbox);
    let new_multicheckbox = multicheckbox.map((c) => {
      if (c.checkbox_value === event.target.value) {
        console.log(' BINGO');
        console.log(c);
        c.checked = event.target.checked;
      }
      return c;
    });
    console.log(new_multicheckbox);
    this.setState({ multicheckbox: new_multicheckbox })
  }

  _submit() {
    // update rss subscriptions
    console.log('_submit() ');
    // let dispatch = this.props.dispatch;
    let fetch_url = api_lib.feeds_subscriptions_update(); // UPDATE FEEDS SUBS
    // let checked_arr = ['https://tutorialzine.com/feed', 'http://feeds.arstechnica.com/arstechnica/index/']; // TEST
    let checked_arr = [];
    let unchecked_arr = [];
    let multicheckbox = this.state.multicheckbox;
    multicheckbox.forEach((item, index) => {
      console.log(item);
      if (item.checked !== false) {
        // anything different from FALSE is true...
        checked_arr.push(item.checkbox_value);
      } else {
        // FALSE
        unchecked_arr.push(item.checkbox_value);
      }
    });

    /*
      let outbox = {
        data_checked: [],
        data_unchecked: [],
        nestoa: 'a'
      }; // data for post method
  
      https://tutorialzine.com/feed
      http://feeds.arstechnica.com/arstechnica/index/
    */
    let outbox = {
      subs_checked: checked_arr,
      subs_unchecked: unchecked_arr,
      readme: 'manage rss feed subscriptions'
    }; // data for post method

    /*
    // data za feeds su stari puttcut feeds.js na liniji 384 
    
    updateFeedsSubscriptions(rssarray_ch, rssarray_un, callback) {
      // data treba da bude array sa pojedinacnim feed subscriptionima...
      // otkomentovati kada bude spreman API
      var fetch_url = putcut.api_lib.feeds_subscriptions_update(); // old api
      console.log(fetch_url);
      $.post(fetch_url, {data_checked: JSON.stringify(rssarray_ch), data_unchecked: JSON.stringify(rssarray_un)}
    */

    let callback = () => {
      let dispatch = this.props.dispatch;
      console.warn('***** CALLBACK FROM MINI AJAX ???');
      // dispatch(actionNewRoute('FOLDERS', { fid: fid, user_id: user_id }));
      this.props.dispatch_close_modal();
    }
    mini_ajax_post(fetch_url, outbox, callback, null, true, 'neki opis');
  }

  _subscriptions_get() {
    console.log(' ***** _subscriptions_get *****');
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let fetch_url = api_lib.feeds_subscriptions_get(); // GET METHOD
    let callback = (result) => {
      // let dispatch = this.props.dispatch;
      console.warn('***** CALLBACK FROM MINI AJAX POST ???');
      // this.setState({ data: result, pending: false });
      this._multicheckbox_init(result); // INITIALIZE MULTICHECKBOX STATE...
    }
    mini_ajax_get(fetch_url, callback, null, true, 'neki description');
  }

  _multicheckbox_init(result) {
    console.log('_multicheckbox_init() ');
    // this.setState({ data: result, pending: false });
    // INITIALIZE MULTICHECKBOX STATE...
    let subscriptions = result.items;
    let checked = true; // all checked for initial render of edit subscriptions list...
    let multicheckbox = subscriptions.map((item) => {
      return {
        checkbox_value: item,
        checked: checked
      };
    }
    );
    this.setState({
      data: result,
      multicheckbox,
      pending: false
    });
  }

  componentDidMount() {
    this._subscriptions_get();
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);

    let dispatch_close_modal = this.props.dispatch_close_modal;

    let jsx_pending = (
      <div className="spinner-area">
        <div className="spinner-cell">
          <div className="the-spinner"></div>
        </div>
      </div>
    );

    let pending = this.state.pending;
    let jsx_arr = null;

    if (pending === false) {
      jsx_pending = null;
      let multicheckbox = this.state.multicheckbox;
      jsx_arr = multicheckbox.map((item, index) => {
        let checkbox_value = item.checkbox_value;
        let checked = item.checked;
        let checkbox_title = item.checkbox_value;
        return (
          <div key={index}>
            <input
              type="checkbox"
              className="check-to-subscribe"
              value={checkbox_value}
              checked={checked}
              onChange={this._handleMulticheckboxChange}
            />{checkbox_title} <br />
          </div>
        );
      });
    }

    let jsx_buttons = (
      <>
        <div className="xi cancel-button btn btn-size-1" onClick={dispatch_close_modal}>Cancel</div>
        <div className="xi add-button btn btn-size-1 btn-style-blue-1" onClick={this._handleClick('SUBSCRIBE')}>Subscribe</div>
      </>
    );

    let jsx_custom = (
      <>
        <div className="modal-feed-editor-real">
          <h3>Edit RSS feeds subscriptions</h3>
          <p>Cheek the feed you want subscribe to, uncheck feed to unsubscribe. Feeds you already subscribed to are checked in advance.</p>
          <div className="feeds-choose-container">
            {jsx_pending}
            {jsx_arr}
            {/*<!--<input type="checkbox" />feed 1<br />-->*/}
          </div>
          <div className="flex-bar right gaps edit-buttons-group buttons-group-style-2">

            {jsx_buttons}

          </div>
        </div>
      </>
    );

    let modal_args = {
      custom_content_jsx: jsx_custom
    };

    return (
      <>
        <ModalCustomJSX modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}

