import React, { Component } from 'react';
import { actionNewRoute } from '../actions/actions.js';
import { actionMultimodalOpen } from '../actions/frfr-multimodal-actions.js';
import UniversalFollowMultibuttonContainer from '../containers/UniversalFollowMultibuttonContainer';


export default class CommentsItem extends Component {
  constructor(props) {
    super(props);
    this._handleClick = this._handleClick.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'OPTIONS') {
      return (e) => {
        e.preventDefault();
        console.log('OPTIONS');
        let comment_id = this.props.item.id;
        // dispatch(actionMultimodalCommentOptions(comment_id));
        dispatch(actionMultimodalOpen('MODAL_COMMENT_OPTIONS', { id: comment_id }));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  render() {
    // console.log('Comment');
    // console.log(this.props);
    let item = this.props.item;
    console.log('**** COMMENT ITEM');
    console.log(item);
    let comment_id = item.id;

    // TODO uuid

    return (
      <>
        <div className="comment" data-comment-id="' + props.comment.id + '" data-user-id="' + props.comment.user_uuid + '" data-time="' + props.comment.time + '">
          <div className="avatar">
            <a href="/user/' + props.comment.user_uuid + '"><img src={item.user.avatar_url} /></a>
          </div>
          <div className="balloon">
            <h5 className="nickname"><a href="/user/' + comment.user_uuid + '">{item.user.display_name}</a></h5>:
					<p className="comment-text">{item.comment}</p>
          </div>
          <div className="click-to-comment-options" title="Comment options" onClick={this._handleClick('OPTIONS')}>
          </div>
        </div>

      </>
    );
  }
}


