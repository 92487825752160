import React, { Component } from 'react';
import { actionNewRoute, actionPostsRecycleBinFetch } from '../actions/actions.js';
import { actionMultimodalAddLink } from '../actions/frfr-multimodal-actions.js';
import { url_lib } from '../utils/url-lib.js';
import { browser_utils } from '../utils/frfr-browser-utils.js';
import ToolbarUserTheir from './ToolbarUserTheir';
import PostsItemLinkUniversal from './PostsItemLinkUniversal';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';


export default class RouteBin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewstyle: 'DEFAULT'
    };
    this._handleClick = this._handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'VS_DEFAULT') {
      return () => {
        this.setState({ viewstyle: 'DEFAULT' });
      }
    }
    if (what === 'VS_LIST') {
      return () => {
        this.setState({ viewstyle: 'LIST' });
      }
    }
    if (what === 'VS_ICONS') {
      return () => {
        this.setState({ viewstyle: 'ICONS' });
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return (e) => {
        e.preventDefault();
        // dispatch(actionNewRoute());
      }
    }
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value
    });
  }



  _refresh() {
    let dispatch = this.props.dispatch;
    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let move = this.props.appstate.route_freshness;

    let user_id = route_params.user_id;
    let my = false;
    let my_user_id = this.props.appstate.my_user_id;
    if (user_id === my_user_id) {
      my = true;
    }

    //
    dispatch(actionPostsRecycleBinFetch(move)); // TODO

  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('did UPDATE');
    let is_fresh_route = false;
    if (this.props.appstate.route_freshness !== prevProps.appstate.route_freshness) {
      is_fresh_route = true;
    }
    if (is_fresh_route) {
      // REFRESH
      this._refresh();
    }
  }

  render() {
    let pending = true;
    let zero = true;
    let table_arr = [];

    let route_params = this.props.appstate.route_current.route_params;
    let fid = route_params.fid;
    let user_id = route_params.user_id;

    let my = true;

    let editable = false;
    let draggable = true;
    let recycled = true;

    let raw_data = this.props.appstate.data_bin;

    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      // table_arr = raw_data.data.items;
      table_arr = raw_data.data.items;
    }

    console.log(table_arr);

    // DATA IS READY
    // ----------------
    // BROWSER UPDATE
    let page_title = 'Recycle bin - PutCut';
    let browser_url = url_lib.recyclebin();
    browser_utils.browser_url_set(browser_url);
    browser_utils.browser_title_set(page_title);
    // ----------------
    // PRESENTATION JSX

    let jsx_spinner = null;
    let jsx_zero_items = null;
    let jsx_arr = null; //
    let jsx_toolbar = null;
    let jsx_toolbar_user_their = null;
    let jsx_putcut_widget = null;
    // if (pending === true) {
    if (pending) {
      // SPINNER
      jsx_spinner = <SpinnerMain />
    }

    // toolbar for recycle bin <ToolbarBin />
    jsx_toolbar = (
      <>
        <div className="main-toolbar clearfix">
          <div className="item layout-view" onClick={this._handleClick('VS_DEFAULT')}><img title="Details view" className="change-view-style click-to-style-normal" src={process.env.PUBLIC_URL + "/static/img/view-thumblist.png"} /></div>
          <div className="item layout-view" onClick={this._handleClick('VS_ICONS')}><img title="Icons view" className="change-view-style click-to-style-icons" src={process.env.PUBLIC_URL + "/static/img/view-icons-medium.png"} /></div>
          <div className="item layout-view" onClick={this._handleClick('VS_LIST')}><img title="List view" className="change-view-style click-to-style-list" src={process.env.PUBLIC_URL + "/static/img/view-list.png"} /></div>
        </div>
        <div className="clear"></div>
        <h1>Recycle bin</h1>
      </>
    );


    if (pending === false) {

      let filtered_arr = table_arr; // NO FILTERING

      jsx_arr = filtered_arr.map((item, index) => {
        zero = false;
        // <div>{item.action}</div>
        return (
          <PostsItemLinkUniversal key={item.post_id} item={item} editable={editable} draggable={draggable} recycled={recycled} dispatch={this.props.dispatch} />
        );
      });

      if (zero === true) {
        jsx_zero_items = 'Nema rezultata';
      }

    } // end of pending === false

    let cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-default';
    if (this.state.viewstyle === 'LIST') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-list';
    } else if (this.state.viewstyle === 'ICONS') {
      cl_viewstyle = 'ajax-container links-container real-links-ajax links-style-default view-style-icons';
    }

    return (
      <>
        {jsx_toolbar}

        <div className={cl_viewstyle}>

          {jsx_arr}
          {jsx_zero_items}
          {jsx_spinner}

        </div>
      </>
    );
  }
}
