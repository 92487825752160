import { cookie_utils } from './frfr-cookie-utils.js'; // using browser cookies in React.
import { ajax_utils } from './frfr-ajax-abstract.js';
import { storage_utils, session_storage_utils } from './frfr-webstorage-utils';

export let auth_steps = {};

auth_steps.config = {
  use_cookies: true,
  use_storage: false
};

let config = auth_steps.config;

auth_steps.state = {};

auth_steps.local_token_key_set = (key_name) => {
  auth_steps.state.local_token_key_name = key_name;
  console.log('auth_steps state ', auth_steps.state);
};

auth_steps.header_token_key_set = (key_name) => {
  auth_steps.state.header_token_key_name = key_name;
  console.log('auth_steps state ', auth_steps.state);
};

auth_steps.local_token_get = async () => {
  let key = auth_steps.state.local_token_key_name;
  console.log(key);
  console.log(auth_steps.state);
  console.log(auth_steps.state.local_token_key_name);
  let token;
  if (config.use_cookies) {
    key = auth_steps.state.local_token_key_name;
    token = await cookie_utils.item_get(key);
    console.log('auth_steps.local_token_get() will return: ' + token);
    return token;
  } else if (config.use_storage) {
    key = auth_steps.state.local_token_key_name;
    token = await storage_utils.item_get(key);
    console.log('auth_steps.local_token_get() will return: ' + token);
    return token;
  }
};

auth_steps.local_token_set = async (value, options) => {
  let key = auth_steps.state.local_token_key_name;
  let token;
  if (config.use_cookies) {
    token = await cookie_utils.item_set(key, value, options);
    return true;
  } else if (config.use_storage) {
    token = await storage_utils.item_set(key, value);
    return true;
  }
};

auth_steps.local_token_delete = async () => {
  let key = auth_steps.state.local_token_key_name;
  let token;
  if (config.use_cookies) {
    token = await cookie_utils.item_delete(key);
    return true;
  } else if (config.use_storage) {
    token = await storage_utils.item_delete(key);
    return true;
  }
};

// storage_utils.item_set(token_key, token)


// let _logout_procedure = async () => {
auth_steps.logout_procedure = async () => {
  //
  console.log('LOGOUT PROCEDURE...');
  console.log('DELETING LOCAL TOKENS, cookies...');
  // storage_utils.item_delete(token_key);
  auth_steps.local_token_delete();
  /*
  cookie_utils.log();
  console.log(cookie_utils);
  cookie_utils.item_delete(token_key);
  */

  console.log('before DELETING HEADERS...');
  /*
  console.log('++get HEADERS: ', ajaxget_headers);
  for (var pair of ajaxget_headers.entries()) {
    console.log(pair[0] + ': ' + pair[1]); // log content of the headers
  }
  console.log('++post HEADERS: ', ajaxpost_headers);
  for (var pair of ajaxpost_headers.entries()) {
    console.log(pair[0] + ': ' + pair[1]); // log content of the headers
  }
  */
  ajax_utils.log();

  console.log('DELETING HEADERS...');
  let key = auth_steps.state.header_token_key_name;
  ajax_utils.getmethod_headers_delete(key);
  ajax_utils.postmethod_headers_delete(key);


  console.log('after DELETING HEADERS...');
  /*
  console.log('++get HEADERS: ', ajaxget_headers);
  for (var pair of ajaxget_headers.entries()) {
    console.log(pair[0] + ': ' + pair[1]); // log content of the headers
  }
  console.log('++post HEADERS: ', ajaxpost_headers);
  for (var pair of ajaxpost_headers.entries()) {
    console.log(pair[0] + ': ' + pair[1]); // log content of the headers
  }
  */
  ajax_utils.log();

  console.log('LOGOUT PROCEDURE FINISHED');
  return true;
};




// let _autologin_step1_check_frontend_precondition = async () => {
// auth_steps._autologin_step1 = async () => {
auth_steps.before_autologin_preconditions = async () => {
  let token = await auth_steps.local_token_get();
  console.log('test c l s');
  console.log(typeof token);
  // if(!token) {
  if (!(typeof token === 'string' || typeof token === 'number')) {
    // if token is anything other than string or number
    console.log('login failed step 1 - no frontend token');
    return false;
  }
  console.log('cls PREPARING HEADERS... ', token);
  let key = auth_steps.state.header_token_key_name;
  // SETUJEMO HEADERS AZ BUDUCE AJAX POZIVE
  ajax_utils.getmethod_headers_set(key, token); // ???
  ajax_utils.postmethod_headers_set(key, token); // ???

  // let loginResponse = await _zt_fetch_checkLogin(token);
  // console.log('cls header seted??? ', temp_header);
  console.log('cls header seted??? ');
  return true;
};



/*

AUTH/LOGIN STEPS

- TRY AUTOLOGIN odmah na pocetku

- LOGIN FORM SUBMIT
frontend_after_login_done_procedure
store token localy

- AUTOLOGIN
before autologin procedura
check if local token exist or expired
AJAX autologin_check_login_status(); // AJAX

after_autologin_done_procedure
token_expiration_renew()
set_cookie ...

EMIT DISPATCH EVENT autologin_logged_in(); // EMIT


*/


