import React, { Component } from 'react';
import { api_lib } from '../utils/url-lib.js';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';

export default class UniversalFollowMultibutton extends React.Component {
  //

  render() {
    console.log('********* fol');
    console.log(this.props);

    let inbox = this.props.data;
    console.log(inbox);

    let cl_multibutton = 'universal-follow-multibutton multibutton-status-ajax-loading';
    if (inbox.pending === false) {
      if (inbox.data.success) {
        if (inbox.data.is_following === true) {
          cl_multibutton = 'universal-follow-multibutton multibutton-status-following mymymy-clickable-unfollow';
        } else {
          cl_multibutton = 'universal-follow-multibutton multibutton-status-not-following mymymy-clickable-followw';
        }
      }
    }

    return (
      <>
        <div class={cl_multibutton} data-user-id="' + props.data.user_id + '">
          <div class="inside-btn clickable-follow" onClick={this.props.cb_follow}><span class="button-text follow-text">Follow</span></div>
          <div class="inside-btn dummy-following"><span class="button-text following-text">Following</span></div>
          <div class="inside-btn clickable-unfollow" onClick={this.props.cb_unfollow}><span class="button-text unfollow-text">Unfollow</span></div>
          <div class="inside-btn dummy-spinner"><SpinnerSmall /></div>
        </div>
      </>
    );
  }
}
