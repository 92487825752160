import React, { Component } from 'react';
import { actionNewRoute, actionFoldersMyFetch } from '../actions/actions.js';
import SpinnerSmall from './SpinnerSmall';
import TreeItemsFolders from './TreeItemsFolders';
import TreeLiFolder from './TreeLiFolder';

export default class FoldersMy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false
    };
    this._handleClick = this._handleClick.bind(this);

    // this._callbackClose = this._callbackClose.bind(this);
    // this.parentRef = React.createRef(); // ref radi detekcije klikova kod childa
  }

  _callbackClose() {
    console.log(' ***** CHILDREN CALLBACK *****');
    this.setState({
      opened: false
    });
  }

  _handleClick(what, second) {
    let dispatch = this.props.dispatch;
    if (what === 'TOGGLE') {
      return (e) => {
        e.stopPropagation();
        console.log('TOGGLE');
        if (this.state.opened === true) {
          this.setState({
            opened: false
          });
        } else {
          this.setState({
            opened: true
          });
        }
      }
    }

    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  componentDidMount() {
    let dispatch = this.props.dispatch;
    dispatch(actionFoldersMyFetch());
  }

  render() {
    let pending = false;
    let appstate = this.props.appstate;
    let my_user_id = appstate.my_user_id;
    let raw_folders = [];
    let raw_data = appstate.data_folders_my;
    if (raw_data.pending === true) {
      pending = true;
    } else {
      pending = false;
      raw_folders = raw_data.data.items;
    }

    let cl_tree = 'tree root-ul';
    if (pending) {
      cl_tree = 'tree root-ul tree-case-is-spinner';
    }

    let recycle_bin_item = {
      id: "bin",
      level: 1,
      parent_fid: 0,
      title: 'Recycle Bin',
      private: "0",
      user_id: my_user_id,
      state: null
    };

    let folders = [...raw_folders, recycle_bin_item]; // PURE append bin in folder array

    let root_folder_item = {
      id: "0",
      level: 0,
      parent_fid: "DESKTOP_HAS_NO_PARENT",
      title: 'DesktopRR',
      private: "0",
      user_id: my_user_id,
      state: null,
      children: folders
    };
    /*
    children: []
id: "171"
level: "1"
parent_fid: "0"
private: "0"
state: "0"
title: "Biznis"
user_id: "_5f4f8d3"
__proto__: Object

    */

    return (
      <>
        <ul className={cl_tree}>

          <li className="spinner-li">
            <div className="item spinner-item systemfolder" data-folder-id="spinner" data-is-folder="false" data-level="0" data-view-stlye="icons"><SpinnerSmall /><span></span></div>
          </li>

          <TreeLiFolder key={'0desktopID'} type={'DESKTOP'} folder={root_folder_item} folders={folders} active_item={this.props.active_item} my={true} active_item_fid={this.props.active_item_fid} dispatch={this.props.dispatch} />

        </ul>
      </>
    );
  }
}

/*
    <li className="not-spinner-li">
      <div className="item desktop-item systemfolder" data-folder-id="0" data-user-id="' + props.temp_desktop_user_id + '" data-is-folder="false" data-level="0" data-view-stlye="icons"><a className="folderlink" href="' + props.temp_desktop_url + '"><div className="icon"><img src="/static/img/desktop.png" /></div><span>Desktop</span></a><div className="expand"><img src="/static/img/expand.png" /></div></div>
      <ul className="folders-actually-go-here">
        <TreeItemsFolders folders={folders} />
      </ul>
    </li>

    */
