
import { store } from '../index.js' // NOVO dispatch
import autoconfig from './putcut-react-autoconfig.js';

/*
CHEATS

TODO:
+ globalna funkcija za ukljucenje CHEAT MODE
+ dodavanje css klasa za testing env...
+ vidljiva komponenta za cheat mode CheatsToolbar
+ action za cheats. uradjen i action creator ali za sada saljemo action object samo. 

OSTALO TODO
- da li da svi routeri budu containeri?
- konzistencija prefix App Auth Init Router i sta jos ???
- srediti url routing... naziev funkcija i svasta...
- srediti browser_utils nazive funkcije...
- dodati opis i PLEASE DESCRIBE ... u frfr fajlove.

*/

export let putcut_cheats_init = function () {

  let env = autoconfig.detect_environment();
  if (env === 'DEV') {
    // add css class for testing environment only
    document.documentElement.classList.add('testing-env', 'testing-env-react'); // html element
    document.body.classList.add('testing-env', 'testing-env-react'); // body

    // DOM and css changes for testing environment only
    let dom_meta_link = document.createElement('link');
    dom_meta_link.rel = 'stylesheet';
    dom_meta_link.href = process.env.PUBLIC_URL + "/static/css/testing-only/testing-only.css";
    document.getElementsByTagName('head')[0].appendChild(dom_meta_link);
  }

  //
  window.cheat = function () {
    // globalna funkcija za ukljucivanje CHEAT MODE
    console.log('**************************');
    console.log('*** CHEAT MODE ENABLED ***');
    console.log('**************************');
    store.dispatch({ type: 'CHEATS_ENABLE' });
  };

};
