import { ajax_fetch } from './frfr-ajax-fetch';

/*
let ajax_simulator = function (data) {
  resolve_after_ms(data, 5000);
}

export let resolve_after_ms = async function (x, ms) {
  if (typeof ms === 'undefined') {
    ms = 7000;
  }
  return new Promise(resolve => {
    setTimeout(function () { resolve(x) }, ms);
  });
}
*/

export let ajax_utils = {};

ajax_utils.config = {
  use_fetch: true,
  use_jquery: false,
  use_axios: false
};

let config = ajax_utils.config;
// let use_fetch = ajax_utils.config.use_fetch;
// let use_jquery = ajax_utils.config.use_jquery;


ajax_utils.convert_to_urlencoded_string = (formvals) => {
  // convert object with data for post method request to url encoding (URL query) string
  // 'application/x-www-form-urlencoded;charset=UTF-8';
  // PHP http_build_query — Generate URL-encoded query string
  const params = new URLSearchParams();
  for (const [key, val] of Object.entries(formvals)) {
    params.append(key, val);
  }
  return params.toString()
};


ajax_utils.init = () => {
  if (config.use_fetch) {
    ajax_fetch.init();
  }
};


ajax_utils.log = () => {
  if (config.use_fetch) {
    ajax_fetch.log();
  }
};


ajax_utils.getmethod_headers_set = function (key, value) {
  if (config.use_fetch) {
    ajax_fetch.getmethod_headers_set(key, value);
  }
};
ajax_utils.postmethod_headers_set = function (key, value) {
  if (config.use_fetch) {
    ajax_fetch.postmethod_headers_set(key, value);
  }
};


ajax_utils.getmethod_headers_set_arr = function (arr) {
  if (config.use_fetch) {
    ajax_fetch.getmethod_headers_set_arr(arr);
  }
};
ajax_utils.postmethod_headers_set_arr = function (arr) {
  if (config.use_fetch) {
    ajax_fetch.postmethod_headers_set_arr(arr);
  }
};


ajax_utils.getmethod_headers_delete = function (key) {
  if (config.use_fetch) {
    ajax_fetch.getmethod_headers_delete(key);
  }
};
ajax_utils.postmethod_headers_delete = function (key) {
  if (config.use_fetch) {
    ajax_fetch.postmethod_headers_delete(key);
  }
};


// ABSTRACT FETCH

ajax_utils.getmethod_send = async (url = '') => {
  if (config.use_fetch) {
    return await ajax_fetch.getmethod_send(url)
      .catch(function (error) {
        console.log('catch ERROR ABSTRACT ajax_utils.GETmethod_send() ');
        console.log(url);
        console.log(error);
      });
  }
}

ajax_utils.postmethod_send = async (url = '', data = {}) => {
  if (config.use_fetch) {
    let data_prepared = ajax_utils.convert_to_urlencoded_string(data); // ENCODE...
    return await ajax_fetch.postmethod_send(url, data_prepared)
      .catch(function (error) {
        console.log('catch ERROR ABSTRACT ajax_utils.POSTmethod_send() ');
        console.log(url);
        console.log(error);
      });
  }
}

