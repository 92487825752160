import React, { Component } from 'react';
import ModalError from './ModalError';
import ModalSuccess from './ModalSuccess';
import ModalMessage from './ModalMessage';
import ModalAddEditLink from './ModalAddEditLink';
import ModalMoveLink from './ModalMoveLink';
// import ModalInput from './ModalInput';
import ModalFolderCreate from './ModalFolderCreate';
import ModalFolderRename from './ModalFolderRename';
import ModalFolderDelete from './ModalFolderDelete';
import ModalPostDelete from './ModalPostDelete';
import ModalCommentOptions from './ModalCommentOptions';
import ModalRssSubsAdd from './ModalRssSubsAdd';
import ModalRssSubsEdit from './ModalRssSubsEdit';
import ModalAvatarUpload from './ModalAvatarUpload';
import ModalAvatarUploadTemp from './ModalAvatarUploadTemp';

import ModalTestLarge from './ModalTestLarge';


// <MultiModalItemContentRouter modal_id={this.props.id} data={this.props.data} dispatch={this.props.dispatch} dispatch_closing_modal={_dispatch_close_modal(this.props.id)} />

export default class MultiModalItemContentRouter extends React.Component {
  constructor(props) {
    super(props);
    console.log('constructor MultiModalItemContentRouter ');
    console.log(this.props);
  }

  render() {
    //
    let modal_data = this.props.multimodal_item.modal_content;
    let dispatch = this.props.dispatch;
    let dispatch_close_modal = this.props.dispatch_close_modal;
    let modal_content_type = this.props.multimodal_item.modal_content.type;
    let content_data = this.props.multimodal_item.modal_content.data;
    /*
    let modal_content_type;
    if ('modal_type' in modal_data) {
      // OLD...
      modal_content_type = this.props.multimodal_item.modal_content.modal_type;
    } else {
      // NEW
      modal_content_type = this.props.multimodal_item.modal_content.type;
    }
    */

    console.log('MODAL CONTENT ROUTER all props');
    console.log(this.props);
    console.log('MODAL_DATA');
    console.log(modal_data);

    // DEFAULT
    let title = '';
    let message = 'no message - modal_id: ' + this.props.modal_id;
    let modal_args;
    let jsx_modal_content = null;

    // CASES

    if (typeof modal_content_type !== 'undefined') {

      // DEFAULT MODAL CONTENT
      jsx_modal_content = (
        <>
          <h3>not found Modal component for: {modal_content_type}</h3>
        </>
      );

      // CASES

      if (modal_content_type === 'MODAL_CATCH') {
        // title = modal_data.response.message.title; // Nice!
        console.log('MODAL DATA');
        // console.log(modal_data); // OLD
        // console.log(content_data); // NEW
        console.error(content_data); // NEW **
        title = 'ERROR CATCH';
        let where = '';
        if (typeof content_data.where === 'string') {
          where = content_data.where
        }
        message = where + '  - Check log for details';
        modal_args = {
          title,
          message
        };
        jsx_modal_content = <ModalError modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />
      }

      if (modal_content_type === 'MODAL_FRFR_ERF') {
        console.log('MODAL DATA');
        // console.log(modal_data); // OLD
        console.log(content_data); // NEW
        title = content_data.frfr_error_response.error_message.title;
        message = content_data.frfr_error_response.error_message.message;
        modal_args = {
          title,
          message
        };
        jsx_modal_content = <ModalError modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />
      }

      if (modal_content_type === 'MODAL_FRFR_SUCCESS') {
        // title = modal_data.response.message.title; // Nice!
        console.log('MODAL DATA');
        // console.log(modal_data); // OLD
        console.log(content_data); // NEW
        // message = content_data.response.message;
        if (content_data.frfr_success_response.success_message.title) {
          title = content_data.frfr_success_response.success_message.title;
        }
        message = content_data.frfr_success_response.success_message.message;
        modal_args = {
          title,
          message
        };
        jsx_modal_content = <ModalSuccess modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />
      }

      if (modal_content_type === 'MODAL_SUCCESS') {
        // title = modal_data.response.message.title; // Nice!
        console.log('MODAL DATA');
        console.log(content_data); // NEW
        message = content_data.response.message;
        modal_args = {
          title,
          message
        };
        jsx_modal_content = <ModalSuccess modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />
      }

      if (modal_content_type === 'MODAL_COMING_SOON') {
        // NEW
        title = "You can't do that yet";
        message = 'This feature coming soon!';
        modal_args = {
          title,
          message
        };
        jsx_modal_content = <ModalMessage modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />
      }


      if (modal_content_type === 'MODAL_THEATER') {
        // NEW
        title = "Theater***";
        message = 'test';
        modal_args = {
          title,
          message
        };
        // jsx_modal_content = <ModalMessage modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} />

        jsx_modal_content = <ModalTestLarge modal_data={content_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      }


      // -----

      if (modal_content_type === 'MODAL_LINK_EDITOR') {
        jsx_modal_content = (
          <ModalAddEditLink modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_MOVE_LINK') {
        jsx_modal_content = (
          <ModalMoveLink modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_POST_DELETE') {
        jsx_modal_content = (
          <ModalPostDelete modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_FOLDER_CREATE') {
        jsx_modal_content = (
          <ModalFolderCreate modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_FOLDER_RENAME') {
        jsx_modal_content = (
          <ModalFolderRename modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_FOLDER_DELETE') {
        jsx_modal_content = (
          <ModalFolderDelete modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_COMMENT_OPTIONS') {
        jsx_modal_content = (
          <ModalCommentOptions modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_RSS_SUBS_ADD') {
        jsx_modal_content = (
          <ModalRssSubsAdd modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_RSS_SUBS_EDIT') {
        jsx_modal_content = (
          <ModalRssSubsEdit modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      } else if (modal_content_type === 'MODAL_AVATAR_UPLOAD') {
        /*
        jsx_modal_content = (
          <ModalAvatarUpload modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
        */
        // TEMP
        jsx_modal_content = (
          <ModalAvatarUploadTemp modal_data={content_data} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
        );
      }

      // end of cases
    }


    /*
    {
        "success": true,
        ...
    }
    
    let jsx_content = (
      <div className="modal-item-content">
        <h2>{title}</h2>
        <p>{message}</p>
        <button type="button" className="btn btn-block btn-default" onClick={dispatch_closing}>Ok</button>
      </div>
    );

    */


    let error_alert = function (type, dispatch, what_to_dispatch) {
      // show content in modal
      // and button
      // on button_press call this function
      if (false) {
        dispatch(what_to_dispatch);
      }
    }




    return (
      <>
        {jsx_modal_content}
      </>
    );
  }
}

