import config from './putcut-react-config.js';  // TODO RAZLIKA OD SAJTA DO SAJTA

let autoconfig = {};

autoconfig.detect_environment = function () {
  let url_part_domain = window.location.hostname;
  console.log(url_part_domain);
  let env = 'DEV';
  if (url_part_domain === config.env_prod_domain) {
    env = 'PROD';
  }
  console.log('environment is: ', env);
  return env;
};

autoconfig.api_prefix_current = function () {
  let env = autoconfig.detect_environment();
  let prefix = config.api_prefix_dev;
  if (env === 'PROD') {
    prefix = config.api_prefix_prod;
  }
  console.log('prefix is ', prefix);
  return prefix;
};

export default autoconfig;
