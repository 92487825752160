import React, { Component } from 'react';
import { mini_ajax_post } from '../utils/ajax.js';
import { actionFoldersMyFetch } from '../actions/actions.js';
import { actionMultimodalOpen_frfr_erf, actionMultimodalOpen_catch } from '../actions/frfr-multimodal-actions.js';
import { url_lib, api_lib } from '../utils/url-lib.js';

import ModalPrompt from './ModalPrompt';



export default class ModalFolderRename extends Component {
  constructor(props) {
    super(props);
    let initial_value = '';

    this.state = {
      modalinput: initial_value,
      error: false,
      error_message: '',
      pending: true
    };
    // this._cb_refresh = this._cb_refresh.bind(this);
    this._cb_submit_rename = this._cb_submit_rename.bind(this);
    // this._cb_unfollow = this._cb_unfollow.bind(this); // bez bindovanja bude Cannot read property 'props' of undefined
  }




  /*
  _post_rename_folder = (fetch_url, data, callback) => {
    mini_fetch_post(fetch_url, data)
      .then(response => response.json())
      .then(result => {
        // this.setState({ data: result, pending: false });
        console.log('MINI POST SUCCESSFUL');
        console.log(result);
        // this._refresh();
        return result;
      })
      .then(result => {
        // this.setState({ scraped_data: result, pending: false });
        // AFTER SUCCESS MAKE CALLBACK
        if (callback) {
          callback();
        }
      })
      .catch(e => {
        console.log('mini fetch post CATCH ERROR - e: ');
        console.log(e);
      });
  };
  */

  _cb_submit_rename(name) {
    console.log('_cb_submit_rename(e) SUBMIT ');
    let fetch_url = api_lib.folders_rename_update();
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let fid = modal_data.fid; // folder_id
    let outbox = {
      folderid: fid, 
      name: name
    };
    // data for post method
    console.log('outbox');
    console.log(outbox);
    let callback = () => {
      let dispatch = this.props.dispatch;
      dispatch(actionFoldersMyFetch());
      this.props.dispatch_close_modal();
    }
    // step 1
    // this.setState({ scraped_data: {}, pending: true });
    //
    mini_ajax_post(fetch_url, outbox, callback, null, true, '');
  }

  render() {
    let modal_data = this.props.modal_data;
    console.log(modal_data);
    let folder_name = modal_data.folder_name; //

    let dispatch_close_modal = this.props.dispatch_close_modal;

    let callback_validate = (val) => {
      console.log('*** VALIDATING...');
      if (val === "") {
        // swal.showInputError("You need to write something!");
        let error_msg = 'You need to write something!';
        return error_msg;
      } else {
        return true;
      }
    };

    let callback_submit = this._cb_submit_rename;

    let modal_args = {
      title: 'Rename folder!',
      message: 'Enter the new name for ' + folder_name + ' folder:',
      placeholder: folder_name,
      value: folder_name,
      callback_validate: callback_validate,
      callback_submit: callback_submit
    };

    return (
      <>
        <ModalPrompt modal_data={modal_data} modal_args={modal_args} dispatch_close_modal={dispatch_close_modal} dispatch={this.props.dispatch} />
      </>
    );
  }
}
