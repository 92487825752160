
/*
if (route_name === 'HOME') {
  // default home is ACTIVITIES
  main_content = <RouteActivities appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'TEST') {
  main_content = <RouteTest appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'INITIAL') {
  main_content = <RouteInitialRerouteContainer appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'HELP') {
} else if (route_name === 'WELCOME') {
} else if (route_name === 'ACTIVITIES') {
  
  browser_url = url_lib.activities(my_user_id);
  browser_utils.browser_url_set(browser_url);
  
  main_content = <RouteActivities appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'FEEDS') {
  
  browser_url = url_lib.feeds();
  browser_utils.browser_url_set(browser_url);
  
  main_content = <RouteRssFeeds appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'USER') {
  main_content = <RouteUser appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'SEARCH') {
  main_content = <RouteSearch appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'FOLDERS') {
  main_content = <RouteFolders appstate={appstate} dispatch={dispatch} />
} else if (route_name === 'POSTS') {
  main_content = <RoutePosts appstate={appstate} dispatch={dispatch} />
} else if (route_name === '++POSTS_**') {
  //
} else if (route_name === 'POSTS_SINGLE') {
  main_content = <RoutePostsSingle appstate={appstate} dispatch={dispatch} />
} else {
  main_content = <RouteTest appstate={appstate} dispatch={dispatch} />
}
*/

/*
export let routes = [
  {
    id: '0001',
    route_key: 'HOME',
    sidebar_active_item : "feed-item",
    breadcrumbs: [{
      route_name: 'HOME',
      route_title: 'Home'
    },
    {
      route_name: 'Second',
      route_title: 'Second'
    }
    ],
    enabled: true
  }
];
*/

export let routes = [
  {
    id: '0001',
    route_key: 'HOME',
    sidebar_active_item: "home-item"
  },
  {
    id: '0001',
    route_key: 'TEST',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'INITIAL',
    sidebar_active_item: "feed-item"
  },
  {
    id: '0001',
    route_key: 'HELP',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'WELCOME',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'ACTIVITIES',
    sidebar_active_item: "home-item"
  },
  {
    id: '0001',
    route_key: 'FEEDS',
    sidebar_active_item: "feed-item"
  },
  {
    id: '0001',
    route_key: 'USERS',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'SEARCH',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'FOLDERS',
    sidebar_active_item: "tree-item",
    sidebar_active_fid: 'FID*****'
  },
  {
    id: '0001',
    route_key: 'BIN',
    sidebar_active_item: "tree-item",
    sidebar_active_fid: 'bin*****'
  },
  {
    id: '0001',
    route_key: 'POSTS',
    sidebar_active_item: "nothing zero none"
  },
  {
    id: '0001',
    route_key: 'POSTS_SINGLE',
    sidebar_active_item: "nothing zero none"
  }
];



export let main_menu_options = {
  items: [
    {
      id: '0001',
      route_name: 'WELCOME',
      title: 'Dobrodošli',
      enabled: false,
      superadmins_only: false
    }
  ]
};

