import React, { Component } from 'react';
import { actionNewRoute, actionUserFetch } from '../actions/actions.js';
import SpinnerMain from './SpinnerMain';
import SpinnerNormal from './SpinnerNormal';
import SpinnerSmall from './SpinnerSmall';
import SpinnerLarge from './SpinnerLarge';
import { url_lib } from '../utils/url-lib.js';

export default class ToolbarUserMy extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
    this._handleClick = this._handleClick.bind(this);
    this._refresh = this._refresh.bind(this);
  }

  _refresh() {
  }

  _handleClick(what, second, third) {
    let dispatch = this.props.dispatch;
    if (what === 'ROUTE') {
      return (e) => {
        e.preventDefault();
        console.log('CLICKED');
        dispatch(actionNewRoute(second, third));
        console.log('test');
      }
    }
    if (what === 'SECOND_OPTION_BLA_BLA') {
      return () => {
        // dispatch(actionNewRoute());
      }
    }
  }

  componentDidMount() {
    this._refresh();
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    // let item = this.props.item;

    // let raw_data = this.props.appstate.data_user_my;
    let raw_data = this.props.appstate.data_my_user_data; // AUTOLOGIN DATA
    let item = raw_data.data;
    let props = item;
    let user_id = item.user_id;

    let href_user = url_lib.user(user_id);

    return (
      <>
        <div className="user-toolbar JS-MADE" data-rendered-here="user_toolbar_my">
          <div className="main-toolbar JS-MADE">
            <div className="user-profile-toolbar-panel user-profile-toolbar-panel-my">
              <div className="spa-user-toolbar-spinner spinner-area" style={{ display: 'none' }}>
                <div className="spinner-cell">
                  <div className="the-spinner"></div>
                </div>
              </div>
              <div className="user-toolbar-ajax">
                <div className="avatar">
                  <a className="spa-anchor-user" data-user-id="_5f4f8d3" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}><img className="user-img" src={props.avatar_url} /></a>
                </div>
                <div className="username-group username-group-custom-1">
                  <div className="username"><a className="spa-anchor-user" data-user-id="_5f4f8d3" href={href_user} onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id })}>{props.display_name}</a></div>
                  <div className="edit-profile-button button-style-3">						<span className="button-text edit-text click-to-edit-user" data-user-id="_5f4f8d3" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'PROFILE_EDIT' })}>Edit profile</span>
                  </div>
                </div>

                <div className="social-numbers-group">
                  <div className="clickable click-to-profile-following" data-user-id="' + props.data.user_id + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'FOLLOWING' })}><span>{props.stats.following}</span> Following</div>
                  <div className="clickable click-to-profile-followers" data-user-id="' + props.data.user_id + '" onClick={this._handleClick('ROUTE', 'USER', { user_id: user_id, section: 'FOLLOWERS' })}><span>{props.stats.followers}</span> Followers</div>
                  <div className="not-clickable"><span>{props.stats.saved_links}</span> Saved links</div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
