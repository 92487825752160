import React, { Component } from 'react';

export default class MenuBrain extends Component {
  constructor(props) {
    super(props);

    // stackoverflow...
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  //Set the wrapper ref
  setWrapperRef(element) {
    // Callback Refs https://reactjs.org/docs/refs-and-the-dom.html#callback-refs
    // The function receives the React component instance or HTML DOM element as its argument
    this.wrapperRef = element;
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      console.log('CLICKED OUTSIDE - children not contain target');
      console.log(this.props);
      console.log(this.refs);
      console.log(this.wrapperRef);

      // .current 
      // When a ref is passed to an element in render, a reference to the node becomes accessible at the current attribute of the ref. const node = this.myRef.current;
      // When the ref attribute is used on an HTML element, the ref created in the constructor with React.createRef() receives the underlying DOM element as its current property.
      // https://stackoverflow.com/questions/38864033/react-whats-the-proper-way-of-passing-a-ref-to-a-prop
      let parent_ref = this.props.parent_ref;
      if (parent_ref) {
        let parent_node = parent_ref.current;
        if (parent_node && !parent_node.contains(event.target)) {
          console.log('and parent not contain target');
          this.props.callback();
        } else {
          console.log('BUT parent contain target');
        }
      } else {
        // no parent ref
        this.props.callback();
      }
    }
    /*
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      // alert('You clicked outside of me!');
      console.log('OUTSIDE *****************');
    }
    */

  }

  render() {
    let children = this.props.children;

    let jsx_mmenu = (
      <div ref={this.setWrapperRef}>
        {children}
      </ div>
    );

    return (
      <>
        {jsx_mmenu}
      </>
    );
  }
}


